import { generatedApi as api } from '../emptyApi';

import {
    CrmDocumentsCreateApiResponse,
    CrmNewDocumentsCreateApiResponse,
} from './crm';
import { InspectionsPhotosCreateApiResponse } from './inspection';
import { ReportsSignCreateApiResponse } from './reports';

const patchedApi = api.injectEndpoints({
    endpoints: (build) => ({
        crmApplicationDocumentUpload: build.mutation<
            CrmDocumentsCreateApiResponse,
            FormData
        >({
            query: (fd) => ({
                url: `/api/v1/crm/documents/`,
                method: 'POST',
                body: fd,
                formData: true,
            }),
        }),
        crmDocumentUpload: build.mutation<
            CrmNewDocumentsCreateApiResponse,
            FormData
        >({
            query: (fd) => ({
                url: `/api/v1/crm/new-documents/`,
                method: 'POST',
                body: fd,
                formData: true,
            }),
        }),
        crmDocumentUpdate: build.mutation<
            CrmNewDocumentsCreateApiResponse,
            FormData
        >({
            query: (fd) => ({
                url: `/api/v1/crm/new-documents/${fd.get('id')}`,
                method: 'PUT',
                body: fd,
                formData: true,
            }),
        }),
        inspectionPhotoUpload: build.mutation<
            InspectionsPhotosCreateApiResponse,
            FormData
        >({
            query: (fd) => ({
                url: `/api/v1/inspections/photos/`,
                method: 'POST',
                body: fd,
                formData: true,
            }),
        }),
        reportFileSign: build.mutation<
            ReportsSignCreateApiResponse,
            FormData
        >({
            query: (fd) => ({
                url: `/api/v1/reports/${fd.get('id')}/sign/`,
                method: 'POST',
                body: fd,
                formData: true,
            }),
        }),
    }),
});

export const {
    useCrmApplicationDocumentUploadMutation,
    useInspectionPhotoUploadMutation,
    useCrmDocumentUploadMutation,
    useCrmDocumentUpdateMutation,
    useReportFileSignMutation
} = patchedApi;
