import { Disclosure, Transition, DisclosureProps } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { ElementType, ReactNode } from 'react';
import { classnames } from '../../../lib/classnames';

type AccordionProps<T extends ElementType> = DisclosureProps<T> & {
    title: string | (() => ReactNode);
    children: ReactNode;
};

export const Accordion = <T extends ElementType>({
    title,
    children,
    defaultOpen,
}: AccordionProps<T>) => {
    return (
        <Disclosure defaultOpen={defaultOpen}>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex w-full text-xl font-semibold justify-between items-center rounded-lg px-4 py-2 text-left text-gray-900 focus:outline-none focus-visible:ring">
                        {typeof title === 'string' ? title : title()}
                        <ChevronUpIcon
                            className={classnames(
                                'h-5 w-5 transition-transform',
                                {
                                    'rotate-180 transform': !open,
                                },
                            )}
                        />
                    </Disclosure.Button>
                    <div className="overflow-hidden">
                        <Transition
                            enter="transition duration-300 ease-out"
                            enterFrom="transform -translate-y-1/3 opacity-0"
                            enterTo="transform translate-y-0 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform translate-y-0 opacity-100"
                            leaveTo="transform -translate-y-1/3 opacity-0">
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                {children}
                            </Disclosure.Panel>
                        </Transition>
                    </div>
                </>
            )}
        </Disclosure>
    );
};
