import React, { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { setActiveClass } from '../lib/setActiveClass';

interface LinkAppProps
    extends NavLinkProps,
        React.RefAttributes<HTMLAnchorElement> {
    baseClass?: string;
    activeClass?: string;
}

export const LinkApp: FC<LinkAppProps> = React.forwardRef(
    (
        {
            to,
            baseClass = 'text-indigo-600 hover:text-indigo-900',
            activeClass = '',
            className,
            children,
            target,
        },
        ref,
    ) => {
        return (
            <NavLink
                to={to}
                ref={ref}
                target={target}
                className={setActiveClass(
                    `${baseClass} ${className}`,
                    activeClass,
                )}>
                {children}
            </NavLink>
        );
    },
);
