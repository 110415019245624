import { FC, PropsWithChildren } from 'react';

interface SkeletonListProps {
    title?: string;
}

export const SkeletonList: FC<PropsWithChildren<SkeletonListProps>> = ({
    title,
    children,
}) => {
    return (
        <div className="animate-pulse">
            {title && <div className="h-6 bg-gray-200 rounded w-1/4" />}
            <dl className="divide-y divide-gray-100">{children}</dl>
        </div>
    );
};
