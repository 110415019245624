import { ReactNode, useCallback } from 'react';
import { classnames } from 'shared/lib/classnames';
import { SelectBase } from 'shared/ui/Select';

interface HeadingTabsProps<T extends any> {
    namePage?: string;
    actions?: ReactNode[];
    tabs?: { value: string; name: string }[];
    currentTab?: T;
    onChangeTab?: (arg: T) => void;
}

export const HeadingTabs = <T extends unknown>({
    namePage = '',
    actions = [],
    tabs = [],
    currentTab,
    onChangeTab = () => {},
}: HeadingTabsProps<T>) => {
    const handleChangeFilter = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChangeTab(event.target.value as T);
        },
        [onChangeTab],
    );

    return (
        <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
            <div className="md:flex md:items-center md:justify-between">
                <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                    {namePage}
                </h1>
                {actions && (
                    <div className="mt-3 flex gap-3">
                        {actions}
                    </div>
                )}
            </div>
            <div className="mt-4">
                <div className="sm:hidden">
                    <label htmlFor="current-tab" className="sr-only">
                        Select a tab
                    </label>
                    <SelectBase
                        formKey=""
                        label=""
                        options={tabs}
                        attrs={{
                            value: String(currentTab),
                            onChange: handleChangeFilter,
                        }}
                    />
                </div>
                <div className="hidden sm:block">
                    <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                            <button
                                key={tab.value}
                                onClick={() => {
                                    onChangeTab(tab.value as T);
                                }}
                                className={classnames(
                                    currentTab === tab.value
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium transition-colors',
                                )}>
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
};
