import { RetrieveOrderRead } from 'app/generatedApi/crm';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { SummerRoomsLabels } from 'shared/config/SummerRoomsLabels';
import { WallTypesLabels } from 'shared/config/WallTypesLabels';

import { GroupBuild, OrderObjectBuild } from '../module/types';

export function prepareObjectBuild(order: RetrieveOrderRead) {
    const result: OrderObjectBuild = { buildFields: [], slides: [] };
    const baseField: GroupBuild = { title: 'Общие сведения', value: [] };
    baseField.value.push({
        name: 'Адрес объекта оценки',
        value: order?.address,
    });
    baseField.value.push({
        name: 'Материал стен дома',
        value: order.selection?.wallType && WallTypesLabels[order.selection.wallType],
    });
    baseField.value.push({
        name: 'Материал перекрытий',
        value: 'кирпич' /*WallTypesLabels[order.selection.wallType!]*/,
    }); //todo:откуда брать
    baseField.value.push({
        name: 'Этажность дома',
        value: '20' /*WallTypesLabels[order.selection.houseFloors!]*/,
    });
    baseField.value.push({
        name: 'Этаж объекта оценки',
        value: order.selection?.floor,
    });
    baseField.value.push({
        name: 'Тип отделки',
        value: order.selection?.renovationType && RenovationsLabels[order.selection.renovationType],
    });
    baseField.value.push({
        name: 'Количество комнат',
        value: order.selection?.roomsCount,
    });
    baseField.value.push({
        name: 'Количество санузлов',
        value: order.countAndTypeOfRestrooms,
    });//todo: уточнить
    baseField.value.push({
        name: 'Лоджия или балкон',
        value: order.selection?.summerRooms && SummerRoomsLabels[order.selection.summerRooms],
    });
    baseField.value.push({
        name: 'Тип санузла',
        value: order.countAndTypeOfRestrooms,
    });
    baseField.value.push({
        name: 'Высота потолков, м',
        value: order.ceilingHeights,
    });

    const squaresField: GroupBuild = { title: 'Площади', value: [] };
    squaresField.value.push({
        name: 'Общая объекта оценки, м2',
        value: order.area,
    });
    squaresField.value.push({ name: 'Жилая, м2', value: order.livingArea });
    squaresField.value.push({ name: 'Кухни, м2', value: order.kitchenArea });
    squaresField.value.push({
        name: 'Летних помещений, м2',
        value: order.summerRoomArea,
    });
    squaresField.value.push({
        name: 'Вспомогательная, м2',
        value: order.utilityRoomsArea,
    });
    squaresField.value.push({ name: 'Вид обременения', value: '' }); //todo:откуда брать

    result.buildFields.push(baseField);
    result.buildFields.push(squaresField);

    const slides = order.techDocuments.map((groupDoc) => {
        return groupDoc.documents.map((doc) => ({
            url: String(doc.file),
        }));
    });
    
    result.slides = slides.flat()
    
    return result;
}
