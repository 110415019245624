import {
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
    defaultLayoutPlugin,
    ToolbarProps,
    ToolbarSlot,
} from '@react-pdf-viewer/default-layout';
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import { FC, ReactElement } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { classnames } from '../../lib/classnames';
import { Button } from '../Button/Button';

import cls from './PdfView.module.css';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

interface PdfViewProps {
    className?: string;
    fileUrl?: string | null;
}

const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
        {(slots: ToolbarSlot) => {
            const {
                CurrentPageInput,
                CurrentScale,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
                Download,
                Print,
            } = slots;
            return (
                <div className="w-full flex gap-4 items-center justify-between">
                    <div className="px-3 flex gap-4 items-center">
                        <ZoomOut>
                            {(props) => (
                                <Button onClick={props.onClick} round={true}>
                                    <MagnifyingGlassMinusIcon className="w-4 h-4" />
                                </Button>
                            )}
                        </ZoomOut>
                        <CurrentScale>
                            {(props) => (
                                <span>{`${Math.round(
                                    props.scale * 100,
                                )}%`}</span>
                            )}
                        </CurrentScale>
                        <ZoomIn>
                            {(props) => (
                                <Button onClick={props.onClick} round={true}>
                                    <MagnifyingGlassPlusIcon className="w-4 h-4" />
                                </Button>
                            )}
                        </ZoomIn>
                    </div>
                    <div className="flex px-3 gap-3 items-center">
                        <Download />
                        <Print />
                        <GoToPreviousPage>
                            {(props) => (
                                <Button
                                    disabled={props.isDisabled}
                                    onClick={props.onClick}
                                    round={true}>
                                    <ChevronLeftIcon className="w-4 h-4" />
                                </Button>
                            )}
                        </GoToPreviousPage>
                        <div className="w-12">
                            <CurrentPageInput />
                        </div>
                        / <NumberOfPages />
                        <GoToNextPage>
                            {(props) => (
                                <Button
                                    disabled={props.isDisabled}
                                    onClick={props.onClick}
                                    round={true}>
                                    <ChevronRightIcon className="w-4 h-4" />
                                </Button>
                            )}
                        </GoToNextPage>
                    </div>
                </div>
            );
        }}
    </Toolbar>
);

export const PdfView: FC<PdfViewProps> = ({ fileUrl, className = '' }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({ renderToolbar });

    if (!fileUrl) {
        return <ErrorMessage message="Отсутствует ссылка на документ" />;
    }

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div className={classnames(cls.wrapper, className)}>
                <Viewer
                    renderError={() => (
                        <ErrorMessage message="Не удалось загрузить документ" />
                    )}
                    localization={ru_RU}
                    fileUrl={fileUrl}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </div>
        </Worker>
    );
};
