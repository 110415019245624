import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from 'app/baseQuery';
import { BuildingInfo } from 'shared/types/buildings';

import { Suggest } from './types';

export const buildingsApi = createApi({
    reducerPath: 'buildingsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        searchAddress: builder.query<Array<Suggest>, string>({
            query: (body) => ({
                url: '/api/v1/buildings/search',
                method: 'GET',
                params: { query: body },
            }),
        }),
        house: builder.query<BuildingInfo, string>({
            query: (house_guid) => ({
                url: '/api/v1/buildings/house/' + house_guid,
                method: 'GET',
            }),
        }),
        importUpload: builder.mutation<boolean, FormData>({
            query: (body) => ({
                url: '/api/v1/buildings/import',
                method: 'POST',
                body,
                formData: true,
            }),
        }),
    }),
});

export const {
    useSearchAddressQuery,
    useImportUploadMutation,
    useHouseQuery,
    useLazyHouseQuery,
} = buildingsApi;
