import { generatedApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        selectionsList: build.query<
            SelectionsListApiResponse,
            SelectionsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/`,
                params: {
                    ordering: queryArg.ordering,
                    page: queryArg.page,
                    page_size: queryArg.pageSize,
                    status: queryArg.status,
                },
            }),
        }),
        selectionsCreate: build.mutation<
            SelectionsCreateApiResponse,
            SelectionsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/`,
                method: 'POST',
                body: queryArg.createSelection,
            }),
        }),
        selectionsRetrieve: build.query<
            SelectionsRetrieveApiResponse,
            SelectionsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/${queryArg.id}/`,
            }),
        }),
        selectionsCheckCreate: build.mutation<
            SelectionsCheckCreateApiResponse,
            SelectionsCheckCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/${queryArg.selectionId}/check/`,
                method: 'POST',
            }),
        }),
        selectionsRestartCreate: build.mutation<
            SelectionsRestartCreateApiResponse,
            SelectionsRestartCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/${queryArg.selectionId}/restart/`,
                method: 'POST',
            }),
        }),
        selectionsApartmentsList: build.query<
            SelectionsApartmentsListApiResponse,
            SelectionsApartmentsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/apartments/`,
                params: { house: queryArg.house },
            }),
        }),
        selectionsApartmentsRetrieve: build.query<
            SelectionsApartmentsRetrieveApiResponse,
            SelectionsApartmentsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/apartments/${queryArg.id}/`,
            }),
        }),
        selectionsEntrancesList: build.query<
            SelectionsEntrancesListApiResponse,
            SelectionsEntrancesListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/entrances/`,
                params: { house: queryArg.house },
            }),
        }),
        selectionsResidentialComplexHousesList: build.query<
            SelectionsResidentialComplexHousesListApiResponse,
            SelectionsResidentialComplexHousesListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/residential-complex-houses/`,
                params: { residential_complex: queryArg.residentialComplex },
            }),
        }),
        selectionsResidentialComplexHousesRetrieve: build.query<
            SelectionsResidentialComplexHousesRetrieveApiResponse,
            SelectionsResidentialComplexHousesRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/residential-complex-houses/${queryArg.id}/`,
            }),
        }),
        selectionsResidentialComplexesList: build.query<
            SelectionsResidentialComplexesListApiResponse,
            SelectionsResidentialComplexesListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/residential-complexes/`,
                params: { city: queryArg.city },
            }),
        }),
        selectionsResidentialComplexesRetrieve: build.query<
            SelectionsResidentialComplexesRetrieveApiResponse,
            SelectionsResidentialComplexesRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/selections/residential-complexes/${queryArg.id}/`,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type SelectionsListApiResponse =
    /** status 200  */ PaginatedSelectionListListRead;
export type SelectionsListApiArg = {
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
    /** * `0` - Создан
     * `1` - В процессе
     * `2` - Закончен
     * `3` - Ошибка */
    status?: 0 | 1 | 2 | 3;
};
export type SelectionsCreateApiResponse =
    /** status 201  */ CreateSelectionRead;
export type SelectionsCreateApiArg = {
    createSelection: CreateSelection;
};
export type SelectionsRetrieveApiResponse = /** status 200  */ SelectionRead;
export type SelectionsRetrieveApiArg = {
    /** A unique integer value identifying this selection2. */
    id: number;
};
export type SelectionsCheckCreateApiResponse = unknown;
export type SelectionsCheckCreateApiArg = {
    selectionId: number;
};
export type SelectionsRestartCreateApiResponse = unknown;
export type SelectionsRestartCreateApiArg = {
    selectionId: number;
};
export type SelectionsApartmentsListApiResponse =
    /** status 200  */ ResidentialComplexApartmentRead[];
export type SelectionsApartmentsListApiArg = {
    /** Residential complex house id */
    house?: number;
};
export type SelectionsApartmentsRetrieveApiResponse =
    /** status 200  */ ResidentialComplexApartmentRead;
export type SelectionsApartmentsRetrieveApiArg = {
    /** A unique integer value identifying this residential complex apartment. */
    id: number;
};
export type SelectionsEntrancesListApiResponse =
    /** status 200  */ ResidentialComplexHouseEntranceRead[];
export type SelectionsEntrancesListApiArg = {
    /** Residential complex house id */
    house?: number;
};
export type SelectionsResidentialComplexHousesListApiResponse =
    /** status 200  */ ResidentialComplexHouseRead[];
export type SelectionsResidentialComplexHousesListApiArg = {
    /** Residential complex id */
    residentialComplex?: number;
};
export type SelectionsResidentialComplexHousesRetrieveApiResponse =
    /** status 200  */ ResidentialComplexHouseRead;
export type SelectionsResidentialComplexHousesRetrieveApiArg = {
    /** A unique integer value identifying this residential complex house. */
    id: number;
};
export type SelectionsResidentialComplexesListApiResponse =
    /** status 200  */ ResidentialComplexRead[];
export type SelectionsResidentialComplexesListApiArg = {
    /** City id */
    city?: number;
};
export type SelectionsResidentialComplexesRetrieveApiResponse =
    /** status 200  */ ResidentialComplexRead;
export type SelectionsResidentialComplexesRetrieveApiArg = {
    /** A unique integer value identifying this residential complex. */
    id: number;
};
export type TypeCadEnum = 'default' | 'residential_complex';
export type RenovationTypeEnum = -1 | 0 | 1 | 2 | 3 | 4 | 5;
export type NewRenovationTypeEnum =
    | 'unknown'
    | 'none'
    | 'preclean'
    | 'average'
    | 'good'
    | 'euro'
    | 'premium';
export type SummerRoomsEnum = -1 | 0 | 1 | 2;
export type HouseTypeEnum = -1 | 1 | 2;
export type WallTypeEnum =
    | 'unknown'
    | 'brick'
    | 'panel'
    | 'block'
    | 'monolith'
    | 'wooden'
    | 'monolith_brick';
export type SelectionList = {
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type StatusA47Enum = 0 | 1 | 2 | 3;
export type SelectionListRead = {
    id: number;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type PaginatedSelectionListList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: SelectionList[];
};
export type PaginatedSelectionListListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: SelectionListRead[];
};
export type CreateSelection = {
    type?: TypeCadEnum;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    building?: number | null;
};
export type CreateSelectionRead = {
    id: number;
    type?: TypeCadEnum;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    building?: number | null;
};
export type Building = {
    id: number;
    regionId?: string | null;
    areaId?: string | null;
    cityId?: string | null;
    streetId?: string | null;
    shortnameRegion?: string | null;
    formalnameRegion?: string | null;
    shortnameArea?: string | null;
    formalnameArea?: string | null;
    shortnameCity?: string | null;
    formalnameCity?: string | null;
    shortnameStreet?: string | null;
    formalnameStreet?: string | null;
    houseNumber?: string | null;
    building?: string | null;
    block?: string | null;
    letter?: string | null;
    address?: string | null;
    houseguid?: string | null;
    managementOrganizationId?: number | null;
    builtYear?: number | null;
    exploitationStartYear?: number | null;
    projectType?: string | null;
    houseType?: string | null;
    isAlarm?: string | null;
    methodOfFormingOverhaulFund: string;
    floorCountMax?: number | null;
    floorCountMin?: number | null;
    entranceCount?: number | null;
    elevatorsCount?: number | null;
    energyEfficiency?: string | null;
    quartersCount?: number | null;
    livingQuartersCount?: number | null;
    unlivingQuartersCount?: number | null;
    areaTotal?: number | null;
    areaResidential?: number | null;
    areaNonResidential?: number | null;
    areaCommonProperty?: number | null;
    areaLand?: number | null;
    parkingSquare?: number | null;
    playground?: number | null;
    sportsground?: number | null;
    otherBeautification?: string | null;
    foundationType?: string | null;
    floorType?: string | null;
    wallMaterial?: string | null;
    basementArea?: string | null;
    chuteType?: string | null;
    chuteCount?: number | null;
    electricalType?: string | null;
    electricalEntriesCount?: number | null;
    heatingType?: string | null;
    hotWaterType?: string | null;
    coldWaterType?: string | null;
    sewerageType?: string | null;
    sewerageCesspoolsVolume?: number | null;
    gasType?: string | null;
    ventilationType?: string | null;
    firefightingType?: string | null;
    drainageType?: string | null;
    systemCity?: number | null;
    newCity?: number | null;
};
export type Selection = {
    building: Building;
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    homogeneityRatio?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplexApartment?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type CorrectedAnalog = {
    sumOfPercentageCorrections: number;
    pricePerMeter?: number;
    correctedPricePerMeter?: number;
    bargainingCorrection?: number;
    salesTypeCorrection?: number;
    wallTypeCorrection?: number;
    floorCorrection?: number;
    areaCorrection?: number;
    summerRoomsCorrection?: number;
    renovationTypeCorrection?: number;
    weight?: number;
};
export type SourceIdEnum = 4;
export type WindowsViewEnum = -1 | 0 | 1;
export type Advertisement = {
    id: number;
    pricePerMeter: number;
    modified?: string | null;
    address?: string;
    sourceUrl: string;
    sourceId?: SourceIdEnum;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro?: string;
    kmDoMetro: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    windowsView?: WindowsViewEnum;
    summerRooms?: SummerRoomsEnum;
    heightOfCeiling?: number | null;
    buildingYear?: number | null;
    location?: string | null;
    originalData?: any;
    unpublished?: boolean;
    photoCount?: number;
    dadataData?: any | null;
    parsedAt?: string | null;
    screenshot?: string | null;
    screenshotTakenAt?: string | null;
    building?: number | null;
};
export type AdvertisementRead = {
    id: number;
    pricePerMeter: number;
    wallTypeLabel: string;
    renovationTypeLabel: string;
    summerRoomsLabel: string;
    windowsViewLabel: string;
    houseTypeLabel: string;
    created: string;
    modified?: string | null;
    address?: string;
    sourceUrl: string;
    sourceId?: SourceIdEnum;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro?: string;
    kmDoMetro: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    windowsView?: WindowsViewEnum;
    summerRooms?: SummerRoomsEnum;
    heightOfCeiling?: number | null;
    buildingYear?: number | null;
    location?: string | null;
    originalData?: any;
    unpublished?: boolean;
    photoCount?: number;
    dadataData?: any | null;
    parsedAt?: string | null;
    screenshot?: string | null;
    screenshotTakenAt?: string | null;
    building?: number | null;
};
export type CorrectedAnalogRead = {
    id: number;
    advertisement: AdvertisementRead;
    sumOfPercentageCorrections: number;
    pricePerMeterWithBargainingCorrection: string;
    pricePerMeter?: number;
    correctedPricePerMeter?: number;
    bargainingCorrection?: number;
    salesTypeCorrection?: number;
    wallTypeCorrection?: number;
    floorCorrection?: number;
    areaCorrection?: number;
    summerRoomsCorrection?: number;
    renovationTypeCorrection?: number;
    weight?: number;
};
export type SimpleReport = {};
export type SimpleReportRead = {
    id: number;
};
export type ResidentialComplex = {
    modified?: string | null;
    name: string;
    subject?: string;
    locality?: string;
    polygon: string;
    additionalFilters?: any | null;
    resaleOnly?: boolean;
    city?: number | null;
};
export type ResidentialComplexRead = {
    id: number;
    created: string;
    modified?: string | null;
    name: string;
    subject?: string;
    locality?: string;
    polygon: string;
    additionalFilters?: any | null;
    resaleOnly?: boolean;
    city?: number | null;
};
export type SecurityEnum = 1 | 2 | 3 | 4;
export type TransportAvailabilityLevelEnum = 1 | 2 | 3;
export type ResidentialComplexHouse = {
    modified?: string | null;
    address?: string;
    kadastrNumber?: string;
    location?: string | null;
    builtYear: number;
    buildingWearPercentage: number;
    serialNumber?: string;
    foundationType: string;
    floorsCount: number;
    wallType?: WallTypeEnum;
    floorType: string;
    elevator?: boolean;
    garbageChute?: boolean;
    security?: SecurityEnum;
    coldWaterSupply?: string;
    hotWaterSupply?: string;
    sewageSystem?: string;
    electricalSupply?: string;
    gasSupply?: string;
    heatingSystem?: string;
    lowVoltageEquipment?: string;
    transportAvailabilityLevel?: TransportAvailabilityLevelEnum;
    kindergarten?: boolean | null;
    school?: boolean | null;
    clinic?: boolean | null;
    mall?: boolean | null;
    pond?: boolean | null;
    residentialComplex: number;
};
export type ResidentialComplexHouseRead = {
    id: number;
    created: string;
    modified?: string | null;
    address?: string;
    kadastrNumber?: string;
    location?: string | null;
    builtYear: number;
    buildingWearPercentage: number;
    serialNumber?: string;
    foundationType: string;
    floorsCount: number;
    wallType?: WallTypeEnum;
    floorType: string;
    elevator?: boolean;
    garbageChute?: boolean;
    security?: SecurityEnum;
    coldWaterSupply?: string;
    hotWaterSupply?: string;
    sewageSystem?: string;
    electricalSupply?: string;
    gasSupply?: string;
    heatingSystem?: string;
    lowVoltageEquipment?: string;
    transportAvailabilityLevel?: TransportAvailabilityLevelEnum;
    kindergarten?: boolean | null;
    school?: boolean | null;
    clinic?: boolean | null;
    mall?: boolean | null;
    pond?: boolean | null;
    residentialComplex: number;
};
export type SelectionRead = {
    id: number;
    building: Building;
    correctedAnalogs: CorrectedAnalogRead[];
    analogs: AdvertisementRead[];
    report: SimpleReportRead;
    priceInWords: string;
    liquidationPriceInWords: string;
    roominess: string;
    discountRatePercentage: string;
    lowLiquidityRiskCompensationPercentage: string;
    riskFreeRatePercentage: string;
    residentialComplex: ResidentialComplexRead;
    residentialComplexHouse: ResidentialComplexHouseRead;
    ratio: string;
    priceFormatted: string;
    liquidationPriceFormatted: string;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step: number;
    distance: number;
    areaThreshold: number;
    homogeneityRatio?: number;
    pricePerMeter: number;
    price: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplexApartment?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type SummerRoomsType2E3Enum = -1 | 0 | 1 | 2;
export type LayoutTypeEnum = 'free' | 'fixed';
export type ResidentialComplexApartment = {
    modified?: string | null;
    number: number;
    roomsCount: number;
    area: number;
    floor: number;
    renovationType?: RenovationTypeEnum;
    summerRoomsType?: SummerRoomsType2E3Enum;
    layoutType?: LayoutTypeEnum;
    livingArea?: number | null;
    kitchenArea?: number | null;
    fullArea?: number | null;
    summerRoomsArea?: number | null;
    roomsAreas?: number[];
    summerRooms?: boolean;
    utilityRoomsArea?: number | null;
    restroomCountAndType?: string;
    heightOfCeiling?: number | null;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    entryAndInteriorDoors?: string;
    overallCondition?: string;
    house: number;
    entrance?: number | null;
};
export type ResidentialComplexApartmentRead = {
    id: number;
    created: string;
    modified?: string | null;
    number: number;
    roomsCount: number;
    area: number;
    floor: number;
    renovationType?: RenovationTypeEnum;
    summerRoomsType?: SummerRoomsType2E3Enum;
    layoutType?: LayoutTypeEnum;
    livingArea?: number | null;
    kitchenArea?: number | null;
    fullArea?: number | null;
    summerRoomsArea?: number | null;
    roomsAreas?: number[];
    summerRooms?: boolean;
    utilityRoomsArea?: number | null;
    restroomCountAndType?: string;
    heightOfCeiling?: number | null;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    entryAndInteriorDoors?: string;
    overallCondition?: string;
    house: number;
    entrance?: number | null;
};
export type ResidentialComplexHouseEntrance = {
    number: number;
};
export type ResidentialComplexHouseEntranceRead = {
    id: number;
    number: number;
};
export const {
    useSelectionsListQuery,
    useLazySelectionsListQuery,
    useSelectionsCreateMutation,
    useSelectionsRetrieveQuery,
    useLazySelectionsRetrieveQuery,
    useSelectionsCheckCreateMutation,
    useSelectionsRestartCreateMutation,
    useSelectionsApartmentsListQuery,
    useLazySelectionsApartmentsListQuery,
    useSelectionsApartmentsRetrieveQuery,
    useLazySelectionsApartmentsRetrieveQuery,
    useSelectionsEntrancesListQuery,
    useLazySelectionsEntrancesListQuery,
    useSelectionsResidentialComplexHousesListQuery,
    useLazySelectionsResidentialComplexHousesListQuery,
    useSelectionsResidentialComplexHousesRetrieveQuery,
    useLazySelectionsResidentialComplexHousesRetrieveQuery,
    useSelectionsResidentialComplexesListQuery,
    useLazySelectionsResidentialComplexesListQuery,
    useSelectionsResidentialComplexesRetrieveQuery,
    useLazySelectionsResidentialComplexesRetrieveQuery,
} = injectedRtkApi;
