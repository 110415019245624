import { PlusIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { UserIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { format, formatDate } from 'date-fns';
import {
    Order,
    OwnerRead,
    useCrmOrdersOwnersCreateMutation,
    useCrmOrdersOwnersDestroyMutation,
    useCrmOrdersOwnersPartialUpdateMutation,
} from 'app/generatedApi/crm';
import { InputForm } from 'shared/ui/Input';
import { SelectForm } from 'shared/ui/Select';
import { validationDate } from 'shared/lib/validation/validationDate';
import { Button, ButtonSize, ButtonTheme } from 'shared/ui/Button/Button';
import { useParams } from 'react-router-dom';
import { getErrorMessage } from 'shared/lib/getErrorMessage';

interface OwnersListProps {}

export const OwnersList: FC<OwnersListProps> = () => {
    const {
        watch,
        register,
        setValue,
        formState: { errors },
    } = useFormContext<Order & { owners: OwnerRead[] }>();
    register('owners', { required: true });
    const orderDraft = watch();
    const [deletingOwnersIds, setDeletingOwnersIds] = useState<number[]>([]);
    const [updatingOwnersIds, setUpdatingOwnersIds] = useState<number[]>([]);

    const { id } = useParams();

    const [create, { isLoading: isCreating }] =
        useCrmOrdersOwnersCreateMutation();
    const [update] = useCrmOrdersOwnersPartialUpdateMutation();
    const [remove] = useCrmOrdersOwnersDestroyMutation();

    const onAddOwner = useCallback(async () => {
        try {
            if (id) {
                const newOwner = {
                    gotOwnershipDate: formatDate(new Date(), 'yyyy-MM-dd'),
                    order: +id,
                };
                const resultOwner = await create({
                    owner: newOwner,
                }).unwrap();

                setValue('owners', [...(orderDraft.owners || []), resultOwner]);
            }
        } catch (error) {
            alert(getErrorMessage(error, 'Произошла ошибка'));
        }
    }, [create, id, orderDraft.owners, setValue]);

    const onDeleteOwner = async (id: number) => {
        try {
            setDeletingOwnersIds((ids) => [...ids, id]);
            await remove({ id }).unwrap();
            setValue(
                'owners',
                orderDraft.owners &&
                    orderDraft.owners.filter((owner) => owner.id !== id),
            );
        } catch (error) {
            alert(getErrorMessage(error, 'Произошла ошибка'));
        } finally {
            setDeletingOwnersIds((ids) => ids.filter((idx) => idx !== id));
        }
    };

    const updatePropOwner = useCallback(
        async function (
            event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
            id: number,
            name: string,
        ) {
            try {
                setValue(
                    'owners',
                    orderDraft.owners?.map((owner) =>
                        owner.id === id
                            ? {
                                  ...owner,
                                  [name]: event.target.value,
                              }
                            : owner,
                    ),
                );

                await update({
                    id,
                    patchedOwner: {
                        [name]: event.target.value,
                    },
                });
            } catch (error) {
                alert(getErrorMessage(error, 'Произошла ошибка'));
            } finally {
            }
        },
        [orderDraft.owners, setValue, update],
    );

    const onAutoCompleteCustomer = async (id: number) => {
        try {
            if (!orderDraft.customerFio) return;

            setUpdatingOwnersIds((ids) => [...ids, id]);
            await update({
                id,
                patchedOwner: { owner: orderDraft.customerFio },
            });
            const index = orderDraft.owners?.findIndex(
                (owner) => owner.id === id,
            );
            setValue(`owners.${index}.owner`, orderDraft.customerFio);
        } catch (error) {
            alert(getErrorMessage(error, 'Произошла ошибка'));
        } finally {
            setUpdatingOwnersIds((ids) => ids.filter((idx) => idx !== id));
        }
    };

    return (
        <div className="col-span-full">
            <label className="mb-4 font-medium text-sm">
                Собственники<span className="text-red-600"> *</span>
            </label>
            <div className="my-2 divide-y-8 divide-transparent">
                {orderDraft.owners?.map((owner, index) => (
                    <div
                        key={owner.id}
                        className="max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid sm:grid-cols-7 md:col-span-2 sm:items-end">
                        <InputForm
                            formKey={`owners.${index}.owner`}
                            label="ФИО"
                            register={register}
                            required={true}
                            isShowError={false}
                            size={'s'}
                            error={errors.owners?.[index]?.owner}
                            attrs={{
                                ...register(`owners.${index}.owner`, {
                                    required: true,
                                    onBlur: (event) => {
                                        updatePropOwner(
                                            event,
                                            owner.id,
                                            'owner',
                                        );
                                    },
                                }),
                            }}
                        />
                        <SelectForm
                            formKey={`owners.${index}.ownershipType`}
                            label={'Вид права'}
                            register={register}
                            size={'s'}
                            error={errors.owners?.[index]?.ownershipType}
                            isShowError={false}
                            options={[
                                {
                                    value: 'Право собственности',
                                    name: 'Право собственности',
                                },
                                {
                                    value: 'Право совместной собственности',
                                    name: 'Право совместной собственности',
                                },
                                {
                                    value: 'Право долевой собственности',
                                    name: 'Право долевой собственности',
                                },
                                {
                                    value: 'Право требования',
                                    name: 'Право требования',
                                },
                                { value: 'Другое', name: 'Другое' },
                            ]}
                            emptyValue={'Укажите вид права'}
                            required={true}
                            attrs={{
                                ...register(`owners.${index}.ownershipType`, {
                                    required: true,
                                    onChange: (event) => {
                                        updatePropOwner(
                                            event,
                                            owner.id,
                                            'ownershipType',
                                        );
                                    },
                                }),
                            }}
                        />
                        <InputForm
                            formKey={`owners.${index}.gotOwnershipDate`}
                            label={'Дата получения прав'}
                            register={register}
                            type="date"
                            size={'s'}
                            required={true}
                            error={errors.owners?.[index]?.gotOwnershipDate}
                            isShowError={false}
                            attrs={{
                                ...register(
                                    `owners.${index}.gotOwnershipDate`,
                                    {
                                        required: true,
                                        validate: (value) => {
                                            return validationDate(
                                                new Date(),
                                                value,
                                            );
                                        },
                                        onChange: (event) => {
                                            updatePropOwner(
                                                event,
                                                owner.id,
                                                'gotOwnershipDate',
                                            );
                                        },
                                    },
                                ),
                                max: format(new Date(), 'yyyy-MM-dd'),
                            }}
                        />
                        <div className="flex gap-2 mb-0.5">
                            <Button
                                title="Заполнить ФИО из Заказчика"
                                theme={ButtonTheme.GREYOPACITY}
                                round={true}
                                className="self-center justify-self-end hover:!text-indigo-600"
                                onClick={() => {
                                    if (
                                        owner.owner !==
                                            orderDraft.customerFio &&
                                        !updatingOwnersIds.includes(owner.id)
                                    ) {
                                        onAutoCompleteCustomer(owner.id);
                                    }
                                }}>
                                {updatingOwnersIds.includes(owner.id) ? (
                                    <ArrowPathIcon className="h-6 w-6 animate-spin" />
                                ) : (
                                    <UserIcon className="w-5 h-5" />
                                )}
                            </Button>
                            <Button
                                theme={ButtonTheme.GREYOPACITY}
                                round={true}
                                className="self-center justify-self-end hover:!text-red-500"
                                onClick={() => onDeleteOwner(owner.id)}>
                                {deletingOwnersIds.includes(owner.id) ? (
                                    <ArrowPathIcon className="h-6 w-6 animate-spin" />
                                ) : (
                                    <XMarkIcon className="w-5 h-5" />
                                )}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
            <Button
                onClick={onAddOwner}
                theme={ButtonTheme.SECONDARY}
                size={ButtonSize.SM}
                loading={isCreating}
                icon>
                <PlusIcon className="h-5 w-5" />
                Добавить Собственника
            </Button>
            {errors.owners && (
                <p className="mt-3 text-sm leading-6 text-red-600">
                    Эти поля обязательны для заполнения
                </p>
            )}
        </div>
    );
};
