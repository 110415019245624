export class Polygon {
    coords: Array<Point> = [];

    static parse(str: string): Polygon {
        const polygon = new Polygon();
        const coordinatesString = str.match(/\(\(([^)]+)\)\)/)?.[1];

        if (coordinatesString) {
            const pairs = coordinatesString.split(', ');
            pairs.forEach((pair) => {
                const [lon, lat] = pair.split(' ').map(Number);
                polygon.coords.push(new Point(lon, lat));
            });
        }

        return polygon;
    }

    getCenter(): Point {
        if (this.coords.length === 0) {
            return new Point(0, 0);
        }

        // Calculate the average latitude and longitude
        const sumLat = this.coords.reduce((sum, point) => sum + point.lat, 0);
        const sumLon = this.coords.reduce((sum, point) => sum + point.long, 0);

        const avgLat = sumLat / this.coords.length;
        const avgLon = sumLon / this.coords.length;

        return new Point(avgLon, avgLat);
    }
}

export class Point {
    constructor(public long: number, public lat: number) {}

    static parse(str: string): Point {
        const match = str.match(/POINT\(([^ ]+) ([^)]+)\)/);

        if (!match) {
            return new Point(0, 0);
        }
        const lon = parseFloat(match[1]);
        const lat = parseFloat(match[2]);

        return new Point(lon, lat);
    }

    toString(): string {
        return `POINT(${this.lat} ${this.long})`;
    }
}
