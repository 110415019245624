import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    CreateSelection,
    useLazySelectionsApartmentsListQuery,
    useLazySelectionsResidentialComplexHousesListQuery,
    useSelectionsCreateMutation,
    useSelectionsResidentialComplexesListQuery,
} from 'app/generatedApi/selections';
import { renderInput, renderSelect } from 'features/fields';
import { Polygon } from 'shared/lib/geo';
import { RenovationTypes, SummerRooms } from 'shared/types/buildings';
import { Button } from 'shared/ui/Button/Button';
import { Loader } from 'shared/ui/Loader/Loader';
import { Tabs } from 'shared/ui/Tabs/Tabs';

import { SelectionForm } from '../SelectionForm/SelectionForm';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { SummerRoomsLabels } from 'shared/config/SummerRoomsLabels';
import { Form } from 'shared/ui/Form/Form';
import { useHandleErrors } from 'shared/lib/react/useHandleErrors';
import { useCrmCitiesListQuery } from 'app/generatedApi/crm';
import { SelectBase } from 'shared/ui/Select';

type CreateSelectionFormProps = {
    onSubmit?: (cs: CreateSelection) => void;
};
export const CreateSelectionForm: React.FC<CreateSelectionFormProps> = ({
    onSubmit,
}) => {
    const [createNewSelection, { data, isLoading, error }] =
        useSelectionsCreateMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && data) {
            navigate('/selection/' + data.id);
        }
    }, [data, error, isLoading, navigate]);

    const createSelection = (cs: CreateSelection) => {
        createNewSelection({ createSelection: cs });
    };

    return (
        <>
            <Tabs
                tabs={['Подбор по ЖК', 'Стандартный']}
                content={[
                    <CreateResidentialSelectionForm onSubmit={onSubmit} />,
                    <SelectionForm
                        onSubmit={onSubmit || createSelection}
                        isLoading={isLoading}
                    />,
                ]}
            />
        </>
    );
};

const CreateResidentialSelectionForm: React.FC<CreateSelectionFormProps> = ({
    onSubmit,
}) => {
    const [selectedCity, setSelectedCity] = useState<string | null>(null);
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
        setValue,
        setError,
    } = useForm<CreateSelection>({
        defaultValues: {
            type: 'residential_complex',
        },
    });

    const navigate = useNavigate();
    const {
        residentialComplex,
        residentialComplexHouse,
        residentialComplexApartment,
        // apartmentNumber,//todo: fix change generated Api
    } = watch();

    const [create, { isLoading: isCreating, error }] =
        useSelectionsCreateMutation();

    const { currentData: cities, isLoading: isLoadingCities } =
        useCrmCitiesListQuery();

    const {
        currentData: residentialComplexes,
        isFetching: residentialComplexesFetching,
    } = useSelectionsResidentialComplexesListQuery(
        selectedCity
            ? {
                  city: Number(selectedCity),
              }
            : {},
    );
    const [fetchHouses, { currentData: houses, isLoading: housesLoading }] =
        useLazySelectionsResidentialComplexHousesListQuery();

    const [
        fetchApartments,
        { currentData: apartments, isLoading: apartmentsLoading },
    ] = useLazySelectionsApartmentsListQuery();
    const house = useMemo(() => {
        if (residentialComplexHouse) {
            return houses?.find((h) => h.id === +residentialComplexHouse);
        }
    }, [residentialComplexHouse, houses]);

    const apartment = useMemo(() => {
        if (residentialComplexApartment) {
            return apartments?.find(
                (a) => a.id === +residentialComplexApartment,
            );
        }
    }, [residentialComplexApartment, apartments]);

    const complex = useMemo(() => {
        if (residentialComplex) {
            return residentialComplexes?.find(
                (rc) => rc.id === +residentialComplex,
            );
        }
    }, [residentialComplex, residentialComplexes]);

    useEffect(() => {
        if (complex) {
            fetchHouses({ residentialComplex: complex.id });
            if (complex) {
                setValue(
                    'location',
                    Polygon.parse(complex.polygon).getCenter().toString(),
                );
                // setValue('address', complex.address);
            }
        }
    }, [complex, fetchHouses, setValue]);

    useEffect(() => {
        if (house) {
            fetchApartments({ house: house.id });

            if (house) {
                setValue('maxFloor', house.floorsCount);
                setValue('builtYear', house.builtYear);
            }
        }
    }, [fetchApartments, house, setValue]);

    useEffect(() => {
        if (apartment) {
            setValue('floor', apartment.floor);
            setValue('renovationType', apartment.renovationType);
            setValue('area', apartment.area);
            setValue('roomsCount', apartment.roomsCount);
            // setValue('apartmentNumber', apartment.number);//todo: fix change generated Api
            setValue('summerRooms', apartment.summerRoomsType);
        }
    }, [apartment, setValue]);

    useHandleErrors<CreateSelection>(error, setError);

    const createSelection = useCallback(
        async (cs: CreateSelection) => {
            try {
                const { id } = await create({
                    createSelection: {
                        ...cs,
                        address: `${house?.address}`, //todo:${apartmentNumber} fix change generated Api
                    },
                }).unwrap();

                navigate('/selection/' + id);
            } catch (e) {}
        },
        [create, house?.address, navigate], //todo: fix change generated Api
    );

    return (
        <>
            <Form
                inProgress={isCreating}
                onSubmit={handleSubmit(onSubmit || createSelection)}>
                <input type="text" {...register('type')} hidden />
                <input type="text" {...register('address')} hidden />
                <input type="text" {...register('builtYear')} hidden />
                <input type="text" {...register('maxFloor')} hidden />
                <input type="text" {...register('location')} hidden />
                <SelectBase
                    formKey="city"
                    label="Город"
                    options={
                        cities?.map((c) => ({
                            name: c.name,
                            value: c.id.toString(),
                        })) ?? []
                    }
                    fieldClassName="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 [&_select]:sm:max-w-xs [&_div]:sm:mt-0 [&_div]:sm:col-span-2 [&_label]:sm:pt-1.5"
                    isLoading={isLoadingCities}
                    attrs={{
                        defaultValue: '',
                        onChange: (event) =>
                            setSelectedCity(event.target.value),
                    }}
                    emptyValue='Выберите город'
                />

                {renderSelect({
                    formKey: 'residentialComplex',
                    label: 'ЖК',
                    options:
                        residentialComplexes?.map((rc) => ({
                            value: rc.id.toString(),
                            name: rc.name,
                        })) ?? [],
                    register,
                    emptyValue: 'Выберите ЖК',
                    isLoading: residentialComplexesFetching,
                })}
                {housesLoading ? <Loader /> : null}
                {complex && houses ? (
                    <>
                        {renderSelect({
                            formKey: 'residentialComplexHouse',
                            label: 'Дом',
                            options:
                                houses?.map((h) => ({
                                    value: h.id.toString(),
                                    name: h.address || '',
                                })) ?? [],
                            register,
                            emptyValue: 'Выберите дом',
                        })}
                    </>
                ) : null}

                {apartmentsLoading ? <Loader /> : null}
                {residentialComplexHouse ? (
                    <>
                        {renderSelect({
                            formKey: 'residentialComplexApartment',
                            label: 'Квартира',
                            options:
                                apartments?.map((a) => ({
                                    value: a.id.toString(),
                                    name: a.number.toString(),
                                })) ?? [],
                            hint: 'Выберите квартиру или введите данные вручную.',
                            emptyValue: 'Ввести данные вручную',
                            register,
                            error: errors.residentialComplexApartment,
                        })}
                        {/* {renderInput({
                            formKey: 'apartmentNumber',
                            label: 'Номер квартиры',
                            register,
                            type: 'number',
                        })} */}
                        {/*todo: fix change generated Api*/}
                        {renderInput({
                            formKey: 'floor',
                            label: 'Этаж',
                            register,
                            type: 'number',
                            error: errors.floor,
                        })}
                        {renderInput({
                            formKey: 'roomsCount',
                            label: 'Кол-во комнат',
                            register,
                            type: 'number',
                            error: errors.roomsCount,
                        })}
                        {renderInput({
                            formKey: 'area',
                            label: 'Площадь',
                            register,
                            props: {
                                type: 'number',
                                step: '0.1',
                            },
                            error: errors.area,
                        })}
                        {renderSelect({
                            formKey: 'renovationType',
                            label: 'Ремонт',
                            options:
                                [
                                    RenovationTypes.UNKNOWN,
                                    RenovationTypes.NO,
                                    RenovationTypes.ROUGH,
                                    RenovationTypes.PRECLEAN,
                                    RenovationTypes.COSMETIC,
                                    RenovationTypes.EURO,
                                    RenovationTypes.DESIGN,
                                ].map((a) => ({
                                    value: a.toString(),
                                    name: RenovationsLabels[a],
                                })) ?? [],
                            register,
                        })}
                        {renderSelect({
                            formKey: 'summerRooms',
                            label: 'Летние помещения',
                            options:
                                [
                                    SummerRooms.UNKNOWN,
                                    SummerRooms.NO,
                                    SummerRooms.BALCONY,
                                    SummerRooms.LOGGIA,
                                ].map((sr) => ({
                                    value: sr.toString(),
                                    name: SummerRoomsLabels[sr],
                                })) ?? [],
                            register,
                        })}
                    </>
                ) : null}
                {complex && residentialComplexHouse && (
                    <Button type="submit">Запустить подбор</Button>
                )}
            </Form>
        </>
    );
};
