import React, { PropsWithChildren } from 'react';
import {
    FieldError,
    FieldValues,
    Path,
    UseFormRegister,
} from 'react-hook-form';

import { classnames as cn } from '../../../lib/classnames';
import { htmlFor } from '../../../lib/htmlFor';
import { FieldSize } from '../model/types';

import cls from './Field.module.css';
import { Skeleton } from './Skeleton';

export type FieldProps<T extends FieldValues> = {
    formKey: Path<T>;
    label: string;
    hint?: string;
    error?: FieldError;
    required?: boolean;
    size?: FieldSize;
    fieldClassName?: string;
    isLoading?: boolean;
    isShowError?: boolean;
};

export type FieldChildrenProps<T extends FieldValues> = FieldProps<T> & {
    register: UseFormRegister<T>;
};

export const Field = <T extends FieldValues>(
    props: PropsWithChildren<FieldProps<T>>,
) => {
    const {
        fieldClassName,
        size = 'm',
        children,
        formKey: key,
        label,
        error,
        hint,
        required,
        isLoading,
        isShowError,
    } = props;

    if (isLoading) {
        return (
            <Skeleton fieldClassName={fieldClassName} label={label} hint={hint} size={size}>
                {children}
            </Skeleton>
        );
    }
    return (
        <div
            className={cn(
                {
                    [cls['wrapper-xs']]: size === 'xs',
                    [cls['wrapper-s']]: size === 's',
                    [cls['wrapper']]: size === 'm',
                    [cls['wrapper-l']]: size === 'l',
                    [cls['wrapper-xl']]: size === 'xl',
                },
                fieldClassName || '',
            )}>
            {label && (
                <label htmlFor={htmlFor(key)} className={cls.label}>
                    {label}
                    {required && <span className="text-red-600"> *</span>}
                </label>
            )}
            <div
                className={cn(cls.innerControl, {
                    [cls.innerControlError]: !!error,
                })}>
                {children}

                {hint && <p className={cls.messageInfo}>{hint}</p>}
                {isShowError && error && (
                    <p className={cls.messageError}>
                        {error.type === 'required'
                            ? 'Это поле обязательное для заполнения'
                            : error.message}
                    </p>
                )}
            </div>
        </div>
    );
};
