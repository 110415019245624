import React, { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { FieldValues } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';

import { htmlFor } from '../../../lib/htmlFor';
import { Field, FieldChildrenProps } from '../../Field';


export type InputFormProps<T extends FieldValues> = FieldChildrenProps<T> & {
    type?: HTMLInputTypeAttribute;
    attrs?: InputHTMLAttributes<HTMLInputElement>;
    mask?: string;
};
export const InputForm = <T extends FieldValues>(
    {
        formKey,
        label,
        type,
        attrs,
        error,
        required,
        size,
        mask,
        fieldClassName,
        register,
        isLoading,
        isShowError = true,
    }: InputFormProps<T>,
) => {
    const registerWithMask = useHookFormMask(register)

    return (
        <Field
            formKey={formKey}
            label={label}
            error={error}
            required={required}
            size={size}
            fieldClassName={fieldClassName}
            isLoading={isLoading}
            isShowError={isShowError}
        >
            {mask
                ? <input
                    id={htmlFor(formKey)}
                    type={type}
                    {...attrs}
                    {...registerWithMask(formKey, mask)}
                />
                : <input
                    id={htmlFor(formKey)}
                    type={type}
                    {...attrs}
                />
            }
        </Field>
    );
};
