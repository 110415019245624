import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from 'app/hooks';

type Token = string | null;

type AuthState = {
    token: Token;
    user: string;
};

const token =
    localStorage.getItem('auth.token') ||
    sessionStorage.getItem('auth.token') ||
    null;

const slice = createSlice({
    name: 'auth',
    initialState: {
        token,
        user: '',
    },
    reducers: {
        setToken: (state, action: PayloadAction<Token>) => {
            state.token = action.payload;
        },
        setUser: (state, action: PayloadAction<string>) => {
            state.user = action.payload;
        },
        logOut: (state) => {
            state.token = null;
        },
    },
});

export const { setToken, setUser, logOut } = slice.actions;
export const useGetToken = () => {
    return useAppSelector(currentToken);
};
export default slice.reducer;

export const currentUser = (state: { auth: AuthState }) => state.auth.user;
export const currentToken = (state: { auth: AuthState }) => state.auth.token;
