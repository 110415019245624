import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/solid';
import {
    FC,
    useCallback,
    useEffect,
    useState,
    forwardRef,
    Fragment,
} from 'react';
import { FieldError } from 'react-hook-form';
import { FieldSkeleton } from '../../../ui/Field';
import { classnames as cn } from '../../../lib/classnames';

export type Option = { value: string | number; name: string };

export type SingleComboboxProps = {
    options: Array<string | Option>;
    onChange?: (option: string | number | Option) => void;
    label: string;
    hint?: string;
    value?: string | number | Option | null;
    className?: string;
    isLoading?: boolean;
    required?: boolean;
    error?: FieldError;
    isCustomOption?: boolean;
    ref?: React.LegacyRef<HTMLElement>;
};
export const SingleCombobox: FC<SingleComboboxProps> = forwardRef(
    (
        {
            options,
            label,
            onChange,
            value,
            hint,
            className = '',
            isLoading,
            required,
            error,
            isCustomOption,
        },
        ref,
    ) => {
        const [query, setQuery] = useState('');
        const [selectedOption, setSelectedOption] = useState<
            string | Option | null
        >(null);

        useEffect(() => {
            if (value) {
                const option = options.find((option) => {
                    if (typeof option === 'object') {
                        if (typeof value === 'object') {
                            return String(option.value) === String(value.value);
                        } else {
                            return String(option.value) === String(value);
                        }
                    } else {
                        return option === value;
                    }
                });

                if (option) {
                    setSelectedOption(option);
                }
            }
        }, [options, value]);

        const filteredOptions =
            query === ''
                ? options
                : options.filter((option) => {
                      if (typeof option === 'object') {
                          return option.name
                              .toLowerCase()
                              .includes(query.toLowerCase());
                      } else {
                          return option
                              .toLowerCase()
                              .includes(query.toLowerCase());
                      }
                  });

        const onSelectOption = useCallback(
            (option: string | Option) => {
                setSelectedOption(option);

                onChange?.(
                    typeof value !== 'object' && typeof option === 'object'
                        ? option.value
                        : option,
                );
            },
            [onChange, value],
        );

        if (isLoading) {
            return (
                <FieldSkeleton label={label} fieldClassName={className}>
                    <div />
                </FieldSkeleton>
            );
        }

        return (
            <div className={className}>
                <Combobox
                    as="div"
                    ref={ref}
                    value={selectedOption}
                    onChange={onSelectOption}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        {label}
                        {required && <span className="text-red-600"> *</span>}
                    </Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                            placeholder="Выберите значение"
                            className={cn(
                                'w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                                {
                                    'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                                        !!error,
                                },
                            )}
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={(
                                o: string | number | Option | null,
                            ) =>
                                typeof o === 'object'
                                    ? o?.name || ''
                                    : o.toString()
                            }
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>

                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}>
                            <Combobox.Options
                                as="div"
                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredOptions.length > 0 ? (
                                    filteredOptions.map((option) => (
                                        <Combobox.Option
                                            key={
                                                typeof option === 'object'
                                                    ? option.value
                                                    : option
                                            }
                                            value={option}
                                            className={({ active }) =>
                                                cn(
                                                    'relative cursor-default select-none py-2 pl-3 pr-9 list-none',
                                                    active
                                                        ? 'bg-indigo-600 text-white'
                                                        : 'text-gray-900',
                                                )
                                            }>
                                            {({ active, selected }) => (
                                                <>
                                                    <span
                                                        className={cn(
                                                            'block truncate',
                                                            selected
                                                                ? 'font-semibold'
                                                                : '',
                                                        )}>
                                                        {typeof option ===
                                                        'object'
                                                            ? option.name
                                                            : option}
                                                    </span>

                                                    {selected && (
                                                        <span
                                                            className={cn(
                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-indigo-600',
                                                            )}>
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                ) : query.length ? (
                                    <Combobox.Option
                                        value={
                                            typeof value === 'object'
                                                ? {
                                                      name: query,
                                                      value: query,
                                                  }
                                                : query
                                        }
                                        className="relative cursor-pointer select-none py-2 pl-3 pr-9 list-none">
                                        Добавить "{query}"
                                    </Combobox.Option>
                                ) : null}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
                {hint && (
                    <p className="mt-3 text-sm leading-6 text-gray-600">
                        {hint}
                    </p>
                )}
                {error && (
                    <p className="mt-3 text-sm leading-6 text-red-600">
                        {error.type === 'required'
                            ? 'Это поле обязательное для заполнения'
                            : error.message}
                    </p>
                )}
            </div>
        );
    },
);
