import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from 'app/baseQuery';

export const adsApi = createApi({
    reducerPath: 'adsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        searchAnalogs: builder.mutation({
            query: (body) => ({
                url: '/api/v1/ads/analogs',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useSearchAnalogsMutation } = adsApi;
