import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from 'app/baseQuery';
import { saveInStorage } from 'shared/lib/saveInStorage';

import { setToken } from '../model/authSlice';

type Credentials = {
    username: string;
    password: string;

    keepLoggedIn: boolean;
};

type Token = {
    token: string;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation<Token, Credentials>({
            query: (body) => ({
                url: '/api/v1/auth/token/',
                method: 'POST',
                body: { username: body.username, password: body.password },
            }),
            extraOptions: {
                skipAuth: true,
            },
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(setToken(data.token));
                saveInStorage(
                    'auth.keepLoggedIn',
                    String(arg.keepLoggedIn),
                    true
                );
                saveInStorage('auth.token', data.token, arg.keepLoggedIn);
            },
        }),
    }),
});

export const { useLoginMutation } = authApi;
