import { FC, Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';

import logo from 'shared/assets/images/logo_white.png';
import { LinkApp } from 'shared/ui/LinkApp';

type ItemMenu = { name: string; href: string }

interface HeaderProps {
    menu?: ItemMenu[];
    userMenu?: ItemMenu[]
}

export const Header: FC<HeaderProps> = ({ menu = [], userMenu = [] }) => {
    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        className="h-8 w-8"
                                        src={logo}
                                        alt="Ocenka"
                                    />
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {menu.map((item) => (
                                            <LinkApp
                                                key={item.name}
                                                to={item.href}
                                                baseClass="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                                                activeClass="bg-gray-900 text-white">
                                                {item.name}
                                            </LinkApp>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-4 flex items-center md:ml-6">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span className="sr-only">
                                                    Открыть пользовательское
                                                    меню
                                                </span>
                                                <UserCircleIcon
                                                    className="h-8 w-8 text-white rounded-full"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userMenu.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {() => (
                                                            <LinkApp
                                                                to={item.href}
                                                                baseClass="block px-4 py-2 text-sm text-gray-700"
                                                                activeClass="bg-gray-100">
                                                                {item.name}
                                                            </LinkApp>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                            <div className="-mr-2 flex md:hidden">
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Открыть главное меню
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                            {menu.map((item) => (
                                <LinkApp
                                    key={item.name}
                                    to={item.href}
                                    baseClass="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                                    activeClass="bg-gray-900 text-white">
                                    {item.name}
                                </LinkApp>
                            ))}
                        </div>
                        <div className="border-t border-gray-700 pb-3 pt-4">
                            <div className="flex items-center px-5">
                                <div className="flex-shrink-0">
                                    <UserCircleIcon
                                        className="h-8 w-8 text-white rounded-full"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                            <div className="mt-3 space-y-1 px-2">
                                {userMenu.map((item) => (
                                    <LinkApp
                                        key={item.name}
                                        to={item.href}
                                        baseClass="block rounded-md px-3 py-2 text-base text-gray-400 active:bg-gray-700 active:text-white"
                                        activeClass="bg-gray-700 text-white">
                                        {item.name}
                                    </LinkApp>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};
