import { FC } from 'react';

import { DataList } from 'shared/ui/DataList';
import { SliderApp } from 'shared/ui/SliderApp/ui/SliderApp/SliderApp';

import { OrderCustomer } from '../module/types';

interface Display1Props {
    values: OrderCustomer;
}

export const Display1: FC<Display1Props> = ({ values }) => {
    return (
        <div className="grid grid-cols-2 gap-16">
            <div>
                <h3 className="block mb-4 text-2xl font-semibold">Заказчик</h3>
                <DataList items={values.customer} />
            </div>
            <div>
                <SliderApp slides={values.slides} imageNav={true} modal={true} />
            </div>
        </div>
    );
};
