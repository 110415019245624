import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

interface SkeletonPaginationProps {}

export const SkeletonPagination: FC<SkeletonPaginationProps> = () => {
    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 animate-pulse">
            <div className="flex flex-1 justify-between sm:hidden"></div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="w-full">
                    <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                </div>
                <div>
                    <div
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination">
                        <div className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover-bg-gray-50 cursor-pointer">
                            <ChevronLeftIcon className="h-5 w-5" />
                            <span className="sr-only">Предыдущая</span>
                        </div>
                        <div className="relative w-9 h-9 inline-flex items-center bg-indigo-600 text-white text-sm font-semibold"></div>
                        <div className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover-bg-gray-50 cursor-pointer">
                            <ChevronRightIcon className="h-5 w-5" />
                            <span className="sr-only">Следующая</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
