import { FC, ReactNode } from 'react';

interface CardStatisticProps {
    title: string;
    children?: ReactNode;
}

const CardStatistic: FC<CardStatisticProps> = ({ title, children }) => {
    return (
        <div className="flex items-center justify-center flex-col text-center overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <h3 className="text-sm font-medium text-gray-500">{title}</h3>
            <h4 className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{children}</h4>
        </div>
    );
};

export default CardStatistic;
