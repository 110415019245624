import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Order, useCrmCitiesListQuery } from 'app/generatedApi/crm';
import { ErrorMessage } from 'app/const/ErrorMessage';
import { InputForm } from 'shared/ui/Input';
import { Section } from 'shared/ui/Section';
import { Combobox } from 'shared/ui/Combobox';
import { format } from 'shared/lib/format';

import { Step, StepProps } from './Step';

export const OrderStep: React.FC<StepProps & {}> = (props) => {
    const { currentData: cities, isLoading } = useCrmCitiesListQuery();
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext<Order>();

    const optionsCity = useMemo(
        () =>
            cities
                ?.map((c) => ({
                    name: c.name,
                    value: c.id.toString(),
                }))
                .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
        [cities],
    );

    return (
        <Step {...props} validate={['city', 'amoCrmLeadId']} validateOnNext>
            <Section
                title="Данные по заявке"
                isLoading={isLoading || props.isLoading}>
                <Controller
                    control={control}
                    name={'city'}
                    rules={{ required: true }}
                    render={({ field }) => {
                        const selectCity = optionsCity.find(
                            (city) => city.value === String(field.value),
                        );

                        const selectedValue = selectCity
                            && {
                                  name: selectCity.name,
                                  value: selectCity.value,
                              }
                        return (
                            <Combobox
                                {...field}
                                value={selectedValue?.value}
                                label="Город"
                                isLoading={isLoading || props.isLoading}
                                className="col-span-6"
                                error={errors.city}
                                required={true}
                                options={optionsCity}
                            />
                        );
                    }}
                />
                <InputForm
                    size={'xl'}
                    formKey={'amoCrmLeadId'}
                    label={'Номер лида в Amo'}
                    register={register}
                    required={true}
                    attrs={{
                        type: 'number',
                        ...register('amoCrmLeadId', {
                            required: true,
                            min: {
                                value: 1,
                                message: format(ErrorMessage.min, 1),
                            },
                        }),
                    }}
                    error={errors.amoCrmLeadId}
                />
            </Section>
        </Step>
    );
};
