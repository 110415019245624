import React from 'react';
import { FieldValues } from 'react-hook-form';

import { htmlFor } from '../../../lib/htmlFor';
import { Field, FieldChildrenProps } from '../../Field';

export type SelectFormProps<T extends FieldValues> = FieldChildrenProps<T> & {
    defaultValue?: string;
    options: { value: string; name: string }[];
    emptyValue?: string;
    attrs?: React.SelectHTMLAttributes<HTMLSelectElement>;
};
export const SelectForm = <T extends FieldValues>({
    options,
    emptyValue,
    formKey,
    label,
    hint,
    error,
    defaultValue = '',
    required,
    size,
    fieldClassName,
    attrs,
    isLoading,
}: SelectFormProps<T>) => {
    return (
        <Field
            formKey={formKey}
            label={label}
            hint={hint}
            error={error}
            required={required}
            size={size}
            fieldClassName={fieldClassName}
            isLoading={isLoading}>
            <select
                id={htmlFor(formKey)}
                defaultValue={defaultValue}
                {...attrs}>
                {emptyValue && (
                    <option value="" disabled>
                        {emptyValue}
                    </option>
                )}
                {options?.map((value) => (
                    <option key={value.value} value={value.value}>
                        {value.name}
                    </option>
                ))}
            </select>
        </Field>
    );
};
