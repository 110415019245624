import { RetrieveOrderRead } from 'app/generatedApi/crm';
import { FC } from 'react';
import { DataItem, DataList } from 'shared/ui/DataList';
import { LabelLayoutTypeLabel, LabelSummerRoomsType } from '../model/consts';

interface ObjectListProps {
    order?: RetrieveOrderRead;
    isLoading?: boolean;
}

export const ObjectList: FC<ObjectListProps> = ({ order, isLoading }) => {
    const orderInfo: DataItem[] = [];
    order?.address &&
        orderInfo.push({
            name: 'Адрес',
            value: order.address,
        });
    order?.subjectRf &&
        orderInfo.push({
            name: 'Название ЖК',
            value: order.subjectRf,
        });
    order?.locality &&
        orderInfo.push({
            name: 'Субъект РФ',
            value: order.locality,
        });
    order?.street &&
        orderInfo.push({
            name: 'Улица',
            value: order.street,
        });
    order?.houseNumber &&
        orderInfo.push({
            name: 'Номер дома',
            value: order.houseNumber,
        });
    order?.kadastrNumber &&
        orderInfo.push({
            name: 'Кадастровый номер',
            value: order.kadastrNumber,
        });
    order?.apartment &&
        orderInfo.push({
            name: 'Номер квартиры',
            value: order.apartment,
        });
    order?.countOfApartmentsOnFloor &&
        orderInfo.push({
            name: 'Кол-во квартир на этаже',
            value: order.countOfApartmentsOnFloor,
        });
    order?.houseFloors &&
        orderInfo.push({
            name: 'Кол-во этажей',
            value: order.houseFloors,
        });
    order?.floorNumber &&
        orderInfo.push({
            name: 'Этаж',
            value: order.floorNumber,
        });
    order?.roomsCount &&
        orderInfo.push({
            name: 'Кол-во комнат',
            value: order.roomsCount,
        });
    order?.area &&
        orderInfo.push({
            name: 'Общая площадь, кв. м.',
            value: order.area,
        });
    order?.summerRoomArea &&
        orderInfo.push({
            name: 'Площадь летних помещений, кв. м',
            value: order.summerRoomArea,
        });
    order?.areaWithSummerRooms &&
        orderInfo.push({
            name: 'Общая площадь с лет. помещениями, кв. м',
            value: order.areaWithSummerRooms,
        });
    order?.livingArea &&
        orderInfo.push({
            name: 'Жилая площадь, кв. м',
            value: order.livingArea,
        });
    order?.kitchenArea &&
        orderInfo.push({
            name: 'Площадь кухни, кв. м',
            value: order.kitchenArea,
        });
    order?.separateKitchen &&
        orderInfo.push({
            name: 'Имеет отдельную от других объектов недвижимости кухню',
            value: order.separateKitchen ? 'Да' : 'Нет',
        });
    order?.utilityRoomsArea &&
        orderInfo.push({
            name: 'Вспомогательные и подсобные помещения, кв. м',
            value: order.utilityRoomsArea,
        });
    order?.ceilingHeights &&
        orderInfo.push({
            name: 'Высота потолков, кв. м',
            value: order.ceilingHeights,
        });
    order?.countAndTypeOfRestrooms &&
        orderInfo.push({
            name: 'Кол-во и тип санузлов',
            value: order.countAndTypeOfRestrooms,
        });
    order?.separateRestroom &&
        orderInfo.push({
            name: 'Имеет отдельный от других объектов недвижимости санузел (ванная комната и туалет)',
            value: order.separateRestroom ? 'Да' : 'Нет',
        });
    order?.summerRoomsType &&
        orderInfo.push({
            name: 'Наличие балкона/лоджии',
            value: LabelSummerRoomsType[order.summerRoomsType],
        });
    order?.layoutType &&
        orderInfo.push({
            name: 'Тип планировки',
            value: LabelLayoutTypeLabel[order.layoutType],
        });
    order?.buildingWeakness &&
        orderInfo.push({
            name: 'Процент износа здания (согласно данным БТИ), %',
            value: order.buildingWeakness,
        });
    order?.floorPlanBti &&
        orderInfo.push({
            name: 'Поэтажный план (по документам БТИ)',
            type: 'file',
            file: order.floorPlanBti,
        });
    order?.legalDocuments &&
        orderInfo.push({
            name: 'Правоподтверждающие документы',
            type: 'file',
            documentsGroup: order.legalDocuments,
        });
    order?.techDocuments &&
        orderInfo.push({
            name: 'Технические документы',
            type: 'file',
            documentsGroup: order.techDocuments,
        });
    order?.district &&
        orderInfo.push({
            name: 'Район города',
            value: order.district,
        });
    order?.nearestMetroStation &&
        orderInfo.push({
            name: 'Ближайшая станция метро',
            value: order.nearestMetroStation,
        });
    order?.nearestMetroStationDistance &&
        orderInfo.push({
            name: 'Расстояние до ближайшей станции метро, м',
            value: order.nearestMetroStationDistance,
        });
    order?.groundTransportStop &&
        orderInfo.push({
            name: 'Остановка наземного транспорта',
            value: order.groundTransportStop,
        });
    order?.groundTransportStopDistance &&
        orderInfo.push({
            name: 'Расстояние до остановки, м',
            value: order.groundTransportStopDistance,
        });
    return <DataList items={orderInfo} isLoading={isLoading} />;
};
