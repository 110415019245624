import { FC } from 'react';

import { DataList } from 'shared/ui/DataList/ui/DataList';
import { SliderApp } from 'shared/ui/SliderApp';

import { OrderObjectBuild } from '../module/types';

interface Display2Props {
    values: OrderObjectBuild;
}

export const Display2: FC<Display2Props> = ({ values }) => {
    return (
        <div className="grid grid-cols-2 gap-16">
            <div>
                <h3 className="block mb-4 text-2xl font-semibold">
                    Объект оценки
                </h3>
                {values.buildFields.map((item, index) => (
                    <DataList
                        key={index}
                        title={item.title}
                        items={item.value}
                    />
                ))}
            </div>
            <div>
                <SliderApp slides={values.slides} imageNav={true} />
            </div>
        </div>
    );
};
