import { XMarkIcon } from '@heroicons/react/20/solid';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { FC, MouseEvent, memo, useCallback } from 'react';
import { classnames } from 'shared/lib/classnames';
import { isDocumentReadType } from 'shared/lib/helpers';
import { validationImage } from 'shared/lib/validation/validationImage';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import { Document } from '../module/types';

export interface DocumentItemProps {
    doc: Document;
    index: number;
    isCheckMoveFile: boolean;
    handleImageDelete: (index: number) => Promise<void>;
    handleImageMove: (doc: Document, index: number) => void;
    uploadingFiles: (files: File[]) => Promise<void>;
    deletingPhotos: number[];
}

export const DocumentItem: FC<DocumentItemProps> = memo(
    ({
        doc,
        index,
        isCheckMoveFile,
        handleImageDelete,
        handleImageMove,
        deletingPhotos,
    }) => {
        const onImageDelete = useCallback(
            (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                !deletingPhotos.includes(index) && handleImageDelete(index);
            },
            [deletingPhotos, handleImageDelete, index],
        );

        return (
            <div
                className={classnames(
                    'relative border rounded-md overflow-hidden',
                    {
                        'border-solid border-indigo-600': !!doc.isChecked,
                        'cursor-pointer': isCheckMoveFile,
                    },
                )}
                onClick={() => handleImageMove(doc, index)}>
                {validationImage(
                    String(
                        isDocumentReadType(doc) ? doc?.originalName : doc.image,
                    ),
                ) ? (
                    (doc.file || (!isDocumentReadType(doc) && doc.image)) && (
                        <img
                            src={
                                isDocumentReadType(doc)
                                    ? String(doc.file)
                                    : String(doc.image)
                            }
                            alt="Preview"
                            className="w-full h-32 object-contain"
                        />
                    )
                ) : (
                    <div className="relative bg-gray-100 h-32 flex flex-col justify-center items-center rounded-md">
                        <DocumentTextIcon className="h-8 w-8 text-gray-400" />
                    </div>
                )}
                <div className="absolute top-2 right-2 grid gap-2 cursor-default">
                    <Button
                        onClick={onImageDelete}
                        round
                        theme={ButtonTheme.GREYOPACITY}
                        className="hover:!text-red-500">
                        {deletingPhotos.includes(index) ? (
                            <ArrowPathIcon className="h-6 w-6 animate-spin" />
                        ) : (
                            <XMarkIcon className="h-6 w-6" />
                        )}
                    </Button>
                </div>
            </div>
        );
    },
);
