import { FC } from 'react';

import { useCrmOrdersNextRetrieveQuery } from 'app/generatedApi/crm';
import { handleError } from 'shared/lib/handleError';
import { ErrorMessage } from 'shared/ui/ErrorMessage/ErrorMessage';

import { StatisticMonth } from '../StatisticMonth/StatisticMonth';

import CurrentOrder from './ui/CurrentOrder';
import Skeleton from './ui/Skeleton';

interface NewApplicationProps {}

export const NewApplication: FC<NewApplicationProps> = () => {
    const {
        data: newOrder,
        isLoading,
        error,
    } = useCrmOrdersNextRetrieveQuery();

    if (error) {
        return <ErrorMessage message={handleError(error)} />;
    }

    return (
        <div className="grid gap-6 sm:grid-cols-2 font-semibold">
            {isLoading ? <Skeleton /> : <CurrentOrder order={newOrder!} />}
            <StatisticMonth />
        </div>
    );
};
