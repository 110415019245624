import React from 'react';
import { HeadingActions } from 'widgets/heading';

import { CreateSelectionForm } from './CreateSelectionForm';

export const NewSelection: React.FC = () => {
    return (
        <>
            <HeadingActions title="Новый подбор" />
            <div className="bg-white white mx-auto max-w-7xl px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                <CreateSelectionForm />
            </div>
        </>
    );
};
