import { SelectionRead } from 'app/generatedApi/crm';
import { FC, useMemo } from 'react';
import { DataItem, DataList } from 'shared/ui/DataList';

interface SelectionListProps {
    selection: SelectionRead;
}

export const SelectionList: FC<SelectionListProps> = ({ selection }) => {
    const pricePerMeterRange = useMemo(() => {
        const prices =
            selection.correctedAnalogs?.map((a) => a.correctedPricePerMeter!) ??
            [];
        return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }, [selection.correctedAnalogs]);

    const orderInfo: DataItem[] = [
        { name: 'Рыночная стоимость', value: selection.price },
        {
            name: 'Средневзвешенная стоимость 1 кв. м.',
            value: selection.pricePerMeter,
        },
        { name: 'Всего найдено аналогов', value: selection.analogs.length },
        { name: 'Радиус поиска аналогов, м.', value: selection.distance },
        {
            name: 'Аналогов использовано в отчете',
            value: selection.correctedAnalogs.length,
        },
        {
            name: 'Отношение самого дорогого к самому дешевому аналогу',
            value: selection.ratio,
        },
        {
            name: 'Диапазон стоимости кв. метра аналогов, руб:',
            value: pricePerMeterRange,
        },
    ];
    return <DataList items={orderInfo} />;
};
