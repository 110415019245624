import {
    useRef,
    useState,
    useCallback,
    MouseEvent,
    ChangeEvent,
    useEffect,
} from 'react';
import ReactCrop, { PixelCrop, Crop } from 'react-image-crop';
import { validationImage } from '../validation/validationImage';
import { DialogApp, DialogSize } from '../../ui/DialogApp/DialogApp';
import { Button } from '../../ui/Button/Button';
import { useDebounceEffect } from './useDebounceEffect';
import { getCroppedImg } from '../canvasUtils';
import { canvasPreview } from '../canvasPreview';
import { UploadFileRead } from 'app/generatedApi/files';
import { classnames } from '../classnames';
import 'react-image-crop/dist/ReactCrop.css';
import { DocumentRead } from 'app/generatedApi/crm';

export function useCropModalFile(
    document: UploadFileRead | DocumentRead | null,
    uploadFile: (file: File) => Promise<void>,
) {
    const [originalFile, setOriginalFile] = useState<File>();
    const [imgSrc, setImgSrc] = useState('');
    const [imgName, setImgName] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [crop, setCrop] = useState<Crop>();
    const [isCropping, setIsCropping] = useState(false);
    const [isOpenCropModal, setIsOpenCropModal] = useState(false);

    useEffect(() => {
        if (document) {
            setImgSrc(document?.file || '');
            setImgName(
                String(
                    'originalName' in document
                        ? document?.originalName
                        : document.name,
                ),
            );
        }
    }, [document]);

    const onShowCropModal = useCallback(
        (
            event:
                | MouseEvent<HTMLButtonElement>
                | ChangeEvent<HTMLInputElement>,
        ) => {
            event.stopPropagation();

            if (validationImage(imgSrc)) {
                setIsOpenCropModal(true);
                setCrop(undefined);
            } else if (
                'files' in event.target &&
                event.target.files &&
                event.target.files.length > 0 &&
                validationImage(event.target.files[0].name)
            ) {
                setOriginalFile(event.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    setImgSrc(reader.result?.toString() || ''),
                );
                reader.readAsDataURL(event.target.files[0]);
                setImgName(event.target.files[0].name);
                setIsOpenCropModal(true);
                setCrop(undefined);
            }
        },
        [imgSrc],
    );

    const onCropImage = async () => {
        try {
            setIsCropping(true);
            if (completedCrop) {
                const blob = await getCroppedImg(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                );

                await uploadFile(
                    new File([blob], imgName || imgSrc, {
                        type: blob.type,
                        lastModified: Date.now(),
                    }),
                );
            } else if (originalFile) {
                await uploadFile(originalFile);
            }
        } catch (e) {
        } finally {
            setImgName('');
            setImgSrc('');
            setIsCropping(false);
            setIsOpenCropModal(false);
        }
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                );
            }
        },
        [completedCrop],
        100,
    );

    const ModalCrop = (
        <DialogApp
            open={isOpenCropModal}
            setOpen={setIsOpenCropModal}
            title="Обрезать изображение"
            size={DialogSize.FULL}
            actionSuccess={
                <Button onClick={onCropImage} loading={isCropping}>
                    Обрезать
                </Button>
            }>
            <div className="flex justify-center relative z-10">
                {!!validationImage(imgSrc) && (
                    <ReactCrop
                        className={classnames(
                            {
                                'pointer-events-none': isCropping,
                            },
                            'rounded-md overflow-hidden gap-2',
                        )}
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}>
                        {isCropping && (
                            <div className="absolute inset-0 bg-gray-200 opacity-80 animate-pulse" />
                        )}
                        <img
                            crossOrigin="anonymous"
                            ref={imgRef}
                            src={imgSrc}
                            alt="Crop me"
                        />
                    </ReactCrop>
                )}
                {!!completedCrop && (
                    <div className="absolute pointer-events-none opacity-0">
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                )}
            </div>
        </DialogApp>
    );

    return { onShowCropModal, ModalCrop };
}
