import { generatedApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        filesCreate: build.mutation<FilesCreateApiResponse, FormData>({
            query: (fd) => ({
                url: `/api/v1/files/`,
                method: 'POST',
                body: fd,
                formData: true,
            }),
        }),
        filesUploadCreate: build.mutation<
            FilesUploadCreateApiResponse,
            FilesUploadCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/files/upload/`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        filesUploadCompleteCreate: build.mutation<
            FilesUploadCompleteCreateApiResponse,
            FilesUploadCompleteCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/files/upload/${queryArg.id}/complete/`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type FilesCreateApiResponse = /** status 201  */ UploadFileRead;
export type FilesCreateApiArg = {
    uploadFile: UploadFile;
};
export type FilesUploadCreateApiResponse = /** status 201  */ UploadRead;
export type FilesUploadCreateApiArg = {
    name: string;
};
export type FilesUploadCompleteCreateApiResponse = UploadFileRead;
export type FilesUploadCompleteCreateApiArg = {
    /** A unique integer value identifying this Загрузка. */
    id: number;
};
export type UploadFile = {
    file: File;
};
export type UploadFileRead = {
    id: number;
    name: string;
    file: string;
    thumbnail: string | null;
};
export type Upload = {
    name: string;
};
export type UploadRead = {
    id: number;
    name: string;
    params: any;
};
export const {
    useFilesCreateMutation,
    useFilesUploadCreateMutation,
    useFilesUploadCompleteCreateMutation,
} = injectedRtkApi;
