import { FC, PropsWithChildren } from 'react';
import { classnames } from '../../../lib/classnames';
import { FieldSize } from '../model/types';
import cls from './Field.module.css';

interface SkeletonProps {
    label: string;
    hint?: string;
    size?: FieldSize;
    fieldClassName?: string;
}

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
    children,
    label,
    hint,
    size,
    fieldClassName,
}) => {
    return (
        <div
            className={classnames(
                {
                    [cls['wrapper-s']]: size === 's',
                    [cls['wrapper']]: size === 'm',
                    [cls['wrapper-l']]: size === 'l',
                    [cls['wrapper-xl']]: size === 'xl',
                },
                'animate-pulse',
                fieldClassName || '',
            )}>
            {label && (
                <div
                    className={classnames(
                        cls.label,
                        'h-6 bg-gray-200 rounded w-1/4',
                    )}
                />
            )}
            {children && (
                <div
                    className={classnames(
                        cls.innerControl,
                        'h-9 bg-gray-200 rounded w-full',
                    )}
                />
            )}
            {hint && <p className={classnames(cls.messageInfo, 'bg-gray-200 rounded w-1/2')} />}
        </div>
    );
};
