import { StatusD4EEnum } from 'app/generatedApi/crm';

export const OrderStatusLabels: Record<StatusD4EEnum, string> = {
    inspection_done: 'Осмотр завершен',
    inspection_request: 'Осмотр запрошен',
    inspection_waiting: 'Ожидается осмотр',
    report_done: 'Отчет сгенерирован',
    report_error: 'Ошибки генерации отчета',
    report_waiting: 'Отчет генерируется',
    selection_done: 'Расчет проведен',
    selection_error: 'Ошибка расчета',
    selection_waiting: 'Расчет стоимости',
    draft: 'Черновик',
    new: 'Новый',
    queued: 'В очереди',
    processing: 'Проверка отчета',
    declined: 'Отклонен',
    completed: 'Завершен',
    archived: 'Заархивирован',
};
