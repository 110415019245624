import { isNil, mapValues, pickBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import {
    Order,
    useCrmOrdersUpdateMutation,
    useLazyCrmOrdersRetrieveQuery,
    useCrmOrdersCreateMutation,
    OwnerRead,
} from 'app/generatedApi/crm';
import { Form } from 'shared/ui/Form/Form';
import { CustomerStep, ObjectStep, OrderStep } from 'widgets/applications';
import { HeadingActions } from 'widgets/heading';
import { useHandleErrors } from 'shared/lib/react/useHandleErrors';

const steps = [OrderStep, CustomerStep, ObjectStep];

export const ApplicationEdit: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentStep = useMemo(() => {
        return Number.parseInt(searchParams.get('step') || '0');
    }, [searchParams]);

    const onPrevStep = useCallback(() => {
        if (currentStep > 0) {
            setSearchParams({ step: `${currentStep - 1}` });
        }
    }, [currentStep, setSearchParams]);

    const CurrentStep = useMemo(() => {
        return steps[currentStep];
    }, [currentStep]);

    const methods = useForm<Order & { owners: OwnerRead[] }>({
        defaultValues: {
            encumbrances: false,
            ooType: 'residential_complex',
        },
    });
    const { handleSubmit, reset, watch, setError } = methods;
    const { id } = useParams();
    const orderDraft = watch();

    const [fetchOrder, { data: fetchedOrder, isFetching }] =
        useLazyCrmOrdersRetrieveQuery();
    const [update, { isLoading: isUpdating, error }] =
        useCrmOrdersUpdateMutation();
    const [create, { isLoading: isCreating }] = useCrmOrdersCreateMutation();

    useEffect(() => {
        if (id) {
            fetchOrder({ id: +id });
        }
    }, [fetchOrder, id]);

    useEffect(() => {
        if (fetchedOrder) {
            reset({
                ...mapValues<Order>(fetchedOrder, (v: string) =>
                    isNil(v) ? '' : v,
                ),
                bank: fetchedOrder?.bank?.id ?? null,
                floorPlanBti: fetchedOrder?.floorPlanBti?.id ?? null,
                legalDocuments:
                    fetchedOrder?.legalDocuments?.map((ld) => ld.id) ?? [],
                techDocuments:
                    fetchedOrder?.techDocuments?.map((ld) => ld.id) ?? [],
                residentialComplex:
                    fetchedOrder?.residentialComplex?.id ?? null,
                residentialComplexHouse:
                    fetchedOrder?.residentialComplexHouse?.id ?? null,
                residentialComplexApartment:
                    fetchedOrder?.residentialComplexApartment?.id ?? null,
                buildingWeakness: null ?? 0,
            });
        }
    }, [fetchedOrder, reset]);

    useHandleErrors<Order>(error, setError);

    const navigate = useNavigate();

    const onSubmit = useCallback(async () => {
        const draft = pickBy(orderDraft, (v) => v !== '');
        try {
            const order = {
                ...draft,
                status:
                    fetchedOrder?.status === 'draft'
                        ? 'new'
                        : fetchedOrder?.status,
            };
            if (id) {
                await update({
                    id: fetchedOrder?.id!,
                    order,
                }).unwrap();
                navigate('/applications/' + fetchedOrder?.id);
            } else {
                const newApplication = await create({
                    order,
                }).unwrap();
                navigate('/applications/' + newApplication.id);
            }
        } catch (e) {}
    }, [
        orderDraft,
        fetchedOrder?.status,
        fetchedOrder?.id,
        id,
        update,
        navigate,
        create,
    ]);

    const saveDraft = useCallback(async () => {
        let draftId = id;
        const draft = pickBy(orderDraft, (v) => v !== '');
        if (!draftId) {
            const newOrderDraft = await create({
                order: { ...draft, status: 'draft' },
            }).unwrap();
            draftId = newOrderDraft.id.toString();
        } else {
            await update({
                id: +draftId,
                order: { ...draft, status: 'draft' },
            }).unwrap();
        }
        return draftId;
    }, [create, id, orderDraft, update]);

    const onSaveDraft = useCallback(async () => {
        await saveDraft();

        navigate('/applications/');
    }, [navigate, saveDraft]);

    const onSaveDraftAndGoNext = useCallback(async () => {
        const draftId = await saveDraft();

        navigate(
            '/applications/' +
                draftId +
                '/edit?' +
                createSearchParams({ step: `${currentStep + 1}` }).toString(),
        );
    }, [currentStep, navigate, saveDraft]);

    return (
        <>
            <HeadingActions title={id ? 'Заявка №' + id : 'Новая заявка'} />
            <div className="bg-white white mx-auto max-w-7xl px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                <FormProvider {...methods}>
                    <Form
                        inProgress={isUpdating || isCreating}
                        onSubmit={handleSubmit(onSubmit)}>
                        {CurrentStep ? (
                            <CurrentStep
                                isLoading={isFetching}
                                onNext={onSaveDraftAndGoNext}
                                onPrev={
                                    currentStep > 0 ? onPrevStep : undefined
                                }
                                onSave={onSaveDraft}
                            />
                        ) : null}
                    </Form>
                </FormProvider>
            </div>
        </>
    );
};
