import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { format } from 'date-fns';

import { Order, useCrmBanksListQuery } from 'app/generatedApi/crm';
import { ErrorMessage } from 'app/const/ErrorMessage';
import { Section } from 'shared/ui/Section';
import { SelectForm } from 'shared/ui/Select';
import { PatternValidation } from 'shared/config/PatternValidation';
import { InputForm } from 'shared/ui/Input';
import { Combobox } from 'shared/ui/Combobox';
import { validationDate } from 'shared/lib/validation/validationDate';

import { Step, StepProps } from './Step';
import { OwnersList } from './OwnersList';

export const CustomerStep: React.FC<StepProps & {}> = (props) => {
    const {
        control,
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext<Order>();
    const orderDraft = watch();

    const { currentData: banks, isLoading: isLoadingBank } =
        useCrmBanksListQuery();

    useEffect(() => {
        setValue(
            'encumbrancesType',
            orderDraft.encumbrances
                ? 'Ипотека в силу закона'
                : 'Не зарегистрировано',
        );
    }, [orderDraft.encumbrances, setValue]);
    return (
        <Step
            {...props}
            validateOnNext
            validate={[
                'customerFio',
                'passportSeries',
                'passportNumber',
                'passportIssueDate',
                'passportIssuer',
                'passportDepartmentCode',
                'birthday',
                'registrationAddress',
                'email',
                'phone',
                'encumbrances',
                'encumbrancesType',
                'bank',
                'owners'
            ]}>
            <Section
                title="Данные Заказчика"
                isLoading={props.isLoading || isLoadingBank}>
                <InputForm
                    formKey={'customerFio'}
                    label={'Заказчик'}
                    register={register}
                    error={errors.customerFio}
                    size={'xl'}
                    required={true}
                    attrs={{ ...register('customerFio', { required: true }) }}
                />
                <InputForm
                    formKey={'passportSeries'}
                    label={'Серия паспорта'}
                    register={register}
                    error={errors.passportSeries}
                    size={'s'}
                    mask="9999"
                    required={true}
                    attrs={{
                        ...register('passportSeries', {
                            required: true,
                        }),
                    }}
                />
                <InputForm
                    formKey={'passportNumber'}
                    label={'Номер паспорта'}
                    register={register}
                    error={errors.passportNumber}
                    size={'l'}
                    mask="999999"
                    required={true}
                    attrs={{
                        ...register('passportNumber', {
                            required: true,
                        }),
                    }}
                />

                <InputForm
                    formKey={'passportIssuer'}
                    label={'Кем выдан'}
                    register={register}
                    error={errors.passportIssuer}
                    size={'xl'}
                    required={true}
                    attrs={{
                        ...register('passportIssuer', { required: true }),
                    }}
                />
                <InputForm
                    formKey={'passportIssueDate'}
                    label={'Дата выдачи'}
                    register={register}
                    type="date"
                    error={errors.passportIssueDate}
                    required={true}
                    attrs={{
                        ...register('passportIssueDate', {
                            required: true,
                            validate: (value) => {
                                return validationDate(new Date(), value);
                            },
                        }),
                        max: format(new Date(), 'yyyy-MM-dd'),
                    }}
                />
                <InputForm
                    formKey={'passportDepartmentCode'}
                    label={'Код подразделения'}
                    register={register}
                    error={errors.passportDepartmentCode}
                    mask="999-999"
                    required={true}
                    attrs={{
                        ...register('passportDepartmentCode', {
                            required: true,
                        }),
                    }}
                />
                <InputForm
                    formKey={'birthday'}
                    label={'Дата рождения'}
                    register={register}
                    type="date"
                    error={errors.birthday}
                    required={true}
                    attrs={{
                        ...register('birthday', {
                            required: true,
                            validate: (value) => {
                                return validationDate(new Date(), value);
                            },
                        }),
                        max: format(new Date(), 'yyyy-MM-dd'),
                    }}
                />

                <InputForm
                    formKey={'registrationAddress'}
                    label={'Адрес регистрации'}
                    register={register}
                    error={errors.registrationAddress}
                    size={'xl'}
                    required={true}
                    attrs={{
                        ...register('registrationAddress', { required: true }),
                    }}
                />
                <InputForm
                    formKey={'email'}
                    label={'Электронная почта'}
                    register={register}
                    type="email"
                    error={errors.email}
                    required={true}
                    attrs={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: PatternValidation['email'],
                                message: ErrorMessage.email,
                            },
                        }),
                    }}
                />
                <InputForm
                    formKey={'phone'}
                    label={'Номер телефона'}
                    register={register}
                    error={errors.phone}
                    mask="+7 (999) 999-99-99"
                    required={true}
                    attrs={{
                        ...register('phone', {
                            required: true,
                        }),
                    }}
                />
                <OwnersList />
                {/* <Textarea
                    formKey={'owner'}
                    label={'Правообладатель'}
                    register={register}
                    error={errors.owner}
                    size={'xl'}
                    required={true}
                    attrs={{ ...register('owner', { required: true }) }}
                />
                <Field formKey={'ownershipType'} label={'Вид права'}>
                    <Controller
                        control={control}
                        name={'ownershipType'}
                        render={({ field }) => (
                            <MultiCombobox
                                {...field}
                                values={'Право собственности, Право совместной собственности, Право долевой собственности, Право требования, Другое'.split(
                                    ', ',
                                )}
                                value={field.value ? String(field.value) : ''}
                            />
                        )}
                    />
                </Field>
                <InputForm
                    formKey={'gotOwnershipDate'}
                    label={'Дата получения прав'}
                    register={register}
                    type="date"
                    error={errors.gotOwnershipDate}
                    required={true}
                    attrs={{
                        ...register('gotOwnershipDate', {
                            required: true,
                            validate: (value) => {
                                return validationDate(new Date(), value);
                            },
                        }),
                        max: format(new Date(), 'yyyy-MM-dd'),
                    }}
                /> */}
                <SelectForm
                    formKey={'encumbrances'}
                    label={'Наличие обременений'}
                    register={register}
                    error={errors.encumbrances}
                    options={[
                        { value: 'false', name: 'Нет' },
                        { value: 'true', name: 'Есть' },
                    ]}
                    emptyValue={'Укажите наличие обременений'}
                    attrs={{
                        ...register('encumbrances', {
                            setValueAs: (v) => v === 'true',
                        }),
                    }}
                />
                <Controller
                    control={control}
                    name={'encumbrancesType'}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Combobox
                            {...field}
                            label="Характер обременений"
                            className="col-span-3"
                            error={errors.encumbrancesType}
                            required={true}
                            isCustomOption
                            isLoading={props.isLoading || isLoadingBank}
                            options={[
                                'Не зарегистрировано',
                                'Ипотека в силу закона',
                            ]}
                        />
                    )}
                />
                <SelectForm
                    formKey={'bank'}
                    label={'Банк'}
                    register={register}
                    error={errors.bank}
                    emptyValue={'Выберите банк'}
                    options={
                        banks?.map((b) => ({
                            name: b.name,
                            value: b.id.toString(),
                        })) ?? []
                    }
                    required={true}
                    attrs={{ ...register('bank', { required: true }) }}
                />
            </Section>
        </Step>
    );
};
