import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { forOwn } from 'lodash';
import { useEffect } from 'react';
import { FieldError } from 'react-hook-form';

export function useHandleErrors<T>(
    error: FetchBaseQueryError | SerializedError | undefined,
    setError: (
        name: keyof T,
        error: FieldError,
        options?: { shouldFocus: boolean },
    ) => void,
    isFocus = false,
) {
    useEffect(() => {
        if (error && 'status' in error && error.status === 400) {
            'data' in error
                ? (error.data as Record<keyof T, string[]>)
                : ({} as Record<keyof T, string[]>);

            forOwn(error.data, (v, k) =>
                setError(k as keyof T, { type: 'custom', message: v[0] }, {shouldFocus: isFocus}),
            );
        }
    }, [error, isFocus, setError]);
}
