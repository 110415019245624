import React, {
    Children,
    cloneElement,
    FC,
    ReactElement,
    ReactNode,
} from 'react';

interface AddPropIsLoadingProps {
    isLoading: boolean;
    children: ReactNode;
}

export const AddPropIsLoading: FC<AddPropIsLoadingProps> = ({
    children,
    isLoading,
}) => {
    return (
        <>
            {Children.map(
                children,
                (child) =>
                    child &&
                    typeof (child as ReactElement).type !== 'string' &&
                    React.isValidElement(child) ?
                    cloneElement(child as ReactElement, { isLoading }) : child
            )}
        </>
    );
};
