import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    OrderRead,
    useCrmOrdersCancelCreateMutation,
    useCrmOrdersStartCreateMutation,
} from 'app/generatedApi/crm';
import { Timer } from 'features/timer';
import { handleError } from 'shared/lib/handleError';
import { Button, ButtonSize, ButtonTheme } from 'shared/ui/Button/Button';
import { DialogApp, DialogSize } from 'shared/ui/DialogApp/DialogApp';
import { Textarea } from 'shared/ui/Textarea/Textarea';

interface CurrentOrderProps {
    order: OrderRead;
}

const CurrentOrder: FC<CurrentOrderProps> = ({ order }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const navigate = useNavigate();

    const [update, { error }] = useCrmOrdersStartCreateMutation();
    const [refuse, { isLoading: loadingRefuse }] =
        useCrmOrdersCancelCreateMutation();

    const openModalRefuse = useCallback(() => {
        setIsOpenModal(true);
    }, []);

    const handleRefuseOrder = async () => {
        await refuse({ id: order.id, order: {} }).unwrap();
        reset();
        setIsOpenModal(false);
    };

    const handelTakeOrder = async () => {
        try {
            await update({ id: order.id, order: {} });
            navigate(`/appraiser/order/${order.id}`);
        } catch (error) {}
    };

    if (error) {
        return (
            <div className="overflow-hidden shadow rounded-lg bg-white px-4 py-5 sm:p-6 ">
                <div className="flex gap-4 items-center text-danger">
                    {handleError(error)}
                </div>
            </div>
        );
    }

    return (
        <div>
            {order.id ? (
                <>
                    <div className="overflow-hidden shadow rounded-lg bg-white px-4 py-5 sm:p-6 ">
                        <div className="flex gap-4 items-center">
                            <h2 className="text-2xl">
                                {`Заявка № ${order.id} `}
                                {order.orderStartDate &&
                                    format(order.orderStartDate, 'dd.MM.yyyy', {
                                        locale: ru,
                                    })}
                            </h2>
                            {order?.orderToStartWorkDate && (
                                <Timer
                                    initDate={
                                        new Date(order.orderToStartWorkDate)
                                    }
                                    onCompleted={() => {}}
                                />
                            )}
                        </div>
                        <div>
                            <span className="text-base block">
                                {order?.roomsCount &&
                                    `${order?.roomsCount}-комнатная квартира`}
                            </span>
                            <span className="text-base block">
                                {order?.address}
                            </span>
                            <span className="text-base block">
                                5 300 000 руб. (тестовая цена)
                            </span>
                        </div>
                    </div>
                    <div className="flex gap-4 pt-4 flex-col md:flex-row">
                        <Button
                            theme={ButtonTheme.SECONDARY}
                            size={ButtonSize.LG}
                            className="w-full"
                            onClick={openModalRefuse}>
                            Отклонить
                        </Button>
                        <Button
                            onClick={() => handelTakeOrder()}
                            size={ButtonSize.LG}
                            className="w-full">
                            Взять в работу
                        </Button>
                    </div>
                    <DialogApp
                        open={isOpenModal}
                        setOpen={setIsOpenModal}
                        title="Отклонить заявку"
                        size={DialogSize.MD}
                        actionSuccess={
                            <Button
                                type="submit"
                                loading={loadingRefuse}
                                onClick={handleSubmit(handleRefuseOrder)}>
                                Отправить
                            </Button>
                        }>
                        <form onSubmit={handleSubmit(handleRefuseOrder)}>
                            <Textarea
                                register={register}
                                label="Причина отказа"
                                formKey="comment"
                            />
                        </form>
                    </DialogApp>
                </>
            ) : (
                <div className="h-full flex items-center justify-center overflow-hidden shadow text-xl rounded-lg bg-white px-4 py-5 sm:p-6">
                    Новых заявок нет
                </div>
            )}
        </div>
    );
};

export default CurrentOrder;
