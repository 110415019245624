/* eslint-disable @conarti/feature-sliced/layers-slices */
import { formatDistance, formatDate } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC, useState, useMemo, ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { StatusD4EEnum, useCrmOrdersListQuery } from 'app/generatedApi/crm';
import { Pagination } from 'features/pagination';
import { SearchInput } from 'features/search';
import { LabelOrderStatus, OrderStatus } from 'entities/appraisers';
import { handleError } from 'shared/lib/handleError';
import { useDebounce } from 'shared/lib/react/useDebounce';
import { ErrorMessage } from 'shared/ui/ErrorMessage/ErrorMessage';
import { Table } from 'shared/ui/Table';
import { OrderStatusLabels } from 'shared/config/OrderStatusLabels';
import { HeadingTabs } from 'widgets/heading';

interface HistoryOrderProps {
    pageSize?: number;
}

export const HistoryOrder: FC<HistoryOrderProps> = ({ pageSize = 20 }) => {
    const [currentFilterStatus, setCurrentFilterStatus] =
        useState<OrderStatus>('new');
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 250);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();

    const {
        currentData: applications,
        isLoading,
        isFetching,
        error,
    } = useCrmOrdersListQuery({
        status: currentFilterStatus as StatusD4EEnum,
        query: debouncedQuery,
        pageSize,
        page,
    });

    const applicationsWithCustomerName = useMemo(
        () =>
            applications?.results?.map((a) => ({
                ...a,
                orderStartDate: formatDate(
                    a.orderStartDate || a.created,
                    'dd.MM.yyyy',
                ),
                client: a.customerFio,
                customerName: a.customerFio,
                orderStatus: a.status && OrderStatusLabels[a.status],
                construction: `${a.roomsCount}-к квартира, ${a.address}`,
                timeWork:
                    a.status === 'new' && a.orderToStartWorkDate
                        ? `Осталось ${formatDistance(
                              new Date(a.orderToStartWorkDate),
                              new Date(),
                              {
                                  locale: ru,
                              },
                          )}`
                        : a.orderCompleteDate
                          ? `Выполнен за ${formatDistance(
                                new Date(a.orderCompleteDate),
                                new Date(),
                                {
                                    locale: ru,
                                },
                            )}`
                          : '-',
            })) ?? [],
        [applications?.results],
    );

    const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        setCurrentFilterStatus('');
    }, []);

    return (
        <div className="mt-20">
            {error ? (
                <ErrorMessage message={handleError(error)} />
            ) : (
                <>
                    <div className="pt-4 bg-white shadow rounded-lg overflow-hidden">
                        <div className="px-4">
                            <HeadingTabs
                                namePage="История заказов"
                                tabs={Object.entries(LabelOrderStatus).map(
                                    ([status, name]) => ({
                                        name,
                                        value: status,
                                    }),
                                )}
                                currentTab={currentFilterStatus}
                                onChangeTab={setCurrentFilterStatus}
                            />
                            <div className="mt-6">
                                <SearchInput onChange={onSearch} />
                            </div>
                        </div>

                        {!isLoading && applications?.count === 0 ? (
                            <div className="px-4 py-12">
                                <div className="text-center">
                                    <h3 className="mt-2 text-xl font-semibold text-gray-900">
                                        Ничего не найдено
                                    </h3>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-y-auto mt-6">
                                    <Table
                                        rows={applicationsWithCustomerName}
                                        columns={[
                                            { key: 'id', className: 'w-1/12' },
                                            {
                                                key: 'orderStartDate',
                                                className: 'w-1/12',
                                            },
                                            {
                                                key: 'client',
                                                className: 'w-1/12 text-nowrap',
                                            },
                                            { key: 'construction' },
                                            {
                                                key: 'orderStatus',
                                                className: 'w-1/12',
                                            },
                                            {
                                                key: 'timeWork',
                                                className: 'w-1/12',
                                            },
                                            {
                                                key: 'customerName',
                                                className: 'w-1/12',
                                            },
                                        ]}
                                        columnsName={[
                                            'Номер',
                                            'Дата',
                                            'Заказчик',
                                            'Объект',
                                            'Статус',
                                            'Время',
                                            'Исполнитель',
                                        ]}
                                        onClick={(application) =>
                                            navigate(
                                                '/applications/' +
                                                    application.id,
                                            )
                                        }
                                        isLoading={isLoading || isFetching}
                                    />
                                </div>
                                {applications?.count && (
                                    <Pagination
                                        onPageChange={setPage}
                                        totalItems={applications?.count}
                                        currentPage={page}
                                        itemsPerPage={pageSize}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
