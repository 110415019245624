import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import React, { ReactNode, useMemo } from 'react';

import { classnames } from '../../../../lib/classnames';
import SkeletonTable from '../SkeletonTable/SkeletonTable';

import cls from '../../styles/Table.module.css';

export type Sort<K> = {
    key: K;
    direction: boolean | null;
};

export enum TableTheme {
    DEFAULT = 'default',
    SIMPLE = 'simple',
}

export type TableProps<T extends any> = {
    rows: Array<T>;
    columns: Array<{ key: keyof T; className?: string }>;
    columnsName: Array<string>;
    highlights?: Array<keyof T>;
    sort?: Array<keyof T>;
    sorted?: Array<Sort<keyof T>>;
    onSort?: (key: keyof T, direction: boolean | null) => void;
    children?: (t: T) => ReactNode;
    theme?: TableTheme;
    onClick?: (t: T) => void;
    isLoading?: boolean;
};

export const Table = <T extends unknown>({
    rows,
    columns,
    columnsName,
    sorted,
    sort,
    onSort,
    highlights,
    children,
    theme = TableTheme.DEFAULT,
    onClick,
    isLoading,
}: TableProps<T>) => {
    const directedColumnsWithHandlers = useMemo(() => {
        return columns.map((c, i) => {
            const isSortable = sort?.includes(c.key);
            const columnSort = sorted?.find((s) => s.key === c.key);
            const onClick = () => {
                const newDirection =
                    columnSort?.direction == null
                        ? true
                        : columnSort.direction
                          ? false
                          : null;

                onSort && onSort(c.key, newDirection);
            };
            return (
                <th
                    key={columnsName[i]}
                    scope="col"
                    className={`text-left text-sm font-semibold text-gray-900 ${String(c.className)}`}>
                    <div
                        onClick={isSortable ? onClick : void 0}
                        className="group inline-flex">
                        {columnsName[i]}
                        {isSortable ? (
                            <span
                                className={classnames(cls.sort, {
                                    'bg-gray-200 text-gray-900 group-hover:bg-gray-300':
                                        columnSort != null,
                                    'invisible text-gray-400 group-hover:visible group-focus:visible':
                                        columnSort == null,
                                })}>
                                {columnSort?.direction ? (
                                    <ChevronDownIcon />
                                ) : (
                                    <ChevronUpIcon />
                                )}
                            </span>
                        ) : null}
                    </div>
                </th>
            );
        });
    }, [columns, columnsName, onSort, sort, sorted]);

    if (isLoading)
        return (
            <SkeletonTable
                theme={theme}
                countColumn={columns.length}
                countRow={10}
            />
        );
    return (
        <table className={`min-w-full ${cls[theme]}`}>
            <thead>
                <tr>
                    {directedColumnsWithHandlers}
                    {children && <th></th>}
                </tr>
            </thead>
            <tbody>
                {rows.map((el, index) => (
                    <tr
                        key={`${index}_${columns.map((c) => el[c.key]).join('_')}`}
                        onClick={() => onClick && onClick(el)}
                        className={`${onClick && 'cursor-pointer hover:bg-gray-50'} transition-colors`}>
                        {columns.map((c) => (
                            <td
                                key={`${c.key as string}_${el[c.key]}`}
                                className={classnames(
                                    'text-sm text-gray-900 w-52 overflow-hidden',
                                    String(c.className),
                                    {
                                        'sm:pl-0':
                                            highlights?.includes(c.key) ??
                                            false,
                                    },
                                )}>
                                <>{el[c.key]}</>
                            </td>
                        ))}
                        {children && (
                            <td className="relative whitespace-nowrap text-sm">
                                {children(el)}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export class Action {}
