import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/solid';
import React, { FC, ReactNode, useRef, useState } from 'react';

import { classnames } from '../../lib/classnames';
import { Button, ButtonTheme } from '../../ui/Button/Button';

export type Step = { name: string; content: ReactNode };
interface StepperAppProps {
    steps?: Step[];
    actionInFinish?: () => void;
    finishButton?: ReactNode;
    footerButton?: ReactNode;
    textButtonNext?: string;
    textButtonFinish?: string;
    className?: string;
}

export const StepperApp: FC<StepperAppProps> = ({
    steps = [],
    actionInFinish = () => {},
    finishButton,
    footerButton,
    textButtonNext = 'Далее',
    textButtonFinish = 'Завершить',
    className = '',
}) => {
    const progressRef = useRef<HTMLDivElement>(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const handleNext = () => setActiveStep((cur) => cur + 1);
    const handlePrev = () => setActiveStep((cur) => cur - 1);
    const handleFinish = async () => {
        try {
            setIsLoading(true);
            await actionInFinish();

            progressRef.current?.addEventListener('click', (event) =>
                event.stopPropagation(),
            );
            setActiveStep(steps.length);
        } catch (e) {
            alert(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classnames('w-full', className)}>
            <nav aria-label="Progress" ref={progressRef}>
                <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 lg:flex lg:divide-y-0">
                    {steps.map((step, stepIdx) => (
                        <li
                            key={stepIdx}
                            className="relative lg:flex lg:flex-1">
                            {activeStep > stepIdx ? (
                                <>
                                    <button
                                        onClick={() => setActiveStep(stepIdx)}
                                        className="group flex w-full items-center transition-colors">
                                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800 transition-colors">
                                                <CheckIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            <span className="ml-4 text-sm font-medium text-gray-900">
                                                {step.name}
                                            </span>
                                        </span>
                                    </button>
                                </>
                            ) : activeStep === stepIdx ? (
                                <>
                                    <div
                                        className="flex items-center px-6 py-4 text-sm font-medium"
                                        aria-current="step">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                            <span className="text-indigo-600">
                                                {stepIdx + 1}
                                            </span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-indigo-600">
                                            {step.name}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <button
                                        onClick={() => setActiveStep(stepIdx)}
                                        className="group flex items-center">
                                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400 transition-colors">
                                                <span className="text-gray-500 group-hover:text-gray-900">
                                                    {stepIdx + 1}
                                                </span>
                                            </span>
                                            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 transition-colors">
                                                {step.name}
                                            </span>
                                        </span>
                                    </button>
                                </>
                            )}
                            {stepIdx !== steps.length - 1 && (
                                <div
                                    className="absolute right-0 top-0 hidden h-full w-5 lg:block"
                                    aria-hidden="true">
                                    <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none">
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className="mt-8">
                {steps[activeStep]?.content
                    ? steps[activeStep]?.content
                    : steps[activeStep - 1]?.content}

                <div className="mt-4 w-2/4 ml-auto flex gap-x-6 justify-end">
                    <>
                        {!!activeStep && activeStep < steps.length && (
                            <Button
                                icon
                                theme={ButtonTheme.SECONDARY}
                                onClick={handlePrev}>
                                <ChevronLeftIcon className="h-5 w-5" />
                                Назад
                            </Button>
                        )}
                        {footerButton}
                        {activeStep === steps.length - 1 && (
                            <Button
                                onClick={handleFinish}
                                loading={isLoading}
                                theme={ButtonTheme.SUCCESS}>
                                {textButtonFinish}
                            </Button>
                        )}
                        {activeStep === steps.length &&
                            finishButton &&
                            finishButton}
                        {activeStep < steps.length - 1 && (
                            <Button
                                icon
                                className={
                                    activeStep === steps.length - 1
                                        ? 'hidden'
                                        : ''
                                }
                                onClick={handleNext}>
                                {textButtonNext}
                                <ChevronRightIcon className="h-5 w-5" />
                            </Button>
                        )}
                    </>
                </div>
            </div>
        </div>
    );
};
