import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { ErrorStatusLabels } from '../config/ErrorStatusLabels';

export function handleError(
    error:
        | FetchBaseQueryError
        | SerializedError
        | undefined,
): string {
    let errorMessage;
    if (error) {
        if ('status' in error) {
            errorMessage = ErrorStatusLabels[error.status] ?? '';
            if (
                typeof error.data === 'object' &&
                error.data !== null &&
                'errorMessage' in error.data
            ) {
                   errorMessage += '\n' + error.data.errorMessage
            }
        } else if ('errorMessage' in error) {
            errorMessage = error.errorMessage as string;
        } else {
            errorMessage = error.message;
        }
    }
    return errorMessage || '';
}
