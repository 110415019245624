import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface useSaveUrlParamsProps {
    urlParams: {
        name: string;
        value: any;
        setValue?: Dispatch<SetStateAction<any>>;
    }[];
}

export const useSaveUrlParams = ({ urlParams }: useSaveUrlParamsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setSearchParams(
            urlParams.reduce(
                (obj, { name, value }) =>
                    value &&
                    Object.assign(
                        obj,
                        name === 'page' && value === 1 ? {} : { [name]: value },
                    ),
                {},
            ),
        );
    }, [setSearchParams, urlParams]);

    useEffect(() => {
        urlParams.forEach(({ name, setValue }) => {
            if (searchParams.get(name)) {
                setValue?.(searchParams.get(name));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
