import { LayoutTypeEnum, SummerRoomsType34DEnum } from 'app/generatedApi/crm';
import { OrderStatusLabels } from 'shared/config/OrderStatusLabels';
import { OrderStatus } from './types';

export const LabelOrderStatus: Partial<Record<OrderStatus, string>> = {
    draft: OrderStatusLabels['draft'],
    new: OrderStatusLabels['new'],
    processing: OrderStatusLabels['processing'],
    completed: OrderStatusLabels['completed'],
    archived: OrderStatusLabels['archived'],
    declined: OrderStatusLabels['declined'],
    '': 'Все',
};

export const LabelSummerRoomsType: Record<SummerRoomsType34DEnum, string> = {
    none: 'Нет',
    loggia: 'Лоджия',
    balcony: 'Балкон',
    two_balcony: 'Два балкона',
    two_loggia: 'Две лоджии',
    loggia_and_balcony: 'Лоджия и балкон'
}
export const LabelLayoutTypeLabel: Record<LayoutTypeEnum, string> ={
    free: 'Фиксированная',
    fixed: 'Свободная'
}
