import { FC } from 'react';

import { classnames } from '../../../../lib/classnames';

import { ArrowType, ArrowTypeEnum } from './ArrowType';
import cls from './SliderArrow.module.css';

interface SliderArrowProps {
    type?: ArrowTypeEnum;
    onClick?: () => {};
}

export const SliderArrow: FC<SliderArrowProps> = ({
    type = ArrowTypeEnum.NEXT,
    onClick,
}) => {
    return (
        <button className={classnames(cls.arrow, cls['arrow'+type])}
                onClick={onClick}>
            {ArrowType[type]}
        </button>
    );
};
