import { PixelCrop } from 'react-image-crop';

export async function getCroppedImg(
    image: HTMLImageElement | null,
    canvasPreview: HTMLCanvasElement | null,
    completedCrop?: PixelCrop,
) {
    if (!image || !canvasPreview || !completedCrop) {
        throw new Error('Crop canvas does not exist');
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
    );

    const ctx = offscreen.getContext('2d');
    if (!ctx) {
        throw new Error('No 2d context');
    }

    ctx.drawImage(
        canvasPreview,
        0,
        0,
        canvasPreview.width,
        canvasPreview.height,
        0,
        0,
        offscreen.width,
        offscreen.height,
    );

    return offscreen.convertToBlob({
        type: 'image/png',
    });
}
