import { generatedApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        crmOrdersInspectionCreate: build.mutation<
            CrmOrdersInspectionCreateApiResponse,
            CrmOrdersInspectionCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/inspection/`,
                method: 'POST',
                body: queryArg.retrieveOrder,
            }),
        }),
        inspectionsList: build.query<
            InspectionsListApiResponse,
            InspectionsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/`,
                params: {
                    ordering: queryArg.ordering,
                    page: queryArg.page,
                    page_size: queryArg.pageSize,
                    status: queryArg.status,
                },
            }),
        }),
        inspectionsCreate: build.mutation<
            InspectionsCreateApiResponse,
            InspectionsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/`,
                method: 'POST',
                body: queryArg.inspection,
            }),
        }),
        inspectionsRetrieve: build.query<
            InspectionsRetrieveApiResponse,
            InspectionsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/${queryArg.id}/`,
            }),
        }),
        inspectionsUpdate: build.mutation<
            InspectionsUpdateApiResponse,
            InspectionsUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.inspection,
            }),
        }),
        inspectionsPartialUpdate: build.mutation<
            InspectionsPartialUpdateApiResponse,
            InspectionsPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedInspection,
            }),
        }),
        inspectionsDestroy: build.mutation<
            InspectionsDestroyApiResponse,
            InspectionsDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        inspectionsPhotoGroupsList: build.query<
            InspectionsPhotoGroupsListApiResponse,
            InspectionsPhotoGroupsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photo-groups/`,
                params: { page: queryArg.page, page_size: queryArg.pageSize },
            }),
        }),
        inspectionsPhotoGroupsCreate: build.mutation<
            InspectionsPhotoGroupsCreateApiResponse,
            InspectionsPhotoGroupsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photo-groups/`,
                method: 'POST',
                body: queryArg.photoGroup,
            }),
        }),
        inspectionsPhotoGroupsRetrieve: build.query<
            InspectionsPhotoGroupsRetrieveApiResponse,
            InspectionsPhotoGroupsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photo-groups/${queryArg.id}/`,
            }),
        }),
        inspectionsPhotoGroupsUpdate: build.mutation<
            InspectionsPhotoGroupsUpdateApiResponse,
            InspectionsPhotoGroupsUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photo-groups/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.photoGroup,
            }),
        }),
        inspectionsPhotoGroupsPartialUpdate: build.mutation<
            InspectionsPhotoGroupsPartialUpdateApiResponse,
            InspectionsPhotoGroupsPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photo-groups/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedPhotoGroup,
            }),
        }),
        inspectionsPhotoGroupsDestroy: build.mutation<
            InspectionsPhotoGroupsDestroyApiResponse,
            InspectionsPhotoGroupsDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photo-groups/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        inspectionsPhotosCreate: build.mutation<
            InspectionsPhotosCreateApiResponse,
            InspectionsPhotosCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photos/`,
                method: 'POST',
                body: queryArg.photo,
            }),
        }),
        inspectionsPhotosUpdate: build.mutation<
            InspectionsPhotosUpdateApiResponse,
            InspectionsPhotosUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photos/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.photo,
            }),
        }),
        inspectionsPhotosPartialUpdate: build.mutation<
            InspectionsPhotosPartialUpdateApiResponse,
            InspectionsPhotosPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photos/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedPhoto,
            }),
        }),
        inspectionsPhotosDestroy: build.mutation<
            InspectionsPhotosDestroyApiResponse,
            InspectionsPhotosDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/inspections/photos/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type CrmOrdersInspectionCreateApiResponse =
    /** status 200  */ RetrieveOrderRead;
export type CrmOrdersInspectionCreateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    retrieveOrder: RetrieveOrder;
};
export type InspectionsListApiResponse =
    /** status 200  */ PaginatedInspectionListRead;
export type InspectionsListApiArg = {
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
    /** * `created` - Created
     * `completed` - Done */
    status?: 'completed' | 'created';
};
export type InspectionsCreateApiResponse = /** status 201  */ InspectionRead;
export type InspectionsCreateApiArg = {
    inspection: Inspection;
};
export type InspectionsRetrieveApiResponse = /** status 200  */ InspectionRead;
export type InspectionsRetrieveApiArg = {
    /** A unique integer value identifying this Осмотр. */
    id: number;
};
export type InspectionsUpdateApiResponse = /** status 200  */ InspectionRead;
export type InspectionsUpdateApiArg = {
    /** A unique integer value identifying this Осмотр. */
    id: number;
    inspection: Inspection;
};
export type InspectionsPartialUpdateApiResponse =
    /** status 200  */ InspectionRead;
export type InspectionsPartialUpdateApiArg = {
    /** A unique integer value identifying this Осмотр. */
    id: number;
    patchedInspection: PatchedInspection;
};
export type InspectionsDestroyApiResponse = unknown;
export type InspectionsDestroyApiArg = {
    /** A unique integer value identifying this Осмотр. */
    id: number;
};
export type InspectionsPhotoGroupsListApiResponse =
    /** status 200  */ PaginatedPhotoGroupListRead;
export type InspectionsPhotoGroupsListApiArg = {
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
};
export type InspectionsPhotoGroupsCreateApiResponse =
    /** status 201  */ PhotoGroupRead;
export type InspectionsPhotoGroupsCreateApiArg = {
    photoGroup: PhotoGroup;
};
export type InspectionsPhotoGroupsRetrieveApiResponse =
    /** status 200  */ PhotoGroupRead;
export type InspectionsPhotoGroupsRetrieveApiArg = {
    /** A unique integer value identifying this photo group2. */
    id: number;
};
export type InspectionsPhotoGroupsUpdateApiResponse =
    /** status 200  */ PhotoGroupRead;
export type InspectionsPhotoGroupsUpdateApiArg = {
    /** A unique integer value identifying this photo group2. */
    id: number;
    photoGroup: PhotoGroup;
};
export type InspectionsPhotoGroupsPartialUpdateApiResponse =
    /** status 200  */ PhotoGroupRead;
export type InspectionsPhotoGroupsPartialUpdateApiArg = {
    /** A unique integer value identifying this photo group2. */
    id: number;
    patchedPhotoGroup: PatchedPhotoGroup;
};
export type InspectionsPhotoGroupsDestroyApiResponse = unknown;
export type InspectionsPhotoGroupsDestroyApiArg = {
    /** A unique integer value identifying this photo group2. */
    id: number;
};
export type InspectionsPhotosCreateApiResponse = /** status 201  */ PhotoRead;
export type InspectionsPhotosCreateApiArg = {
    photo: Photo;
};
export type InspectionsPhotosUpdateApiResponse = /** status 200  */ PhotoRead;
export type InspectionsPhotosUpdateApiArg = {
    /** A unique integer value identifying this photo. */
    id: number;
    photo: Photo;
};
export type InspectionsPhotosPartialUpdateApiResponse =
    /** status 200  */ PhotoRead;
export type InspectionsPhotosPartialUpdateApiArg = {
    /** A unique integer value identifying this photo. */
    id: number;
    patchedPhoto: PatchedPhoto;
};
export type InspectionsPhotosDestroyApiResponse = unknown;
export type InspectionsPhotosDestroyApiArg = {
    /** A unique integer value identifying this photo. */
    id: number;
};
export type StatusD4EEnum =
    | 'draft'
    | 'new'
    | 'inspection_request'
    | 'inspection_waiting'
    | 'inspection_done'
    | 'selection_waiting'
    | 'selection_done'
    | 'selection_error'
    | 'report_waiting'
    | 'report_done'
    | 'report_error'
    | 'queued'
    | 'processing'
    | 'completed'
    | 'declined'
    | 'archived';
export type OoTypeEnum = 'residential_complex' | 'manual';
export type SummerRoomsType34DEnum =
    | 'none'
    | 'loggia'
    | 'balcony'
    | 'two_balcony'
    | 'two_loggia'
    | 'loggia_and_balcony';
export type LayoutTypeEnum = 'free' | 'fixed';
export type BlankEnum = '';
export type NullEnum = null;
export type OrderPurposeEnum = 'pay_mortgage' | 'get_mortgage' | 'other';
export type RetrieveOrder = {
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
};
export type ResidentialComplex = {
    modified?: string | null;
    name: string;
    subject?: string;
    locality?: string;
    polygon: string;
    additionalFilters?: any | null;
    resaleOnly?: boolean;
    city?: number | null;
};
export type ResidentialComplexRead = {
    id: number;
    created: string;
    modified?: string | null;
    name: string;
    subject?: string;
    locality?: string;
    polygon: string;
    additionalFilters?: any | null;
    resaleOnly?: boolean;
    city?: number | null;
};
export type WallTypeEnum =
    | 'unknown'
    | 'brick'
    | 'panel'
    | 'block'
    | 'monolith'
    | 'wooden'
    | 'monolith_brick';
export type SecurityEnum = 1 | 2 | 3 | 4;
export type TransportAvailabilityLevelEnum = 1 | 2 | 3;
export type ResidentialComplexHouse = {
    modified?: string | null;
    address?: string;
    kadastrNumber?: string;
    location?: string | null;
    builtYear: number;
    buildingWearPercentage: number;
    serialNumber?: string;
    foundationType: string;
    floorsCount: number;
    wallType?: WallTypeEnum;
    floorType: string;
    elevator?: boolean;
    garbageChute?: boolean;
    security?: SecurityEnum;
    coldWaterSupply?: string;
    hotWaterSupply?: string;
    sewageSystem?: string;
    electricalSupply?: string;
    gasSupply?: string;
    heatingSystem?: string;
    lowVoltageEquipment?: string;
    transportAvailabilityLevel?: TransportAvailabilityLevelEnum;
    kindergarten?: boolean | null;
    school?: boolean | null;
    clinic?: boolean | null;
    mall?: boolean | null;
    pond?: boolean | null;
    residentialComplex: number;
};
export type ResidentialComplexHouseRead = {
    id: number;
    created: string;
    modified?: string | null;
    address?: string;
    kadastrNumber?: string;
    location?: string | null;
    builtYear: number;
    buildingWearPercentage: number;
    serialNumber?: string;
    foundationType: string;
    floorsCount: number;
    wallType?: WallTypeEnum;
    floorType: string;
    elevator?: boolean;
    garbageChute?: boolean;
    security?: SecurityEnum;
    coldWaterSupply?: string;
    hotWaterSupply?: string;
    sewageSystem?: string;
    electricalSupply?: string;
    gasSupply?: string;
    heatingSystem?: string;
    lowVoltageEquipment?: string;
    transportAvailabilityLevel?: TransportAvailabilityLevelEnum;
    kindergarten?: boolean | null;
    school?: boolean | null;
    clinic?: boolean | null;
    mall?: boolean | null;
    pond?: boolean | null;
    residentialComplex: number;
};
export type RenovationTypeEnum = -1 | 0 | 1 | 2 | 3 | 4 | 5;
export type SummerRoomsType2E3Enum = -1 | 0 | 1 | 2;
export type ResidentialComplexApartment = {
    modified?: string | null;
    number: number;
    roomsCount: number;
    area: number;
    floor: number;
    renovationType?: RenovationTypeEnum;
    summerRoomsType?: SummerRoomsType2E3Enum;
    layoutType?: LayoutTypeEnum;
    livingArea?: number | null;
    kitchenArea?: number | null;
    fullArea?: number | null;
    summerRoomsArea?: number | null;
    roomsAreas?: number[];
    summerRooms?: boolean;
    utilityRoomsArea?: number | null;
    restroomCountAndType?: string;
    heightOfCeiling?: number | null;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    entryAndInteriorDoors?: string;
    overallCondition?: string;
    house: number;
    entrance?: number | null;
};
export type ResidentialComplexApartmentRead = {
    id: number;
    created: string;
    modified?: string | null;
    number: number;
    roomsCount: number;
    area: number;
    floor: number;
    renovationType?: RenovationTypeEnum;
    summerRoomsType?: SummerRoomsType2E3Enum;
    layoutType?: LayoutTypeEnum;
    livingArea?: number | null;
    kitchenArea?: number | null;
    fullArea?: number | null;
    summerRoomsArea?: number | null;
    roomsAreas?: number[];
    summerRooms?: boolean;
    utilityRoomsArea?: number | null;
    restroomCountAndType?: string;
    heightOfCeiling?: number | null;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    entryAndInteriorDoors?: string;
    overallCondition?: string;
    house: number;
    entrance?: number | null;
};
export type DocumentsGroup = {
    modified?: string | null;
    name: string;
};
export type Document = {
    modified?: string | null;
    file?: string | null;
    position: number;
    group?: number | null;
    newFile?: number | null;
};
export type DocumentRead = {
    id: number;
    created: string;
    modified?: string | null;
    file?: string | null;
    originalName: string;
    position: number;
    group?: number | null;
    newFile?: number | null;
};
export type DocumentsGroupRead = {
    id: number;
    documents: DocumentRead[];
    created: string;
    modified?: string | null;
    name: string;
};
export type Building = {
    id: number;
    regionId?: string | null;
    areaId?: string | null;
    cityId?: string | null;
    streetId?: string | null;
    shortnameRegion?: string | null;
    formalnameRegion?: string | null;
    shortnameArea?: string | null;
    formalnameArea?: string | null;
    shortnameCity?: string | null;
    formalnameCity?: string | null;
    shortnameStreet?: string | null;
    formalnameStreet?: string | null;
    houseNumber?: string | null;
    building?: string | null;
    block?: string | null;
    letter?: string | null;
    address?: string | null;
    houseguid?: string | null;
    managementOrganizationId?: number | null;
    builtYear?: number | null;
    exploitationStartYear?: number | null;
    projectType?: string | null;
    houseType?: string | null;
    isAlarm?: string | null;
    methodOfFormingOverhaulFund: string;
    floorCountMax?: number | null;
    floorCountMin?: number | null;
    entranceCount?: number | null;
    elevatorsCount?: number | null;
    energyEfficiency?: string | null;
    quartersCount?: number | null;
    livingQuartersCount?: number | null;
    unlivingQuartersCount?: number | null;
    areaTotal?: number | null;
    areaResidential?: number | null;
    areaNonResidential?: number | null;
    areaCommonProperty?: number | null;
    areaLand?: number | null;
    parkingSquare?: number | null;
    playground?: number | null;
    sportsground?: number | null;
    otherBeautification?: string | null;
    foundationType?: string | null;
    floorType?: string | null;
    wallMaterial?: string | null;
    basementArea?: string | null;
    chuteType?: string | null;
    chuteCount?: number | null;
    electricalType?: string | null;
    electricalEntriesCount?: number | null;
    heatingType?: string | null;
    hotWaterType?: string | null;
    coldWaterType?: string | null;
    sewerageType?: string | null;
    sewerageCesspoolsVolume?: number | null;
    gasType?: string | null;
    ventilationType?: string | null;
    firefightingType?: string | null;
    drainageType?: string | null;
    systemCity?: number | null;
    newCity?: number | null;
};
export type TypeCadEnum = 'default' | 'residential_complex';
export type NewRenovationTypeEnum =
    | 'unknown'
    | 'none'
    | 'preclean'
    | 'average'
    | 'good'
    | 'euro'
    | 'premium';
export type SummerRoomsEnum = -1 | 0 | 1 | 2;
export type HouseTypeEnum = -1 | 1 | 2;
export type Selection = {
    building: Building;
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    homogeneityRatio?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplexApartment?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type CorrectedAnalog = {
    sumOfPercentageCorrections: number;
    pricePerMeter?: number;
    correctedPricePerMeter?: number;
    bargainingCorrection?: number;
    salesTypeCorrection?: number;
    wallTypeCorrection?: number;
    floorCorrection?: number;
    areaCorrection?: number;
    summerRoomsCorrection?: number;
    renovationTypeCorrection?: number;
    weight?: number;
};
export type SourceIdEnum = 4;
export type WindowsViewEnum = -1 | 0 | 1;
export type Advertisement = {
    id: number;
    pricePerMeter: number;
    modified?: string | null;
    address?: string;
    sourceUrl: string;
    sourceId?: SourceIdEnum;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro?: string;
    kmDoMetro: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    windowsView?: WindowsViewEnum;
    summerRooms?: SummerRoomsEnum;
    heightOfCeiling?: number | null;
    buildingYear?: number | null;
    location?: string | null;
    originalData?: any;
    unpublished?: boolean;
    photoCount?: number;
    dadataData?: any | null;
    parsedAt?: string | null;
    screenshot?: string | null;
    screenshotTakenAt?: string | null;
    building?: number | null;
};
export type AdvertisementRead = {
    id: number;
    pricePerMeter: number;
    wallTypeLabel: string;
    renovationTypeLabel: string;
    summerRoomsLabel: string;
    windowsViewLabel: string;
    houseTypeLabel: string;
    created: string;
    modified?: string | null;
    address?: string;
    sourceUrl: string;
    sourceId?: SourceIdEnum;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro?: string;
    kmDoMetro: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    windowsView?: WindowsViewEnum;
    summerRooms?: SummerRoomsEnum;
    heightOfCeiling?: number | null;
    buildingYear?: number | null;
    location?: string | null;
    originalData?: any;
    unpublished?: boolean;
    photoCount?: number;
    dadataData?: any | null;
    parsedAt?: string | null;
    screenshot?: string | null;
    screenshotTakenAt?: string | null;
    building?: number | null;
};
export type CorrectedAnalogRead = {
    id: number;
    advertisement: AdvertisementRead;
    sumOfPercentageCorrections: number;
    pricePerMeterWithBargainingCorrection: string;
    pricePerMeter?: number;
    correctedPricePerMeter?: number;
    bargainingCorrection?: number;
    salesTypeCorrection?: number;
    wallTypeCorrection?: number;
    floorCorrection?: number;
    areaCorrection?: number;
    summerRoomsCorrection?: number;
    renovationTypeCorrection?: number;
    weight?: number;
};
export type SimpleReport = {};
export type SimpleReportRead = {
    id: number;
};
export type StatusA47Enum = 0 | 1 | 2 | 3;
export type SelectionRead = {
    id: number;
    building: Building;
    correctedAnalogs: CorrectedAnalogRead[];
    analogs: AdvertisementRead[];
    report: SimpleReportRead;
    priceInWords: string;
    liquidationPriceInWords: string;
    roominess: string;
    discountRatePercentage: string;
    lowLiquidityRiskCompensationPercentage: string;
    riskFreeRatePercentage: string;
    residentialComplex: ResidentialComplexRead;
    residentialComplexHouse: ResidentialComplexHouseRead;
    ratio: string;
    priceFormatted: string;
    liquidationPriceFormatted: string;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step: number;
    distance: number;
    areaThreshold: number;
    homogeneityRatio?: number;
    pricePerMeter: number;
    price: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplexApartment?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type Status456Enum =
    | 'created'
    | 'generating'
    | 'generated'
    | 'signed'
    | 'error';
export type Report = {
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type SelectionList = {
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type SelectionListRead = {
    id: number;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type ReportRead = {
    id: number;
    selection: SelectionListRead;
    created: string;
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    document: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type Bank = {
    name: string;
    shortName: string;
    image?: string | null;
    defaultEvaluator?: number | null;
};
export type BankRead = {
    id: number;
    name: string;
    shortName: string;
    image?: string | null;
    defaultEvaluator?: number | null;
};
export type InspectionStatusEnum = 'created' | 'completed';
export type WindowViewEnum = -1 | 0 | 1;
export type Inspection = {
    modified?: string | null;
    address?: string;
    customer?: string;
    status?: InspectionStatusEnum;
    entranceCondition?: string;
    roofCondition?: string;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    visibleFinishingDefects?: string;
    windowView?: WindowViewEnum;
    leaksOnCeiling?: boolean;
    kitchenStove?: string;
    plumbingCondition?: string;
    kitchenArrangement?: string;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    doors?: string;
    necessaryRenovations?: string;
    currentUsing?: string;
    complianceLayout?: string;
    unapprovedLayout?: string;
    layoutChanges?: string;
    order: number;
    assessmentObject?: number | null;
    appraiser?: number | null;
    request?: number | null;
};
export type PhotoGroup = {
    modified?: string | null;
    name: string;
    inspection: number;
    assessmentObject?: number | null;
};
export type Photo = {
    modified?: string | null;
    image?: string | null;
    position: number;
    photoGroup?: number | null;
    file?: number | null;
};
export type PhotoRead = {
    id: number;
    created: string;
    modified?: string | null;
    image?: string | null;
    position: number;
    photoGroup?: number | null;
    file?: number | null;
};
export type PhotoGroupRead = {
    id: number;
    photos: PhotoRead[];
    created: string;
    modified?: string | null;
    name: string;
    inspection: number;
    assessmentObject?: number | null;
};
export type InspectionRead = {
    id: number;
    photoGroups: PhotoGroupRead[];
    created: string;
    modified?: string | null;
    address?: string;
    customer?: string;
    status?: InspectionStatusEnum;
    entranceCondition?: string;
    roofCondition?: string;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    visibleFinishingDefects?: string;
    windowView?: WindowViewEnum;
    leaksOnCeiling?: boolean;
    kitchenStove?: string;
    plumbingCondition?: string;
    kitchenArrangement?: string;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    doors?: string;
    necessaryRenovations?: string;
    currentUsing?: string;
    complianceLayout?: string;
    unapprovedLayout?: string;
    layoutChanges?: string;
    order: number;
    assessmentObject?: number | null;
    appraiser?: number | null;
    request?: number | null;
};
export type Owner = {
    owner?: string;
    ownershipType?: string;
    gotOwnershipDate: string;
    order: number;
    assessmentObject?: number | null;
};
export type OwnerRead = {
    id: number;
    owner?: string;
    ownershipType?: string;
    gotOwnershipDate: string;
    order: number;
    assessmentObject?: number | null;
};
export type RetrieveOrderRead = {
    id: number;
    residentialComplex: ResidentialComplexRead;
    residentialComplexHouse: ResidentialComplexHouseRead;
    residentialComplexApartment: ResidentialComplexApartmentRead;
    legalDocuments: DocumentsGroupRead[];
    techDocuments: DocumentsGroupRead[];
    floorPlanBti: DocumentRead;
    selection: SelectionRead;
    report: ReportRead;
    bank: BankRead;
    inspection: InspectionRead;
    owners: OwnerRead[];
    created: string;
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
};
export type PaginatedInspectionList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Inspection[];
};
export type PaginatedInspectionListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: InspectionRead[];
};
export type PatchedInspection = {
    modified?: string | null;
    address?: string;
    customer?: string;
    status?: InspectionStatusEnum;
    entranceCondition?: string;
    roofCondition?: string;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    visibleFinishingDefects?: string;
    windowView?: WindowViewEnum;
    leaksOnCeiling?: boolean;
    kitchenStove?: string;
    plumbingCondition?: string;
    kitchenArrangement?: string;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    doors?: string;
    necessaryRenovations?: string;
    currentUsing?: string;
    complianceLayout?: string;
    unapprovedLayout?: string;
    layoutChanges?: string;
    order?: number;
    assessmentObject?: number | null;
    appraiser?: number | null;
    request?: number | null;
};
export type PatchedInspectionRead = {
    id?: number;
    photoGroups?: PhotoGroupRead[];
    created?: string;
    modified?: string | null;
    address?: string;
    customer?: string;
    status?: InspectionStatusEnum;
    entranceCondition?: string;
    roofCondition?: string;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    visibleFinishingDefects?: string;
    windowView?: WindowViewEnum;
    leaksOnCeiling?: boolean;
    kitchenStove?: string;
    plumbingCondition?: string;
    kitchenArrangement?: string;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    doors?: string;
    necessaryRenovations?: string;
    currentUsing?: string;
    complianceLayout?: string;
    unapprovedLayout?: string;
    layoutChanges?: string;
    order?: number;
    assessmentObject?: number | null;
    appraiser?: number | null;
    request?: number | null;
};
export type PaginatedPhotoGroupList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PhotoGroup[];
};
export type PaginatedPhotoGroupListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PhotoGroupRead[];
};
export type PatchedPhotoGroup = {
    modified?: string | null;
    name?: string;
    inspection?: number;
    assessmentObject?: number | null;
};
export type PatchedPhotoGroupRead = {
    id?: number;
    photos?: PhotoRead[];
    created?: string;
    modified?: string | null;
    name?: string;
    inspection?: number;
    assessmentObject?: number | null;
};
export type PatchedPhoto = {
    modified?: string | null;
    image?: string | null;
    position?: number;
    photoGroup?: number | null;
    file?: number | null;
};
export type PatchedPhotoRead = {
    id?: number;
    created?: string;
    modified?: string | null;
    image?: string | null;
    position?: number;
    photoGroup?: number | null;
    file?: number | null;
};
export const {
    useCrmOrdersInspectionCreateMutation,
    useInspectionsListQuery,
    useLazyInspectionsListQuery,
    useInspectionsCreateMutation,
    useInspectionsRetrieveQuery,
    useLazyInspectionsRetrieveQuery,
    useInspectionsUpdateMutation,
    useInspectionsPartialUpdateMutation,
    useInspectionsDestroyMutation,
    useInspectionsPhotoGroupsListQuery,
    useLazyInspectionsPhotoGroupsListQuery,
    useInspectionsPhotoGroupsCreateMutation,
    useInspectionsPhotoGroupsRetrieveQuery,
    useLazyInspectionsPhotoGroupsRetrieveQuery,
    useInspectionsPhotoGroupsUpdateMutation,
    useInspectionsPhotoGroupsPartialUpdateMutation,
    useInspectionsPhotoGroupsDestroyMutation,
    useInspectionsPhotosCreateMutation,
    useInspectionsPhotosUpdateMutation,
    useInspectionsPhotosPartialUpdateMutation,
    useInspectionsPhotosDestroyMutation,
} = injectedRtkApi;
