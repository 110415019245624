import React, { Children, FC, PropsWithChildren } from 'react';

interface SkeletonProps {
    title?: string;
}

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
    title,
    children,
}) => {
    return (
        <div className="px-4 sm:px-6 lg:px-8 animate-pulse">
            <div className={'mb-2'}>
                <div className="w-2/12 h-6 bg-gray-200 rounded" />
            </div>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <div className="w-4/12 h-9 bg-gray-200 rounded" />
                </div>
                <div className="flex gap-4 mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {Children.map(children, (child, index) => {
                        if (React.isValidElement(child)) {
                            return (
                                <div
                                    key={index}
                                    className="h-9 w-36 bg-gray-200 rounded "
                                />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
