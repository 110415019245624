import { RetrieveOrderRead } from 'app/generatedApi/crm';
import { OrderDataSign } from '../module/types';

export function prepareDataSign(order: RetrieveOrderRead): OrderDataSign {
    const result: OrderDataSign = {
        pdfFile: order?.report?.document,
        appraiserData: {
            signer: order?.report?.signer,
            appraiser: order?.report?.appraiser,
        },
    };

    return result;
}
