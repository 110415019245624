export type CreateNewReport = { selection: Number };

export enum QualityChoices {
    GOOD = 1,
    SATISFACTORY = 2,
    UNSATISFACTORY = 3,
}

export enum EcologicalSituationChoices {
    FAVOURABLE = 1,
    RELATIVELY_FAVOURABLE = 2,
    UNFAVORABLE = 3,
}

export enum SecurityChoices {
    INTERCOM = 1,
    CONCIERGE = 2,
    PROTECTED_TERRITORY = 3,
    ABSENT = 4,
}

export enum LayoutTypeChoices {
    FIXED = 1,
    FREE = 2,
}

export enum StatusTypeChoices {
    HIGH = 1,
    MEDIUM = 2,
    LOW = 3,
}

export interface ReportForm {
    numberOfRooms: string;
    totalArea: string;
    livingArea: string;
    kitchenArea: string;
    encumbrances: string;
    encumbrancesType: string;
    kadastrNumber: string;
    subjectOfFederation: string;
    area: string;
    locality: string;
    address: string;
    passportNumber: string;
    registrationAddress: string;
    contractNumber: string;
    inspectionDate: string | null;
    selectionDate: string | null;
    reportDate: string;
    bank: string;

    cityDistrict: string;
    nearestMetroStation: string;
    distanceToMetroStation: string;

    transportationAvailability: StatusTypeChoices;
    parks: boolean;
    squares: boolean;
    waterBodies: boolean;
    school: boolean;
    kindergarten: boolean;
    bankBranch: boolean;
    householdServices: boolean;
    retailOutlets: boolean;
    pharmacy: boolean;
    clinic: boolean;
    nearbyIndustrialInfrastructure: boolean;
    nearbyAttractiveObjects: string;

    ecologicalSituation: EcologicalSituationChoices;
    districtPrestige: StatusTypeChoices;
    parkingAvailabilityAndType: string;
    expositionPeriodForObject: number;
    expositionPeriodForLiquidationValue: number;
    adjacentTerritoryCondition: QualityChoices;

    residentialComplexName: string;
    yearOfHouseConstruction: number | null;
    yearOfLastMajorRepairs: number | null;
    capitalizationGroup: number | null;
    buildingWearPercent: number | null;
    houseSeries: string | null;
    foundationType: string | null;
    houseFloors: number | null;
    wallMaterial: string | null;
    floorCharacteristics: string | null;

    entranceCondition: QualityChoices;
    facadeDefects: boolean;
    elevator: boolean;
    garbageChute: boolean;
    security: SecurityChoices;
    emergencyRecognition: boolean;
    roofCondition: QualityChoices;
    renovationPlansInfo: string;
    participationInRenovationProgram: string;

    floor: string;
    layoutType: LayoutTypeChoices;
    totalAreaWithSummerRooms: string;
    summerRoomArea: string;
    balconyOrLoggia: string;
    auxiliaryAreas: string;
    restroomCountAndType: string;
    ceilingHeight: string;
    visibleFinishingDefects: boolean;

    coldWaterSupply: string;
    hotWaterSupply: string;
    sewageSystem: string;
    electricalSupply: string;
    gasSupply: string;
    heatingSystem: string;
    lowVoltageEquipment: string;
    floors: string;
    ceiling: string;
    walls: string;
    windows: string;
    entryAndInteriorDoors: string;
    overallCondition: string;

    owner: string;
    rightsType: string;
    dateOfGotRight: string;
    currentUseOfProperty: string;

    conformityToTechnicalDocumentation: boolean;
    descriptionsOfRedevelopment: string;
    floorPlanByBtiDocumentsFile: number;
}

export enum ReportStatuses {
    CREATED = 'created',
    GENERATING = 'generating',
    GENERATED = 'generated',
    ERROR = 'error',
    SIGNED = 'signed'
}

export type Report = {
    id: number;
    selection: Selection;
    appraiser?: number;
    form?: ReportForm;
    status: ReportStatuses;
    document?: string;
    photoGroups: Array<PhotoGroup>;
};
export type UpdateReport = {
    id: number;
    form?: ReportForm;
    appraiser?: number;
};

export interface ReportFile {
    id: number;
    created: Date;
    modified?: Date;
    file: string;
    fileName: string;
}

export type PhotoGroup = {
    id: number;
    name: string;

    photos: Array<Photo>;
};

export type Photo = {
    id: number;
    file: string;
};
