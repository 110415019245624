
import { DocumentRead } from 'app/generatedApi/crm';

export function isDocumentReadType(document: any): document is DocumentRead {
    
    return (
        document.originalName !== undefined &&
        document.file !== undefined &&
        document.position !== undefined
    );
}