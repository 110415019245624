import React, { useMemo, useRef, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';

import { Table } from 'shared/ui/Table';

import {
    InspectionRead,
    InspectionStatusEnum,
    useInspectionsListQuery,
} from 'app/generatedApi/inspection';
import { Pagination } from 'features/pagination';
import { InspectionStatusLabels } from '../consts/utils';
import { HeadingTabs } from 'widgets/heading';
import { useSaveUrlParams } from 'shared/lib/react/useSaveUrlParams';
import { Button } from 'shared/ui/Button/Button';
import { ErrorMessage } from 'shared/ui/ErrorMessage/ErrorMessage';
import { handleError } from 'shared/lib/handleError';

const pageSize = 10;

export const InspectionTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState<InspectionStatusEnum | ''>(
        'created',
    );
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [
            { name: 'status', value: filterStatus, setValue: setFilterStatus },
            { name: 'page', value: page, setValue: setPage },
        ],
        [filterStatus, page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });

    const {
        currentData: inspections,
        isFetching,
        isLoading,
        error,
    } = useInspectionsListQuery({
        page,
        pageSize,
        status: filterStatus as InspectionStatusEnum,
    });

    const totalsInspections = useRef<number>();
    const filterTabs = Object.entries(InspectionStatusLabels).map(
        ([status, name]) => ({
            name,
            value: status,
        }),
    );

    const applicationsWithCustomerName = useMemo(
        () =>
            inspections?.results?.map((i: InspectionRead) => ({
                ...i,
                statusLabel: InspectionStatusLabels[i.status!],
            })) ?? [],
        [inspections?.results],
    );

    if (
        inspections?.count &&
        totalsInspections.current !== inspections?.count
    ) {
        totalsInspections.current = inspections.count;
    }

    if (error) {
        return (
            <ErrorMessage
                message={handleError(error)}
                className="py-8 bg-white"
            />
        );
    }

    return (
        <>
            {inspections?.count === 0 ? (
                <div className="bg-white px-4 py-12">
                    <div className="text-center">
                        <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Осмотров нет
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Начните с создания нового заказа.
                        </p>
                        <div className="mt-6">
                            <Link to={'/applications/new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новый заказ
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="bg-white pt-10 pb-6">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <HeadingTabs
                                namePage="Осмотры"
                                tabs={filterTabs}
                                currentTab={filterStatus}
                                onChangeTab={setFilterStatus}
                            />
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <Table
                            isLoading={isFetching}
                            rows={applicationsWithCustomerName}
                            columns={[
                                { key: 'id', className: 'w-[1%]' },
                                { key: 'order', className: 'w-[1%]' },
                                { key: 'address' },
                                {
                                    key: 'customer',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                                {
                                    key: 'statusLabel',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                            ]}
                            columnsName={[
                                '#',
                                'Заявка',
                                'Адрес',
                                'Заказчик',
                                'Статус',
                            ]}
                            onClick={(el) => {
                                el.status === 'created'
                                    ? navigate(`/inspections/${el.id}/edit`)
                                    : navigate(`/inspections/${el.id}/view`);
                            }}
                        />
                    </div>
                    <Pagination
                        isLoading={isLoading}
                        onPageChange={setPage}
                        totalItems={
                            totalsInspections.current || inspections?.count
                        }
                        currentPage={page}
                        itemsPerPage={pageSize}
                    />
                </>
            )}
        </>
    );
};
