import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from 'app/baseQuery';

import {
    Report,
    UpdateReport,
    CreateNewReport,
    ReportFile,
    PhotoGroup,
    Photo,
} from './types';

export const reportsApi = createApi({
    reducerPath: 'reportsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        report: builder.query<Report, number>({
            query: (id) => ({
                url: `/api/v1/reports/${id}`,
                method: 'GET',
            }),
        }),
        updateReport: builder.mutation<Report, UpdateReport>({
            query: (body) => ({
                url: `/api/v1/reports/${body.id}/`,
                method: 'PATCH',
                body,
            }),
        }),
        createNewReport: builder.mutation<Report, CreateNewReport>({
            query: (body) => ({
                url: '/api/v1/reports/',
                method: 'POST',
                body,
            }),
        }),
        generate: builder.mutation<Report, Report>({
            query: (body) => ({
                url: `/api/v1/reports/${body.id}/generate`,
                method: 'POST',
            }),
        }),
        uploadFile: builder.mutation<ReportFile, FormData>({
            query: (body) => ({
                url: `/api/v1/reports/files/`,
                method: 'POST',
                body,
                formData: true,
            }),
        }),
        getReportFile: builder.query<ReportFile, number>({
            query: (body) => ({
                url: `/api/v1/reports/files/${body}/`,
                method: 'GET',
            }),
        }),
        getPhotoGroup: builder.query<PhotoGroup, number>({
            query: (id) => ({
                url: `/api/v1/reports/photo-group/${id}/`,
                method: 'GET',
            }),
        }),
        createPhotoGroup: builder.mutation<
            PhotoGroup,
            { report: number; name: string }
        >({
            query: (body) => ({
                url: `/api/v1/reports/photo-group/`,
                method: 'POST',
                body,
            }),
        }),
        updatePhotoGroup: builder.mutation<
            PhotoGroup,
            { id: number; name?: string; photos?: number[] }
        >({
            query: ({ id, name, photos }) => ({
                url: `/api/v1/reports/photo-group/${id}/`,
                method: 'PATCH',
                body: { name, photos },
            }),
        }),
        deletePhotoGroup: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/api/v1/reports/photo-group/${id}/`,
                method: 'DELETE',
            }),
        }),
        uploadPhoto: builder.mutation<Photo, FormData>({
            query: (body) => ({
                url: `/api/v1/reports/photo/`,
                method: 'POST',
                body,
                formData: true,
            }),
        }),
    }),
});

export const {
    useReportQuery,
    useCreateNewReportMutation,
    useUpdateReportMutation,
    useGenerateMutation,
    useUploadFileMutation,
    useGetReportFileQuery,
    useLazyGetReportFileQuery,
    useCreatePhotoGroupMutation,
    useDeletePhotoGroupMutation,
    useGetPhotoGroupQuery,
    useUpdatePhotoGroupMutation,
    useUploadPhotoMutation,
} = reportsApi;
