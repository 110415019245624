import { generatedApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        crmBanksList: build.query<CrmBanksListApiResponse, CrmBanksListApiArg>({
            query: () => ({ url: `/api/v1/crm/banks/` }),
        }),
        crmBanksRetrieve: build.query<
            CrmBanksRetrieveApiResponse,
            CrmBanksRetrieveApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/crm/banks/${queryArg.id}/` }),
        }),
        crmCitiesList: build.query<
            CrmCitiesListApiResponse,
            CrmCitiesListApiArg
        >({
            query: () => ({ url: `/api/v1/crm/cities/` }),
        }),
        crmCitiesRetrieve: build.query<
            CrmCitiesRetrieveApiResponse,
            CrmCitiesRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/cities/${queryArg.id}/`,
            }),
        }),
        crmDocumentsList: build.query<
            CrmDocumentsListApiResponse,
            CrmDocumentsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents/`,
                params: { page: queryArg.page, page_size: queryArg.pageSize },
            }),
        }),
        crmDocumentsCreate: build.mutation<
            CrmDocumentsCreateApiResponse,
            CrmDocumentsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents/`,
                method: 'POST',
                body: queryArg.document,
            }),
        }),
        crmDocumentsGroupsList: build.query<
            CrmDocumentsGroupsListApiResponse,
            CrmDocumentsGroupsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents-groups/`,
                params: { page: queryArg.page, page_size: queryArg.pageSize },
            }),
        }),
        crmDocumentsGroupsCreate: build.mutation<
            CrmDocumentsGroupsCreateApiResponse,
            CrmDocumentsGroupsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents-groups/`,
                method: 'POST',
                body: queryArg.documentsGroup,
            }),
        }),
        crmDocumentsGroupsRetrieve: build.query<
            CrmDocumentsGroupsRetrieveApiResponse,
            CrmDocumentsGroupsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents-groups/${queryArg.id}/`,
            }),
        }),
        crmDocumentsGroupsUpdate: build.mutation<
            CrmDocumentsGroupsUpdateApiResponse,
            CrmDocumentsGroupsUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents-groups/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.documentsGroup,
            }),
        }),
        crmDocumentsGroupsPartialUpdate: build.mutation<
            CrmDocumentsGroupsPartialUpdateApiResponse,
            CrmDocumentsGroupsPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents-groups/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedDocumentsGroup,
            }),
        }),
        crmDocumentsGroupsDestroy: build.mutation<
            CrmDocumentsGroupsDestroyApiResponse,
            CrmDocumentsGroupsDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents-groups/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmDocumentsRetrieve: build.query<
            CrmDocumentsRetrieveApiResponse,
            CrmDocumentsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents/${queryArg.id}/`,
            }),
        }),
        crmDocumentsUpdate: build.mutation<
            CrmDocumentsUpdateApiResponse,
            CrmDocumentsUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.document,
            }),
        }),
        crmDocumentsPartialUpdate: build.mutation<
            CrmDocumentsPartialUpdateApiResponse,
            CrmDocumentsPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedDocument,
            }),
        }),
        crmDocumentsDestroy: build.mutation<
            CrmDocumentsDestroyApiResponse,
            CrmDocumentsDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/documents/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmNewDocumentsList: build.query<
            CrmNewDocumentsListApiResponse,
            CrmNewDocumentsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents/`,
                params: { page: queryArg.page, page_size: queryArg.pageSize },
            }),
        }),
        crmNewDocumentsCreate: build.mutation<
            CrmNewDocumentsCreateApiResponse,
            CrmNewDocumentsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents/`,
                method: 'POST',
                body: queryArg.document,
            }),
        }),
        crmNewDocumentsGroupsList: build.query<
            CrmNewDocumentsGroupsListApiResponse,
            CrmNewDocumentsGroupsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents-groups/`,
                params: { page: queryArg.page, page_size: queryArg.pageSize },
            }),
        }),
        crmNewDocumentsGroupsCreate: build.mutation<
            CrmNewDocumentsGroupsCreateApiResponse,
            CrmNewDocumentsGroupsCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents-groups/`,
                method: 'POST',
                body: queryArg.documentsGroup,
            }),
        }),
        crmNewDocumentsGroupsRetrieve: build.query<
            CrmNewDocumentsGroupsRetrieveApiResponse,
            CrmNewDocumentsGroupsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents-groups/${queryArg.id}/`,
            }),
        }),
        crmNewDocumentsGroupsUpdate: build.mutation<
            CrmNewDocumentsGroupsUpdateApiResponse,
            CrmNewDocumentsGroupsUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents-groups/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.documentsGroup,
            }),
        }),
        crmNewDocumentsGroupsPartialUpdate: build.mutation<
            CrmNewDocumentsGroupsPartialUpdateApiResponse,
            CrmNewDocumentsGroupsPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents-groups/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedDocumentsGroup,
            }),
        }),
        crmNewDocumentsGroupsDestroy: build.mutation<
            CrmNewDocumentsGroupsDestroyApiResponse,
            CrmNewDocumentsGroupsDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents-groups/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmNewDocumentsRetrieve: build.query<
            CrmNewDocumentsRetrieveApiResponse,
            CrmNewDocumentsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents/${queryArg.id}/`,
            }),
        }),
        crmNewDocumentsUpdate: build.mutation<
            CrmNewDocumentsUpdateApiResponse,
            CrmNewDocumentsUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.document,
            }),
        }),
        crmNewDocumentsPartialUpdate: build.mutation<
            CrmNewDocumentsPartialUpdateApiResponse,
            CrmNewDocumentsPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedDocument,
            }),
        }),
        crmNewDocumentsDestroy: build.mutation<
            CrmNewDocumentsDestroyApiResponse,
            CrmNewDocumentsDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/new-documents/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmOrdersList: build.query<
            CrmOrdersListApiResponse,
            CrmOrdersListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/`,
                params: {
                    ordering: queryArg.ordering,
                    page: queryArg.page,
                    page_size: queryArg.pageSize,
                    query: queryArg.query,
                    status: queryArg.status,
                },
            }),
        }),
        crmOrdersCreate: build.mutation<
            CrmOrdersCreateApiResponse,
            CrmOrdersCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/`,
                method: 'POST',
                body: queryArg.order,
            }),
        }),
        crmOrdersCustomerList: build.query<
            CrmOrdersCustomerListApiResponse,
            CrmOrdersCustomerListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-customer/`,
                params: {
                    ordering: queryArg.ordering,
                    page: queryArg.page,
                    page_size: queryArg.pageSize,
                },
            }),
        }),
        crmOrdersCustomerCreate: build.mutation<
            CrmOrdersCustomerCreateApiResponse,
            CrmOrdersCustomerCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-customer/`,
                method: 'POST',
                body: queryArg.customerOrder,
            }),
        }),
        crmOrdersCustomerRetrieve: build.query<
            CrmOrdersCustomerRetrieveApiResponse,
            CrmOrdersCustomerRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-customer/${queryArg.id}/`,
            }),
        }),
        crmOrdersCustomerUpdate: build.mutation<
            CrmOrdersCustomerUpdateApiResponse,
            CrmOrdersCustomerUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-customer/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.customerOrder,
            }),
        }),
        crmOrdersCustomerPartialUpdate: build.mutation<
            CrmOrdersCustomerPartialUpdateApiResponse,
            CrmOrdersCustomerPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-customer/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedCustomerOrder,
            }),
        }),
        crmOrdersCustomerDestroy: build.mutation<
            CrmOrdersCustomerDestroyApiResponse,
            CrmOrdersCustomerDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-customer/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmOrdersOwnersCreate: build.mutation<
            CrmOrdersOwnersCreateApiResponse,
            CrmOrdersOwnersCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-owners/`,
                method: 'POST',
                body: queryArg.owner,
            }),
        }),
        crmOrdersOwnersUpdate: build.mutation<
            CrmOrdersOwnersUpdateApiResponse,
            CrmOrdersOwnersUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-owners/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.owner,
            }),
        }),
        crmOrdersOwnersPartialUpdate: build.mutation<
            CrmOrdersOwnersPartialUpdateApiResponse,
            CrmOrdersOwnersPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-owners/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedOwner,
            }),
        }),
        crmOrdersOwnersDestroy: build.mutation<
            CrmOrdersOwnersDestroyApiResponse,
            CrmOrdersOwnersDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders-owners/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmOrdersRetrieve: build.query<
            CrmOrdersRetrieveApiResponse,
            CrmOrdersRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/`,
            }),
        }),
        crmOrdersUpdate: build.mutation<
            CrmOrdersUpdateApiResponse,
            CrmOrdersUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.order,
            }),
        }),
        crmOrdersPartialUpdate: build.mutation<
            CrmOrdersPartialUpdateApiResponse,
            CrmOrdersPartialUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedOrder,
            }),
        }),
        crmOrdersDestroy: build.mutation<
            CrmOrdersDestroyApiResponse,
            CrmOrdersDestroyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        crmOrdersCancelCreate: build.mutation<
            CrmOrdersCancelCreateApiResponse,
            CrmOrdersCancelCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/cancel/`,
                method: 'POST',
                body: queryArg.order,
            }),
        }),
        crmOrdersInspectionCreate: build.mutation<
            CrmOrdersInspectionCreateApiResponse,
            CrmOrdersInspectionCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/inspection/`,
                method: 'POST',
                body: queryArg.retrieveOrder,
            }),
        }),
        crmOrdersLegalDocumentsList: build.query<
            CrmOrdersLegalDocumentsListApiResponse,
            CrmOrdersLegalDocumentsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/legal_documents/`,
            }),
        }),
        crmOrdersSelectionCreate: build.mutation<
            CrmOrdersSelectionCreateApiResponse,
            CrmOrdersSelectionCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/selection/`,
                method: 'POST',
                body: queryArg.retrieveOrder,
            }),
        }),
        crmOrdersStartCreate: build.mutation<
            CrmOrdersStartCreateApiResponse,
            CrmOrdersStartCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/start/`,
                method: 'POST',
                body: queryArg.order,
            }),
        }),
        crmOrdersTechDocumentsList: build.query<
            CrmOrdersTechDocumentsListApiResponse,
            CrmOrdersTechDocumentsListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/${queryArg.id}/tech_documents/`,
            }),
        }),
        crmOrdersNextRetrieve: build.query<
            CrmOrdersNextRetrieveApiResponse,
            CrmOrdersNextRetrieveApiArg
        >({
            query: () => ({ url: `/api/v1/crm/orders/next/` }),
        }),
        crmOrdersStatsRetrieve: build.query<
            CrmOrdersStatsRetrieveApiResponse,
            CrmOrdersStatsRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/crm/orders/stats`,
                params: {
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type CrmBanksListApiResponse = /** status 200  */ BankRead[];
export type CrmBanksListApiArg = void;
export type CrmBanksRetrieveApiResponse = /** status 200  */ BankRead;
export type CrmBanksRetrieveApiArg = {
    /** A unique integer value identifying this Банк. */
    id: number;
};
export type CrmCitiesListApiResponse = /** status 200  */ CitiesRead[];
export type CrmCitiesListApiArg = void;
export type CrmCitiesRetrieveApiResponse = /** status 200  */ CitiesRead;
export type CrmCitiesRetrieveApiArg = {
    /** A unique integer value identifying this city. */
    id: number;
};
export type CrmDocumentsListApiResponse =
    /** status 200  */ PaginatedDocumentListRead;
export type CrmDocumentsListApiArg = {
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
};
export type CrmDocumentsCreateApiResponse = /** status 201  */ DocumentRead;
export type CrmDocumentsCreateApiArg = {
    document: Document;
};
export type CrmDocumentsGroupsListApiResponse =
    /** status 200  */ PaginatedDocumentsGroupListRead;
export type CrmDocumentsGroupsListApiArg = {
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
};
export type CrmDocumentsGroupsCreateApiResponse =
    /** status 201  */ DocumentsGroupRead;
export type CrmDocumentsGroupsCreateApiArg = {
    documentsGroup: DocumentsGroup;
};
export type CrmDocumentsGroupsRetrieveApiResponse =
    /** status 200  */ DocumentsGroupRead;
export type CrmDocumentsGroupsRetrieveApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
};
export type CrmDocumentsGroupsUpdateApiResponse =
    /** status 200  */ DocumentsGroupRead;
export type CrmDocumentsGroupsUpdateApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
    documentsGroup: DocumentsGroup;
};
export type CrmDocumentsGroupsPartialUpdateApiResponse =
    /** status 200  */ DocumentsGroupRead;
export type CrmDocumentsGroupsPartialUpdateApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
    patchedDocumentsGroup: PatchedDocumentsGroup;
};
export type CrmDocumentsGroupsDestroyApiResponse = unknown;
export type CrmDocumentsGroupsDestroyApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
};
export type CrmDocumentsRetrieveApiResponse = /** status 200  */ DocumentRead;
export type CrmDocumentsRetrieveApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
};
export type CrmDocumentsUpdateApiResponse = /** status 200  */ DocumentRead;
export type CrmDocumentsUpdateApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
    document: Document;
};
export type CrmDocumentsPartialUpdateApiResponse =
    /** status 200  */ DocumentRead;
export type CrmDocumentsPartialUpdateApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
    patchedDocument: PatchedDocument;
};
export type CrmDocumentsDestroyApiResponse = unknown;
export type CrmDocumentsDestroyApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
};
export type CrmNewDocumentsListApiResponse =
    /** status 200  */ PaginatedDocumentListRead;
export type CrmNewDocumentsListApiArg = {
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
};
export type CrmNewDocumentsCreateApiResponse = /** status 201  */ DocumentRead;
export type CrmNewDocumentsCreateApiArg = {
    document: Document;
};
export type CrmNewDocumentsGroupsListApiResponse =
    /** status 200  */ PaginatedDocumentsGroupListRead;
export type CrmNewDocumentsGroupsListApiArg = {
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
};
export type CrmNewDocumentsGroupsCreateApiResponse =
    /** status 201  */ DocumentsGroupRead;
export type CrmNewDocumentsGroupsCreateApiArg = {
    documentsGroup: DocumentsGroup;
};
export type CrmNewDocumentsGroupsRetrieveApiResponse =
    /** status 200  */ DocumentsGroupRead;
export type CrmNewDocumentsGroupsRetrieveApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
};
export type CrmNewDocumentsGroupsUpdateApiResponse =
    /** status 200  */ DocumentsGroupRead;
export type CrmNewDocumentsGroupsUpdateApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
    documentsGroup: DocumentsGroup;
};
export type CrmNewDocumentsGroupsPartialUpdateApiResponse =
    /** status 200  */ DocumentsGroupRead;
export type CrmNewDocumentsGroupsPartialUpdateApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
    patchedDocumentsGroup: PatchedDocumentsGroup;
};
export type CrmNewDocumentsGroupsDestroyApiResponse = unknown;
export type CrmNewDocumentsGroupsDestroyApiArg = {
    /** A unique integer value identifying this documents group. */
    id: number;
};
export type CrmNewDocumentsRetrieveApiResponse =
    /** status 200  */ DocumentRead;
export type CrmNewDocumentsRetrieveApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
};
export type CrmNewDocumentsUpdateApiResponse = /** status 200  */ DocumentRead;
export type CrmNewDocumentsUpdateApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
    document: Document;
};
export type CrmNewDocumentsPartialUpdateApiResponse =
    /** status 200  */ DocumentRead;
export type CrmNewDocumentsPartialUpdateApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
    patchedDocument: PatchedDocument;
};
export type CrmNewDocumentsDestroyApiResponse = unknown;
export type CrmNewDocumentsDestroyApiArg = {
    /** A unique integer value identifying this document. */
    id: number;
};
export type CrmOrdersListApiResponse =
    /** status 200  */ PaginatedOrderListRead;
export type CrmOrdersListApiArg = {
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
    query?: string;
    /** * `draft` - Draft
     * `new` - New
     * `inspection_request` - Inspection Request
     * `inspection_waiting` - Inspection Waiting
     * `inspection_done` - Inspection Done
     * `selection_waiting` - Selection Waiting
     * `selection_done` - Selection Done
     * `selection_error` - Selection Error
     * `report_waiting` - Report Waiting
     * `report_done` - Report Done
     * `report_error` - Report Error
     * `queued` - Queued
     * `processing` - Process
     * `completed` - Complete
     * `declined` - Decline
     * `archived` - Archived */
    status?:
        | 'archived'
        | 'completed'
        | 'declined'
        | 'draft'
        | 'inspection_done'
        | 'inspection_request'
        | 'inspection_waiting'
        | 'new'
        | 'processing'
        | 'queued'
        | 'report_done'
        | 'report_error'
        | 'report_waiting'
        | 'selection_done'
        | 'selection_error'
        | 'selection_waiting';
};
export type CrmOrdersCreateApiResponse = /** status 201  */ OrderRead;
export type CrmOrdersCreateApiArg = {
    order: Order;
};
export type CrmOrdersCustomerListApiResponse =
    /** status 200  */ PaginatedCustomerOrderListRead;
export type CrmOrdersCustomerListApiArg = {
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
};
export type CrmOrdersCustomerCreateApiResponse =
    /** status 201  */ CustomerOrderRead;
export type CrmOrdersCustomerCreateApiArg = {
    customerOrder: CustomerOrder;
};
export type CrmOrdersCustomerRetrieveApiResponse =
    /** status 200  */ CustomerOrderRead;
export type CrmOrdersCustomerRetrieveApiArg = {
    id: string;
};
export type CrmOrdersCustomerUpdateApiResponse =
    /** status 200  */ CustomerOrderRead;
export type CrmOrdersCustomerUpdateApiArg = {
    id: string;
    customerOrder: CustomerOrder;
};
export type CrmOrdersCustomerPartialUpdateApiResponse =
    /** status 200  */ CustomerOrderRead;
export type CrmOrdersCustomerPartialUpdateApiArg = {
    id: string;
    patchedCustomerOrder: PatchedCustomerOrder;
};
export type CrmOrdersCustomerDestroyApiResponse = unknown;
export type CrmOrdersCustomerDestroyApiArg = {
    id: string;
};
export type CrmOrdersOwnersCreateApiResponse = /** status 201  */ OwnerRead;
export type CrmOrdersOwnersCreateApiArg = {
    owner: Owner;
};
export type CrmOrdersOwnersUpdateApiResponse = /** status 200  */ OwnerRead;
export type CrmOrdersOwnersUpdateApiArg = {
    /** A unique integer value identifying this Собственник. */
    id: number;
    owner: Owner;
};
export type CrmOrdersOwnersPartialUpdateApiResponse =
    /** status 200  */ OwnerRead;
export type CrmOrdersOwnersPartialUpdateApiArg = {
    /** A unique integer value identifying this Собственник. */
    id: number;
    patchedOwner: PatchedOwner;
};
export type CrmOrdersOwnersDestroyApiResponse = unknown;
export type CrmOrdersOwnersDestroyApiArg = {
    /** A unique integer value identifying this Собственник. */
    id: number;
};
export type CrmOrdersRetrieveApiResponse = /** status 200  */ RetrieveOrderRead;
export type CrmOrdersRetrieveApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type CrmOrdersUpdateApiResponse = /** status 200  */ OrderRead;
export type CrmOrdersUpdateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    order: Order;
};
export type CrmOrdersPartialUpdateApiResponse = /** status 200  */ OrderRead;
export type CrmOrdersPartialUpdateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    patchedOrder: PatchedOrder;
};
export type CrmOrdersDestroyApiResponse = unknown;
export type CrmOrdersDestroyApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type CrmOrdersCancelCreateApiResponse = /** status 200  */ OrderRead;
export type CrmOrdersCancelCreateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    order: Order;
};
export type CrmOrdersInspectionCreateApiResponse =
    /** status 200  */ RetrieveOrderRead;
export type CrmOrdersInspectionCreateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    retrieveOrder: RetrieveOrder;
};
export type CrmOrdersLegalDocumentsListApiResponse =
    /** status 200  */ DocumentsGroupRead[];
export type CrmOrdersLegalDocumentsListApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type CrmOrdersSelectionCreateApiResponse =
    /** status 200  */ RetrieveOrderRead;
export type CrmOrdersSelectionCreateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    retrieveOrder: RetrieveOrder;
};
export type CrmOrdersStartCreateApiResponse = /** status 200  */ OrderRead;
export type CrmOrdersStartCreateApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
    order: Order;
};
export type CrmOrdersTechDocumentsListApiResponse =
    /** status 200  */ DocumentsGroupRead[];
export type CrmOrdersTechDocumentsListApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type CrmOrdersNextRetrieveApiResponse = /** status 200  */ OrderRead;
export type CrmOrdersNextRetrieveApiArg = void;
export type CrmOrdersStatsRetrieveApiResponse =
    /** status 200  */ OrdersStatsResponse;
export type CrmOrdersStatsRetrieveApiArg = {
    dateFrom: string;
    dateTo: string;
};
export type Bank = {
    name: string;
    shortName: string;
    image?: string | null;
    defaultEvaluator?: number | null;
};
export type BankRead = {
    id: number;
    name: string;
    shortName: string;
    image?: string | null;
    defaultEvaluator?: number | null;
};
export type Cities = {
    name: string;
};
export type CitiesRead = {
    id: number;
    name: string;
};
export type Document = {
    modified?: string | null;
    file?: string | null;
    position: number;
    group?: number | null;
    newFile?: number | null;
};
export type DocumentRead = {
    id: number;
    created: string;
    modified?: string | null;
    file?: string | null;
    originalName: string;
    position: number;
    group?: number | null;
    newFile?: number | null;
};
export type PaginatedDocumentList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Document[];
};
export type PaginatedDocumentListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: DocumentRead[];
};
export type DocumentsGroup = {
    modified?: string | null;
    name: string;
};
export type DocumentsGroupRead = {
    id: number;
    documents: DocumentRead[];
    created: string;
    modified?: string | null;
    name: string;
};
export type PaginatedDocumentsGroupList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: DocumentsGroup[];
};
export type PaginatedDocumentsGroupListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: DocumentsGroupRead[];
};
export type PatchedDocumentsGroup = {
    modified?: string | null;
    name?: string;
};
export type PatchedDocumentsGroupRead = {
    id?: number;
    documents?: DocumentRead[];
    created?: string;
    modified?: string | null;
    name?: string;
};
export type PatchedDocument = {
    modified?: string | null;
    file?: string | null;
    position?: number;
    group?: number | null;
    newFile?: number | null;
};
export type PatchedDocumentRead = {
    id?: number;
    created?: string;
    modified?: string | null;
    file?: string | null;
    originalName?: string;
    position?: number;
    group?: number | null;
    newFile?: number | null;
};
export type StatusD4EEnum =
    | 'draft'
    | 'new'
    | 'inspection_request'
    | 'inspection_waiting'
    | 'inspection_done'
    | 'selection_waiting'
    | 'selection_done'
    | 'selection_error'
    | 'report_waiting'
    | 'report_done'
    | 'report_error'
    | 'queued'
    | 'processing'
    | 'completed'
    | 'declined'
    | 'archived';
export type OoTypeEnum = 'residential_complex' | 'manual';
export type SummerRoomsType34DEnum =
    | 'none'
    | 'loggia'
    | 'balcony'
    | 'two_balcony'
    | 'two_loggia'
    | 'loggia_and_balcony';
export type LayoutTypeEnum = 'free' | 'fixed';
export type BlankEnum = '';
export type NullEnum = null;
export type OrderPurposeEnum = 'pay_mortgage' | 'get_mortgage' | 'other';
export type Order = {
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    floorPlanBti?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
    legalDocuments?: number[];
    techDocuments?: number[];
};
export type OrderRead = {
    id: number;
    created: string;
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    floorPlanBti?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
    legalDocuments?: number[];
    techDocuments?: number[];
};
export type PaginatedOrderList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Order[];
};
export type PaginatedOrderListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: OrderRead[];
};
export type CustomerOrder = {
    customerFio?: string | null;
    city?: number | null;
    bank?: number | null;
    phone?: string | null;
    email?: string | null;
    address?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    customerConsent?: boolean;
    area?: number | null;
};
export type CustomerOrderRead = {
    id: number;
    customerFio?: string | null;
    city?: number | null;
    bank?: number | null;
    phone?: string | null;
    email?: string | null;
    address?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    report: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    customerConsent?: boolean;
    area?: number | null;
};
export type PaginatedCustomerOrderList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: CustomerOrder[];
};
export type PaginatedCustomerOrderListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: CustomerOrderRead[];
};
export type PatchedCustomerOrder = {
    customerFio?: string | null;
    city?: number | null;
    bank?: number | null;
    phone?: string | null;
    email?: string | null;
    address?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    customerConsent?: boolean;
    area?: number | null;
};
export type PatchedCustomerOrderRead = {
    id?: number;
    customerFio?: string | null;
    city?: number | null;
    bank?: number | null;
    phone?: string | null;
    email?: string | null;
    address?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    report?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    customerConsent?: boolean;
    area?: number | null;
};
export type Owner = {
    owner?: string;
    ownershipType?: string;
    gotOwnershipDate: string;
    order: number;
    assessmentObject?: number | null;
};
export type OwnerRead = {
    id: number;
    owner?: string;
    ownershipType?: string;
    gotOwnershipDate: string;
    order: number;
    assessmentObject?: number | null;
};
export type PatchedOwner = {
    owner?: string;
    ownershipType?: string;
    gotOwnershipDate?: string;
    order?: number;
    assessmentObject?: number | null;
};
export type PatchedOwnerRead = {
    id?: number;
    owner?: string;
    ownershipType?: string;
    gotOwnershipDate?: string;
    order?: number;
    assessmentObject?: number | null;
};
export type RetrieveOrder = {
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
};
export type ResidentialComplex = {
    modified?: string | null;
    name: string;
    subject?: string;
    locality?: string;
    polygon: string;
    additionalFilters?: any | null;
    resaleOnly?: boolean;
    city?: number | null;
};
export type ResidentialComplexRead = {
    id: number;
    created: string;
    modified?: string | null;
    name: string;
    subject?: string;
    locality?: string;
    polygon: string;
    additionalFilters?: any | null;
    resaleOnly?: boolean;
    city?: number | null;
};
export type WallTypeEnum =
    | 'unknown'
    | 'brick'
    | 'panel'
    | 'block'
    | 'monolith'
    | 'wooden'
    | 'monolith_brick';
export type SecurityEnum = 1 | 2 | 3 | 4;
export type TransportAvailabilityLevelEnum = 1 | 2 | 3;
export type ResidentialComplexHouse = {
    modified?: string | null;
    address?: string;
    kadastrNumber?: string;
    location?: string | null;
    builtYear: number;
    buildingWearPercentage: number;
    serialNumber?: string;
    foundationType: string;
    floorsCount: number;
    wallType?: WallTypeEnum;
    floorType: string;
    elevator?: boolean;
    garbageChute?: boolean;
    security?: SecurityEnum;
    coldWaterSupply?: string;
    hotWaterSupply?: string;
    sewageSystem?: string;
    electricalSupply?: string;
    gasSupply?: string;
    heatingSystem?: string;
    lowVoltageEquipment?: string;
    transportAvailabilityLevel?: TransportAvailabilityLevelEnum;
    kindergarten?: boolean | null;
    school?: boolean | null;
    clinic?: boolean | null;
    mall?: boolean | null;
    pond?: boolean | null;
    residentialComplex: number;
};
export type ResidentialComplexHouseRead = {
    id: number;
    created: string;
    modified?: string | null;
    address?: string;
    kadastrNumber?: string;
    location?: string | null;
    builtYear: number;
    buildingWearPercentage: number;
    serialNumber?: string;
    foundationType: string;
    floorsCount: number;
    wallType?: WallTypeEnum;
    floorType: string;
    elevator?: boolean;
    garbageChute?: boolean;
    security?: SecurityEnum;
    coldWaterSupply?: string;
    hotWaterSupply?: string;
    sewageSystem?: string;
    electricalSupply?: string;
    gasSupply?: string;
    heatingSystem?: string;
    lowVoltageEquipment?: string;
    transportAvailabilityLevel?: TransportAvailabilityLevelEnum;
    kindergarten?: boolean | null;
    school?: boolean | null;
    clinic?: boolean | null;
    mall?: boolean | null;
    pond?: boolean | null;
    residentialComplex: number;
};
export type RenovationTypeEnum = -1 | 0 | 1 | 2 | 3 | 4 | 5;
export type SummerRoomsType2E3Enum = -1 | 0 | 1 | 2;
export type ResidentialComplexApartment = {
    modified?: string | null;
    number: number;
    roomsCount: number;
    area: number;
    floor: number;
    renovationType?: RenovationTypeEnum;
    summerRoomsType?: SummerRoomsType2E3Enum;
    layoutType?: LayoutTypeEnum;
    livingArea?: number | null;
    kitchenArea?: number | null;
    fullArea?: number | null;
    summerRoomsArea?: number | null;
    roomsAreas?: number[];
    summerRooms?: boolean;
    utilityRoomsArea?: number | null;
    restroomCountAndType?: string;
    heightOfCeiling?: number | null;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    entryAndInteriorDoors?: string;
    overallCondition?: string;
    house: number;
    entrance?: number | null;
};
export type ResidentialComplexApartmentRead = {
    id: number;
    created: string;
    modified?: string | null;
    number: number;
    roomsCount: number;
    area: number;
    floor: number;
    renovationType?: RenovationTypeEnum;
    summerRoomsType?: SummerRoomsType2E3Enum;
    layoutType?: LayoutTypeEnum;
    livingArea?: number | null;
    kitchenArea?: number | null;
    fullArea?: number | null;
    summerRoomsArea?: number | null;
    roomsAreas?: number[];
    summerRooms?: boolean;
    utilityRoomsArea?: number | null;
    restroomCountAndType?: string;
    heightOfCeiling?: number | null;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    entryAndInteriorDoors?: string;
    overallCondition?: string;
    house: number;
    entrance?: number | null;
};
export type Building = {
    id: number;
    regionId?: string | null;
    areaId?: string | null;
    cityId?: string | null;
    streetId?: string | null;
    shortnameRegion?: string | null;
    formalnameRegion?: string | null;
    shortnameArea?: string | null;
    formalnameArea?: string | null;
    shortnameCity?: string | null;
    formalnameCity?: string | null;
    shortnameStreet?: string | null;
    formalnameStreet?: string | null;
    houseNumber?: string | null;
    building?: string | null;
    block?: string | null;
    letter?: string | null;
    address?: string | null;
    houseguid?: string | null;
    managementOrganizationId?: number | null;
    builtYear?: number | null;
    exploitationStartYear?: number | null;
    projectType?: string | null;
    houseType?: string | null;
    isAlarm?: string | null;
    methodOfFormingOverhaulFund: string;
    floorCountMax?: number | null;
    floorCountMin?: number | null;
    entranceCount?: number | null;
    elevatorsCount?: number | null;
    energyEfficiency?: string | null;
    quartersCount?: number | null;
    livingQuartersCount?: number | null;
    unlivingQuartersCount?: number | null;
    areaTotal?: number | null;
    areaResidential?: number | null;
    areaNonResidential?: number | null;
    areaCommonProperty?: number | null;
    areaLand?: number | null;
    parkingSquare?: number | null;
    playground?: number | null;
    sportsground?: number | null;
    otherBeautification?: string | null;
    foundationType?: string | null;
    floorType?: string | null;
    wallMaterial?: string | null;
    basementArea?: string | null;
    chuteType?: string | null;
    chuteCount?: number | null;
    electricalType?: string | null;
    electricalEntriesCount?: number | null;
    heatingType?: string | null;
    hotWaterType?: string | null;
    coldWaterType?: string | null;
    sewerageType?: string | null;
    sewerageCesspoolsVolume?: number | null;
    gasType?: string | null;
    ventilationType?: string | null;
    firefightingType?: string | null;
    drainageType?: string | null;
    systemCity?: number | null;
    newCity?: number | null;
};
export type TypeCadEnum = 'default' | 'residential_complex';
export type NewRenovationTypeEnum =
    | 'unknown'
    | 'none'
    | 'preclean'
    | 'average'
    | 'good'
    | 'euro'
    | 'premium';
export type SummerRoomsEnum = -1 | 0 | 1 | 2;
export type HouseTypeEnum = -1 | 1 | 2;
export type Selection = {
    building: Building;
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    homogeneityRatio?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplexApartment?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type CorrectedAnalog = {
    sumOfPercentageCorrections: number;
    pricePerMeter?: number;
    correctedPricePerMeter?: number;
    bargainingCorrection?: number;
    salesTypeCorrection?: number;
    wallTypeCorrection?: number;
    floorCorrection?: number;
    areaCorrection?: number;
    summerRoomsCorrection?: number;
    renovationTypeCorrection?: number;
    weight?: number;
};
export type SourceIdEnum = 4;
export type WindowsViewEnum = -1 | 0 | 1;
export type Advertisement = {
    id: number;
    pricePerMeter: number;
    modified?: string | null;
    address?: string;
    sourceUrl: string;
    sourceId?: SourceIdEnum;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro?: string;
    kmDoMetro: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    windowsView?: WindowsViewEnum;
    summerRooms?: SummerRoomsEnum;
    heightOfCeiling?: number | null;
    buildingYear?: number | null;
    location?: string | null;
    originalData?: any;
    unpublished?: boolean;
    photoCount?: number;
    dadataData?: any | null;
    parsedAt?: string | null;
    screenshot?: string | null;
    screenshotTakenAt?: string | null;
    building?: number | null;
};
export type AdvertisementRead = {
    id: number;
    pricePerMeter: number;
    wallTypeLabel: string;
    renovationTypeLabel: string;
    summerRoomsLabel: string;
    windowsViewLabel: string;
    houseTypeLabel: string;
    created: string;
    modified?: string | null;
    address?: string;
    sourceUrl: string;
    sourceId?: SourceIdEnum;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro?: string;
    kmDoMetro: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    windowsView?: WindowsViewEnum;
    summerRooms?: SummerRoomsEnum;
    heightOfCeiling?: number | null;
    buildingYear?: number | null;
    location?: string | null;
    originalData?: any;
    unpublished?: boolean;
    photoCount?: number;
    dadataData?: any | null;
    parsedAt?: string | null;
    screenshot?: string | null;
    screenshotTakenAt?: string | null;
    building?: number | null;
};
export type CorrectedAnalogRead = {
    id: number;
    advertisement: AdvertisementRead;
    sumOfPercentageCorrections: number;
    pricePerMeterWithBargainingCorrection: string;
    pricePerMeter?: number;
    correctedPricePerMeter?: number;
    bargainingCorrection?: number;
    salesTypeCorrection?: number;
    wallTypeCorrection?: number;
    floorCorrection?: number;
    areaCorrection?: number;
    summerRoomsCorrection?: number;
    renovationTypeCorrection?: number;
    weight?: number;
};
export type SimpleReport = {};
export type SimpleReportRead = {
    id: number;
};
export type StatusA47Enum = 0 | 1 | 2 | 3;
export type SelectionRead = {
    id: number;
    building: Building;
    correctedAnalogs: CorrectedAnalogRead[];
    analogs: AdvertisementRead[];
    report: SimpleReportRead;
    priceInWords: string;
    liquidationPriceInWords: string;
    roominess: string;
    discountRatePercentage: string;
    lowLiquidityRiskCompensationPercentage: string;
    riskFreeRatePercentage: string;
    residentialComplex: ResidentialComplexRead;
    residentialComplexHouse: ResidentialComplexHouseRead;
    ratio: string;
    priceFormatted: string;
    liquidationPriceFormatted: string;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step: number;
    distance: number;
    areaThreshold: number;
    homogeneityRatio?: number;
    pricePerMeter: number;
    price: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplexApartment?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type Status456Enum =
    | 'created'
    | 'generating'
    | 'generated'
    | 'signed'
    | 'error';
export type Report = {
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type SelectionList = {
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type SelectionListRead = {
    id: number;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type ReportRead = {
    id: number;
    selection: SelectionListRead;
    created: string;
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    document: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type InspectionStatusEnum = 'created' | 'completed';
export type WindowViewEnum = -1 | 0 | 1;
export type Inspection = {
    modified?: string | null;
    address?: string;
    customer?: string;
    status?: InspectionStatusEnum;
    entranceCondition?: string;
    roofCondition?: string;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    visibleFinishingDefects?: string;
    windowView?: WindowViewEnum;
    leaksOnCeiling?: boolean;
    kitchenStove?: string;
    plumbingCondition?: string;
    kitchenArrangement?: string;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    doors?: string;
    necessaryRenovations?: string;
    currentUsing?: string;
    complianceLayout?: string;
    unapprovedLayout?: string;
    layoutChanges?: string;
    order: number;
    assessmentObject?: number | null;
    appraiser?: number | null;
    request?: number | null;
};
export type PhotoGroup = {
    modified?: string | null;
    name: string;
    inspection: number;
    assessmentObject?: number | null;
};
export type Photo = {
    modified?: string | null;
    image?: string | null;
    position: number;
    photoGroup?: number | null;
    file?: number | null;
};
export type PhotoRead = {
    id: number;
    created: string;
    modified?: string | null;
    image?: string | null;
    position: number;
    photoGroup?: number | null;
    file?: number | null;
};
export type PhotoGroupRead = {
    id: number;
    photos: PhotoRead[];
    created: string;
    modified?: string | null;
    name: string;
    inspection: number;
    assessmentObject?: number | null;
};
export type InspectionRead = {
    id: number;
    photoGroups: PhotoGroupRead[];
    created: string;
    modified?: string | null;
    address?: string;
    customer?: string;
    status?: InspectionStatusEnum;
    entranceCondition?: string;
    roofCondition?: string;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    visibleFinishingDefects?: string;
    windowView?: WindowViewEnum;
    leaksOnCeiling?: boolean;
    kitchenStove?: string;
    plumbingCondition?: string;
    kitchenArrangement?: string;
    floors?: string;
    ceiling?: string;
    walls?: string;
    windows?: string;
    doors?: string;
    necessaryRenovations?: string;
    currentUsing?: string;
    complianceLayout?: string;
    unapprovedLayout?: string;
    layoutChanges?: string;
    order: number;
    assessmentObject?: number | null;
    appraiser?: number | null;
    request?: number | null;
};
export type RetrieveOrderRead = {
    id: number;
    residentialComplex: ResidentialComplexRead;
    residentialComplexHouse: ResidentialComplexHouseRead;
    residentialComplexApartment: ResidentialComplexApartmentRead;
    legalDocuments: DocumentsGroupRead[];
    techDocuments: DocumentsGroupRead[];
    floorPlanBti: DocumentRead;
    selection: SelectionRead;
    report: ReportRead;
    bank: BankRead;
    inspection: InspectionRead;
    owners: OwnerRead[];
    created: string;
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
};
export type PatchedOrder = {
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    floorPlanBti?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
    legalDocuments?: number[];
    techDocuments?: number[];
};
export type PatchedOrderRead = {
    id?: number;
    created?: string;
    modified?: string | null;
    status?: StatusD4EEnum;
    amoCrmLeadId?: number | null;
    customerFio?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssueDate?: string | null;
    passportIssuer?: string | null;
    passportDepartmentCode?: string | null;
    birthday?: string | null;
    registrationAddress?: string | null;
    email?: string | null;
    phone?: string | null;
    customerConsent?: boolean;
    encumbrances?: boolean;
    encumbrancesType?: string;
    ooType?: OoTypeEnum;
    location?: string | null;
    builtYear?: number | null;
    dadata?: any | null;
    residentialComplexName?: string | null;
    address?: string | null;
    street?: string;
    houseNumber?: string;
    houseFiasId?: string | null;
    district?: string | null;
    nearestMetroStation?: string | null;
    nearestMetroStationDistance?: number | null;
    groundTransportStop?: string | null;
    groundTransportStopDistance?: number | null;
    locality?: string | null;
    subjectRf?: string | null;
    kadastrNumber?: string | null;
    countOfApartmentsOnFloor?: number | null;
    apartment?: number | null;
    roomsCount?: number | null;
    floorNumber?: number | null;
    houseFloors?: number | null;
    area?: number | null;
    summerRoomArea?: number | null;
    areaWithSummerRooms?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    separateKitchen?: boolean | null;
    utilityRoomsArea?: number | null;
    ceilingHeights?: number | null;
    countAndTypeOfRestrooms?: string | null;
    separateRestroom?: boolean | null;
    summerRoomsType?: SummerRoomsType34DEnum;
    layoutType?: (LayoutTypeEnum | BlankEnum | NullEnum) | null;
    buildingWeakness?: number | null;
    orderToStartWorkDate?: string | null;
    orderStartDate?: string | null;
    orderCompleteDate?: string | null;
    orderPurpose?: (OrderPurposeEnum | BlankEnum | NullEnum) | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    floorPlanBti?: number | null;
    evaluator?: number | null;
    assignTo?: number | null;
    legalDocuments?: number[];
    techDocuments?: number[];
};
export type OrdersStatsResponse = {
    completedCount: number;
    completedAvgTime: number;
};
export const {
    useCrmBanksListQuery,
    useLazyCrmBanksListQuery,
    useCrmBanksRetrieveQuery,
    useLazyCrmBanksRetrieveQuery,
    useCrmCitiesListQuery,
    useLazyCrmCitiesListQuery,
    useCrmCitiesRetrieveQuery,
    useLazyCrmCitiesRetrieveQuery,
    useCrmDocumentsListQuery,
    useLazyCrmDocumentsListQuery,
    useCrmDocumentsCreateMutation,
    useCrmDocumentsGroupsListQuery,
    useLazyCrmDocumentsGroupsListQuery,
    useCrmDocumentsGroupsCreateMutation,
    useCrmDocumentsGroupsRetrieveQuery,
    useLazyCrmDocumentsGroupsRetrieveQuery,
    useCrmDocumentsGroupsUpdateMutation,
    useCrmDocumentsGroupsPartialUpdateMutation,
    useCrmDocumentsGroupsDestroyMutation,
    useCrmDocumentsRetrieveQuery,
    useLazyCrmDocumentsRetrieveQuery,
    useCrmDocumentsUpdateMutation,
    useCrmDocumentsPartialUpdateMutation,
    useCrmDocumentsDestroyMutation,
    useCrmNewDocumentsListQuery,
    useLazyCrmNewDocumentsListQuery,
    useCrmNewDocumentsCreateMutation,
    useCrmNewDocumentsGroupsListQuery,
    useLazyCrmNewDocumentsGroupsListQuery,
    useCrmNewDocumentsGroupsCreateMutation,
    useCrmNewDocumentsGroupsRetrieveQuery,
    useLazyCrmNewDocumentsGroupsRetrieveQuery,
    useCrmNewDocumentsGroupsUpdateMutation,
    useCrmNewDocumentsGroupsPartialUpdateMutation,
    useCrmNewDocumentsGroupsDestroyMutation,
    useCrmNewDocumentsRetrieveQuery,
    useLazyCrmNewDocumentsRetrieveQuery,
    useCrmNewDocumentsUpdateMutation,
    useCrmNewDocumentsPartialUpdateMutation,
    useCrmNewDocumentsDestroyMutation,
    useCrmOrdersListQuery,
    useLazyCrmOrdersListQuery,
    useCrmOrdersCreateMutation,
    useCrmOrdersCustomerListQuery,
    useLazyCrmOrdersCustomerListQuery,
    useCrmOrdersCustomerCreateMutation,
    useCrmOrdersCustomerRetrieveQuery,
    useLazyCrmOrdersCustomerRetrieveQuery,
    useCrmOrdersCustomerUpdateMutation,
    useCrmOrdersCustomerPartialUpdateMutation,
    useCrmOrdersCustomerDestroyMutation,
    useCrmOrdersOwnersCreateMutation,
    useCrmOrdersOwnersUpdateMutation,
    useCrmOrdersOwnersPartialUpdateMutation,
    useCrmOrdersOwnersDestroyMutation,
    useCrmOrdersRetrieveQuery,
    useLazyCrmOrdersRetrieveQuery,
    useCrmOrdersUpdateMutation,
    useCrmOrdersPartialUpdateMutation,
    useCrmOrdersDestroyMutation,
    useCrmOrdersCancelCreateMutation,
    useCrmOrdersInspectionCreateMutation,
    useCrmOrdersLegalDocumentsListQuery,
    useLazyCrmOrdersLegalDocumentsListQuery,
    useCrmOrdersSelectionCreateMutation,
    useCrmOrdersStartCreateMutation,
    useCrmOrdersTechDocumentsListQuery,
    useLazyCrmOrdersTechDocumentsListQuery,
    useCrmOrdersNextRetrieveQuery,
    useLazyCrmOrdersNextRetrieveQuery,
    useCrmOrdersStatsRetrieveQuery,
    useLazyCrmOrdersStatsRetrieveQuery,
} = injectedRtkApi;
