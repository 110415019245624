enum ErrorType {
    REQUIRED = 'required',
    EMAIL = 'email',
    BIRTHDAY = 'birthday',
    DATEMAXNOW = 'dateMaxNow',
    COUNTSYMBOL = 'countSymbol',
    MIN = 'min',
}

export const ErrorMessage: Record<ErrorType, string> = {
    [ErrorType.REQUIRED]: 'Поле обязательное для заполнения',
    [ErrorType.EMAIL]: 'Введите корректное значение E-mail',
    [ErrorType.BIRTHDAY]:
        'Дата имеет неверный формат. Вместо этого используйте один из этих форматов: ГГГГ-ММ-ДД.',
    [ErrorType.DATEMAXNOW]: 'Дата не может быть больше чем {0}',
    [ErrorType.COUNTSYMBOL]:
        'Количество символов должно составлять: {0} символов',
    [ErrorType.MIN]: 'Минимальное значение {0}',
};
