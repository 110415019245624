import React from 'react';

import { CorrectedAnalog } from 'entities/selections';
import { classnames } from 'shared/lib/classnames';
import { WallTypesLabels } from 'shared/config/WallTypesLabels';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { SummerRoomsLabels } from 'shared/config/SummerRoomsLabels';

type AnalogsTableProps = {
    analogs: Array<CorrectedAnalog>;
};

const Correction: React.FC<{ correction: number; absolute?: boolean }> = ({
    correction,
    absolute = true,
}) => {
    return (
        <span
            className={classnames(
                correction > 0 ? 'text-green-500' : 'text-red-500'
            )}>
            {correction > 0 ? '+' : ''}
            {absolute ? (correction * 100).toFixed(1) : correction}
            {absolute ? '%' : 'р.'}
        </span>
    );
};

export const AnalogsTable: React.FC<AnalogsTableProps> = ({ analogs }) => {
    return (
        <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
                <tbody>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Адрес
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.address}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Источник
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap  w-64">
                                <a href={analog.advertisement.sourceUrl}>
                                    {analog.advertisement.sourceUrl}
                                </a>
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Контакт
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.phone}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Цена
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.price}
                                {analog.bargainingCorrection !== 0 && (
                                    <Correction
                                        correction={analog.bargainingCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Цена за кв. м.
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {(
                                    analog.advertisement.price /
                                    analog.advertisement.area
                                ).toFixed(0)}{' '}
                                \{' '}
                                <span
                                    className={classnames({
                                        'text-red-500':
                                            analog.advertisement.price /
                                                analog.advertisement.area >
                                            analog.correctedPricePerMeter,
                                        'text-green-500':
                                            analog.advertisement.price /
                                                analog.advertisement.area <=
                                            analog.correctedPricePerMeter,
                                    })}>
                                    {analog.correctedPricePerMeter.toFixed(0)}
                                </span>
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Площадь
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.area}{' '}
                                {analog.areaCorrection !== 0 && (
                                    <Correction
                                        correction={analog.areaCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Город
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.city}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Метро
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.metro}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Расстояние до метро
                        </th>

                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.kmDoMetro}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Тип стен
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {WallTypesLabels[analog.advertisement.wallType]}
                                {analog.wallTypeCorrection !== 0 && (
                                    <Correction
                                        correction={analog.wallTypeCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Этаж
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.floor}/
                                {analog.advertisement.floorsCount}
                                {analog.floorCorrection !== 0 && (
                                    <Correction
                                        correction={analog.floorCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Количество комнат
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.advertisement.roomsCount}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Ремонт
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {
                                    RenovationsLabels[
                                        analog.advertisement.renovationType
                                    ]
                                }
                                {analog.renovationTypeCorrection !== 0 && (
                                    <Correction
                                        correction={
                                            analog.renovationTypeCorrection
                                        }
                                        absolute={false}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Летние помещения
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {
                                    SummerRoomsLabels[
                                        analog.advertisement.summerRooms
                                    ]
                                }
                                {analog.summerRoomsCorrection !== 0 && (
                                    <Correction
                                        correction={
                                            analog.summerRoomsCorrection
                                        }
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
