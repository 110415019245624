import { FC, PropsWithChildren } from 'react';
import { Outlet, RouteProps } from 'react-router-dom';

export const InnerLayout: FC<PropsWithChildren<RouteProps>> = ({
    children,
}) => {
    return (
        <>
            <main>
                <div className="mx-auto max-w-7xl py-6 px-4">
                    <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                        <Outlet />
                        {children}
                    </div>
                </div>
            </main>
        </>
    );
};
