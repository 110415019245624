import { WallTypes } from '../types/buildings';

export const WallTypesLabels: Record<WallTypes, string> = {
    [WallTypes.UNKNOWN]: 'Неизвестно',
    [WallTypes.BRICK]: 'Кирпич',
    [WallTypes.PANEL]: 'Панель',
    [WallTypes.BLOCK]: 'Блок',
    [WallTypes.MONOLITH]: 'Монолит',
    [WallTypes.MONOLITH_BRICK]: 'Монолит-кирпич',
    [WallTypes.WOODEN]: 'Дерево',
};
