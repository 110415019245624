import { FC } from 'react';

import { NewApplication } from 'widgets/appraiser';
import { HistoryOrder } from 'widgets/historyOrder/';

interface AppraiserPageProps {}

export const AppraiserPage: FC<AppraiserPageProps> = () => {
    // const user =

    return (
        <main>
            <div className="mx-auto max-w-7xl mt-20 mb-10 px-4">
                <NewApplication />
                <HistoryOrder />
            </div>
        </main>
    );
};
