import { FC } from 'react';

interface SkeletonProps {}

const Skeleton: FC<SkeletonProps> = () => {
    return (
        <div>
            <div className="overflow-hidden shadow rounded-lg bg-white px-4 py-5 sm:p-6 animate-pulse">
                <div className="flex gap-4 items-center">
                    <div className="h-6 bg-gray-200 rounded w-1/2"></div>
                    <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                </div>
                <div>
                    <div className="h-5 bg-gray-200 rounded w-full mt-2"></div>
                    <div className="h-5 bg-gray-200 rounded w-full mt-2"></div>
                    <div className="h-5 bg-gray-200 rounded w-1/4 mt-2"></div>
                </div>
            </div>
            <div className="flex gap-4 pt-4 flex-col md:flex-row animate-pulse">
                <div className="h-10 bg-gray-200 rounded w-full"></div>
                <div className="h-10 bg-gray-200 rounded w-full mt-2 md:mt-0"></div>
            </div>
        </div>
    );
};

export default Skeleton;
