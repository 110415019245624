import { ErrorMessage } from 'app/const/ErrorMessage';
import { format as formatDate } from 'date-fns';
import { ru } from 'date-fns/locale';
import { format } from '../format';

type DateProp = Date | string | null | undefined;
export function validationDate(d1: DateProp, d2: DateProp) {
    let result = '';
    const timeMs1 = d1
        ? typeof d1 === 'string'
            ? new Date(d1).getTime()
            : d1.getTime()
        : 0;
    const timeMs2 = d2
        ? typeof d2 === 'string'
            ? new Date(d2).getTime()
            : d2.getTime()
        : 0;
    if (timeMs1 < timeMs2) {
        result = format(
            ErrorMessage.dateMaxNow,
            formatDate(timeMs1, 'dd.MM.yyyy', { locale: ru }),
        );
    }

    return result || true;
}
