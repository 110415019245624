export function getErrorMessage(error: unknown, title?: string): string {
    let message: string = '';

    if (error instanceof Error) {
        message = error.message;
    } else if (error && typeof error === 'object' && 'message' in error) {
        message = String(error.message);
    } else if (error && typeof error === 'object' && 'data' in error) {
        if (
            error.data &&
            typeof error.data === 'object' &&
            'detail' in error.data
        ) {
            message = String(error.data.detail);
        }
    } else if (typeof error === 'string') {
        message = error;
    } else {
        message = 'Произошла ошибка';
    }

    return title ? title + `\n${message}` : message;
}
