import React, { useCallback, useEffect, useState } from 'react';

import { classnames } from '../../lib/classnames';

export type TabsProps = {
    tabs: string[];
    content: any[];
    icons?: any[];
    onChange?: (idx: number) => void;
    current?: number;
    className?: string
};

export const Tabs: React.FC<TabsProps> = ({
                                              icons,
                                              tabs,
                                              content,
                                              onChange,
                                              current,
                                              className
                                          }) => {
    const [currentTabIdx, setCurrentTabIdx] = useState(0);
    const onChangeTab = useCallback(
        (idx: number) => {
            setCurrentTabIdx(idx);
            onChange && onChange(idx);
        },
        [onChange],
    );

    useEffect(() => {
        if (typeof current !== 'undefined') {
            setCurrentTabIdx(current);
        }
    }, [current]);
    return (
        <>
            <div className={`sm:gap-4 sm:py-6 ${className}`}>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        value={currentTabIdx}
                        onChange={(e) => onChangeTab(+e.target.value)}>
                        {tabs.map((tab, i) => (
                            <option key={i}>{tab}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                        {tabs.map((tab, i) => {
                            const Icon = icons && icons[i];
                            return (
                                <div
                                    key={i}
                                    className={classnames(
                                        i === currentTabIdx
                                            ? 'bg-gray-100 text-gray-700'
                                            : 'text-gray-500 hover:text-gray-700',
                                        'rounded-md px-3 py-2 text-sm font-medium cursor-pointer select-none',
                                    )}
                                    aria-current={
                                        i === currentTabIdx ? 'page' : undefined
                                    }
                                    onClick={() => onChangeTab(i)}>
                                    {Icon && (
                                        <Icon
                                            className={
                                                '-ml-0.5 mr-2 h-5 w-5 inline'
                                            }
                                        />
                                    )}
                                    {tab}
                                </div>
                            );
                        })}
                    </nav>
                </div>
            </div>
            {content[currentTabIdx]}
        </>
    );
};
