import { RetrieveOrderRead } from 'app/generatedApi/crm';
import { FC } from 'react';
import { getLocaleDate } from 'shared/lib/getLocaleDate';
import { DataItem, DataList } from 'shared/ui/DataList';

interface OrderListProps {
    order?: RetrieveOrderRead;
    isLoading?: boolean
}

export const OrderList: FC<OrderListProps> = ({ order, isLoading }) => {
    const orderInfo: DataItem[] = [
        {
            name: 'Дата заявки',
            value: order?.created && getLocaleDate(new Date(order.created)),
        },
        { name: 'Банк', value: order?.bank?.name },
    ];
    return <DataList items={orderInfo} isLoading={isLoading} />;
};
