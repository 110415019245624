import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { classnames } from 'shared/lib/classnames';
import { SkeletonPagination } from './SkeletonPagination';

interface PaginationProps {
    currentPage: number;
    totalItems?: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    isLoading?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
                                                          currentPage,
                                                          totalItems = 0,
                                                          itemsPerPage,
                                                          onPageChange,
                                                          isLoading,
                                                      }) => {
    if (isLoading) {
        return <SkeletonPagination />;
    }

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const hasPrevPage = currentPage > 1;
    const hasNextPage = currentPage < totalPages;

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                {hasPrevPage && (
                    <div
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer"
                        onClick={() => onPageChange(currentPage - 1)}>
                        <ChevronLeftIcon className="h-5 w-5" />
                        Предыдущая
                    </div>
                )}
                {hasNextPage && (
                    <div
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover-bg-gray-50 cursor-pointer"
                        onClick={() => onPageChange(currentPage + 1)}>
                        Следующая
                        <ChevronRightIcon className="h-5 w-5" />
                    </div>
                )}
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Показаны с{' '}
                        <span className="font-medium">
                            {Math.min(
                                (currentPage - 1) * itemsPerPage + 1,
                                totalItems,
                            )}
                        </span>{' '}
                        по{' '}
                        <span className="font-medium">
                            {Math.min(currentPage * itemsPerPage, totalItems)}
                        </span>{' '}
                        из <span className="font-medium">{totalItems}</span>
                    </p>
                </div>
                {totalPages > 1 ?
                    <div>
                        <div
                            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                            aria-label="Pagination">
                            {hasPrevPage && (
                                <div
                                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover-bg-gray-50 cursor-pointer"
                                    onClick={() => onPageChange(currentPage - 1)}>
                                    <ChevronLeftIcon className="h-5 w-5" />
                                    <span className="sr-only">Предыдущая</span>
                                </div>
                            )}
                            {Array.from({ length: totalPages }, (_, index) => (
                                <div
                                    key={index}
                                    className={classnames(
                                        `relative inline-flex items-center ${
                                            +currentPage === index + 1
                                                ? 'bg-indigo-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                                : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover-bg-gray-50 cursor-pointer hover:bg-gray-50 focus:z-20 focus:outline-offset-0 '
                                        } px-4 py-2 text-sm font-semibold`,
                                        {
                                            'rounded-l-md ': !hasPrevPage && !index,
                                            'rounded-r-md':
                                                !hasNextPage &&
                                                index + 1 === totalPages,
                                        },
                                    )}
                                    onClick={() => onPageChange(index + 1)}>
                                    {index + 1}
                                </div>
                            ))}
                            {hasNextPage && (
                                <div
                                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover-bg-gray-50 cursor-pointer"
                                    onClick={() => onPageChange(currentPage + 1)}>
                                    <span className="sr-only">Следующая</span>
                                    <ChevronRightIcon className="h-5 w-5" />
                                </div>
                            )}
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    );
};
