import React, { Children, FC, ReactNode } from 'react';
import { Badge, BadgeColor } from 'shared/ui/Badge';
import { Skeleton } from './Skeleton';

interface HeadingActionsProps {
    badge?: { title: string; color?: BadgeColor };
    title: string;
    children?: ReactNode;
    isLoading?: boolean;
}

export const HeadingActions: FC<HeadingActionsProps> = ({
    title,
    badge,
    children,
    isLoading,
}) => {
    return (
        <div className="bg-white pt-10 pb-6">
            <div className="mx-auto max-w-7xl">
                {isLoading ? (
                    <Skeleton title={title}>{children}</Skeleton>
                ) : (
                    <div className="px-4 sm:px-6 lg:px-8">
                        {badge && (
                            <div className={'mb-2'}>
                                <Badge color={badge.color}>{badge.title}</Badge>
                            </div>
                        )}
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-3xl font-semibold leading-6 text-gray-900 text-nowrap">
                                    {title}
                                </h1>
                            </div>
                            <div className="flex gap-4 mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                {Children.map(children, (child, index) => {
                                    if (React.isValidElement(child)) {
                                        return (
                                            <React.Fragment key={index}>
                                                {children}
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
