import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { FC, ChangeEvent, memo } from 'react';

interface SearchInputProps {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<SearchInputProps> = memo(({ onChange }) => {
    return (
        <div className="relative max-w-sm">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5" />
            </div>
            <input
                onInput={onChange}
                type="search"
                className="block w-full rounded-md p-2 ps-10 text-sm border-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Поиск"
                required
            />
        </div>
    );
});
