import { FC } from 'react';

import { DataList } from 'shared/ui/DataList';
import { SliderApp } from 'shared/ui/SliderApp';

import { OrderSelection } from '../module/types';

interface Display3Props {
    values: OrderSelection;
}

export const Display3: FC<Display3Props> = ({ values }) => {
    return (
        <div className="grid grid-cols-2 gap-16">
            <div>
                <h3 className="block mb-4 text-2xl font-semibold">Сведения об оценке</h3>
                <DataList items={values.selection} />
            </div>
            <div>
                <SliderApp slides={values.slides} imageNav={true} />
            </div>
        </div>
    );
};
