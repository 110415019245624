import React, { PropsWithChildren } from 'react';
import { classnames } from '../../lib/classnames';

const colorClasses = {
    gray: 'bg-gray-50 text-gray-700 ring-gray-700',
    red: 'bg-red-50 text-red-700 ring-red-600',
    yellow: 'bg-yellow-50 text-yellow-800 ring-yellow-600',
    green: 'bg-green-50 text-green-700 ring-green-600',
    blue: 'bg-blue-50 text-blue-700 ring-blue-700',
    indigo: 'bg-indigo-50 text-indigo-700 ring-indigo-700',
    purple: 'bg-purple-50 text-purple-700 ring-purple-700',
    pink: 'bg-pink-50 text-pink-700 ring-pink-700',
};

export type BadgeColor = keyof typeof colorClasses;

interface BadgeColorProps {
    color?: BadgeColor;
    className?: string;
}

export const Badge: React.FC<PropsWithChildren<BadgeColorProps>> = ({
    color = 'gray',
    className = '',
    children,
}) => {
    const colorClass = colorClasses[color] || colorClasses.gray;
    return (
        <span
            className={classnames(
                'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                colorClass,
                className,
            )}>
            {children}
        </span>
    );
};
