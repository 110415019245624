import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { RetrieveOrderRead } from 'app/generatedApi/crm';

import { OrderCustomer } from '../module/types';

export function prepareCustomer(order: RetrieveOrderRead) {
    const result: OrderCustomer = { customer: [], slides: [] };
    result.customer.push({ name: 'ФИО', value: order.customerFio });
    result.customer.push({
        name: 'Серия и номер паспорта',
        value: `${order.passportSeries} ${order.passportNumber}`,
    });
    result.customer.push({
        name: 'Дата рождения',
        value:
            order.birthday &&
            format(order.birthday, 'dd.MM.yyyy', { locale: ru }),
    });
    result.customer.push({ name: 'Кем выдан', value: order.passportIssuer });
    result.customer.push({
        name: 'Код органа',
        value: order.passportDepartmentCode,
    });
    result.customer.push({
        name: 'Дата выдачи',
        value: format(order.passportIssueDate || '', 'dd.MM.yyyy'),
    });

    const slides = order.legalDocuments.map((groupDoc) => {
        return groupDoc.documents.map((doc) => ({
            url: String(doc.file),
        }));
    });
    
    result.slides = slides.flat()

    return result;
}
