import { CheckIcon } from '@heroicons/react/20/solid';
import {
    DocumentPlusIcon,
    FolderPlusIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';
import { FieldValues } from 'react-hook-form';
import { difference } from 'lodash';

import { isDocumentReadType } from 'shared/lib/helpers';
import { htmlFor } from 'shared/lib/htmlFor';
import { Field, FieldProps } from 'shared/ui/Field';
import { SelectBase } from 'shared/ui/Select';
import { InputBase } from 'shared/ui/Input/ui/InputBase';
import { classnames } from 'shared/lib/classnames';
import { getErrorMessage } from 'shared/lib/getErrorMessage';
import { DnDDocumentItem } from './DnDDocumentItem';
import { Document, Group } from '../module/types';
import { useUploadFiles } from 'shared/lib/react/useUploadFiles';
import { useCrmNewDocumentsPartialUpdateMutation } from 'app/generatedApi/crm';

type GroupProps = {
    onDeleteGroup: (id: number) => Promise<void>;
    onRenameGroup: (id: number, newName: string) => Promise<void>;

    onDeleteFile: (id: number) => Promise<void>;
    onUploadFile: (
        id: number,
        group: number,
        position: number,
    ) => Promise<Document>;
    onMoveFileGroup?: (
        id: number,
        group: number,
        position: number,
    ) => Promise<Document | undefined>;
    onUpdatePositionFile: (
        id: number,
        position: number,
    ) => Promise<Document | undefined>;
};

interface IDocumentsGroup extends Group {
    documents: Document[];
}

export type DocumentsGroupProps<T extends FieldValues> = FieldProps<T> &
    GroupProps & {
        groups: IDocumentsGroup[];
        onAddNewGroup: () => Promise<Group>;
    };

const GroupComponent: React.FC<
    GroupProps & {
        group: IDocumentsGroup;
        groupIndex: number;
        groups: IDocumentsGroup[];
        setGroups: Dispatch<SetStateAction<IDocumentsGroup[]>>;
        formKey: string;
        acceptFiles?: string;
        isCropDocument?: boolean;
    }
> = ({
    formKey,
    group,
    groupIndex,
    groups,
    setGroups,
    onDeleteGroup,
    onRenameGroup,
    onUploadFile,
    onDeleteFile,
    onMoveFileGroup,
    onUpdatePositionFile,
    acceptFiles = '.docx, application/msword, application/pdf, image/*',
}) => {
    const [name, setName] = useState<string>(group.name);
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [deletingPhotosIds, setDeletingPhotosIds] = useState<number[]>([]);
    const [editName, setEditName] = useState<boolean>(false);
    const [uploadedFile] = useUploadFiles();
    const moveGroups = useMemo(() => {
        return groups
            .filter((item) => item.id !== group.id)
            .map((item) => ({
                name: item.name,
                value: String(item.id),
            }));
    }, [group.id, groups]);
    const isCheckMoveFile = Boolean(onMoveFileGroup && moveGroups.length);

    const uploadingFiles = useCallback(
        async (files: File[]) => {
            try {
                let lastIndexDocument = group.documents.length - 1;
                const result = await Promise.allSettled<string>(
                    files.map(async (file) => {
                        return new Promise<string>(async (resolve, reject) => {
                            try {
                                const result = await uploadedFile(file);
                                lastIndexDocument++;
                                const documentReady = await onUploadFile(
                                    result.id,
                                    group.id,
                                    lastIndexDocument,
                                );

                                setGroups((groups) =>
                                    groups.map((group, j) => {
                                        if (groupIndex === j) {
                                            return {
                                                ...group,
                                                documents: [
                                                    ...group.documents,
                                                    documentReady,
                                                ],
                                            };
                                        }
                                        return group;
                                    }),
                                );
                                resolve('');
                            } catch (error) {
                                reject(file.name);
                            }
                        });
                    }),
                );
                const failFiles: string[] = [];

                for (let value of result) {
                    if (value.status === 'rejected') {
                        failFiles.push(value.reason);
                    }
                }

                if (failFiles.length) {
                    alert(
                        `Не удалось загрузить файлы:\n${failFiles.filter((file) => !!file).join(', ')}`,
                    );
                }
            } finally {
                setUploading(false);
            }
        },
        [
            group.documents.length,
            group.id,
            groupIndex,
            onUploadFile,
            setGroups,
            uploadedFile,
        ],
    );

    const [updateDocument] = useCrmNewDocumentsPartialUpdateMutation();

    const handleImageChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setUploading(true);
            const files = Array.from(e.target.files || []);
            uploadingFiles(files);
        },
        [uploadingFiles],
    );

    const handleImageDelete = useCallback(
        async (index: number) => {
            try {
                setDeletingPhotosIds((ids) => [...ids, index]);
                const doc = group.documents[index];
                await onDeleteFile(doc.id);
                setGroups((groups) =>
                    groups.map((group, j) => {
                        if (groupIndex === j) {
                            return {
                                ...group,
                                documents: group.documents
                                    .filter((d) => {
                                        if (d.id === doc.id) {
                                            return false;
                                        } else {
                                            updateDocument({
                                                id: d.id,
                                                patchedDocument: {
                                                    position:
                                                        d.position &&
                                                        d.position - 1,
                                                },
                                            }).unwrap();
                                            return true;
                                        }
                                    })
                                    .map((d) => ({
                                        ...d,
                                        position: d.position && d.position - 1,
                                    })),
                            };
                        }
                        return group;
                    }),
                );
            } catch (error: unknown) {
                alert(
                    getErrorMessage(
                        error,
                        'Произошла ошибка при удалении фотографии',
                    ),
                );
            } finally {
                setDeletingPhotosIds((idxs) =>
                    idxs.filter((idx) => idx !== index),
                );
            }
        },
        [group.documents, groupIndex, onDeleteFile, setGroups, updateDocument],
    );

    const handleImageMove = useCallback(
        (doc: Document, index: number) => {
            if (!isCheckMoveFile) return;
            const newGroups = groups.map((group, j) => {
                if (groupIndex === j) {
                    return {
                        ...group,
                        documents: group.documents.map((photo, i) =>
                            i === index
                                ? { ...photo, isChecked: !photo.isChecked }
                                : { ...photo },
                        ),
                    };
                }
                return group;
            });
            setGroups(newGroups);
        },
        [groupIndex, groups, isCheckMoveFile, setGroups],
    );

    const handleOnMoveFile = useCallback(
        async (event: ChangeEvent<HTMLSelectElement>) => {
            try {
                setUploading(true);

                const idToMove = Number(event.target.value);
                const newGroups = [...groups];
                const selectedDocuments = group.documents.filter(
                    (doc) => doc.isChecked,
                );
                const notSelectedDocuments = difference(
                    group.documents,
                    selectedDocuments,
                );
                let lastPositionDocument =
                    newGroups[idToMove].documents.length - 1;
                const moveGroups = await Promise.all(
                    newGroups.map(async (group) =>
                        group.id === idToMove
                            ? {
                                  ...group,
                                  documents: [
                                      ...group.documents,
                                      ...(await Promise.all(
                                          selectedDocuments.map(async (doc) => {
                                              lastPositionDocument++;
                                              const newDoc =
                                                  await onMoveFileGroup?.(
                                                      doc.id,
                                                      idToMove,
                                                      lastPositionDocument,
                                                  );

                                              return newDoc
                                                  ? {
                                                        ...newDoc,
                                                        isChecked: false,
                                                    }
                                                  : doc;
                                          }),
                                      )),
                                  ],
                              }
                            : group,
                    ),
                );
                newGroups[groupIndex].documents = notSelectedDocuments;

                setGroups(moveGroups);
            } catch (error: unknown) {
                alert(
                    getErrorMessage(
                        error,
                        'Произошла ошибка при перемещении фотографий',
                    ),
                );
            } finally {
                event.target.value = '';
                setUploading(false);
            }
        },
        [group.documents, groupIndex, groups, onMoveFileGroup, setGroups],
    );

    const onFinishRename = useCallback(async () => {
        try {
            setEditing(true);
            await onRenameGroup(group.id, name);
            setEditing(false);
            setEditName(false);
        } catch (error: unknown) {
            alert(
                getErrorMessage(
                    error,
                    'Произошла ошибка при переименовании группы',
                ),
            );
        }
    }, [group.id, name, onRenameGroup]);

    const onDelete = useCallback(async () => {
        try {
            setDeleting(true);
            await onDeleteGroup(group.id);
        } catch (error: unknown) {
            alert(
                getErrorMessage(error, 'Произошла ошибка при удалении группы'),
            );
        } finally {
            setDeleting(false);
        }
    }, [group.id, onDeleteGroup]);

    const movePositionDocument = useCallback(
        async (fromPosition: number, toPosition: number) => {
            setGroups((groups) =>
                groups.map((g) =>
                    g.id === group.id
                        ? {
                              ...g,
                              documents: g.documents.map((doc, i) => {
                                  if (isDocumentReadType(doc)) {
                                      if (doc.position === fromPosition) {
                                          return {
                                              ...doc,
                                              position: toPosition,
                                          };
                                      }
                                      if (doc.position === toPosition) {
                                          return {
                                              ...doc,
                                              position: fromPosition,
                                          };
                                      }
                                  }
                                  return doc;
                              }),
                          }
                        : g,
                ),
            );
        },
        [group.id, setGroups],
    );

    return (
        <div className="mt-2">
            {editName ? (
                <div className="flex items-center gap-2 mb-2">
                    <InputBase
                        fieldClassName="mt-0"
                        size="s"
                        attrs={{
                            value: name,
                            onChange: (e) => setName(e.target.value),
                        }}
                        formKey=""
                        label=""
                    />
                    {editing ? (
                        <ArrowPathIcon className="h-5 w-5 mb-0.5 inline animate-spin" />
                    ) : (
                        <CheckIcon
                            className="h-6 w-6 mb-0.5 p-1 -mx-1 inline cursor-pointer text-green-600"
                            onClick={onFinishRename}
                        />
                    )}
                </div>
            ) : (
                <h4 className="text-l font-semibold mb-2">
                    <span className="mr-1" onClick={() => setEditName(true)}>
                        {name}
                    </span>
                    {deleting ? (
                        <ArrowPathIcon className="h-5 w-5 mb-0.5 inline animate-spin" />
                    ) : (
                        <TrashIcon
                            className="h-5 w-5 mb-0.5 inline cursor-pointer text-red-600"
                            onClick={onDelete}
                        />
                    )}
                </h4>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {[...group.documents]
                    .sort((a, b) => {
                        if (isDocumentReadType(a) && isDocumentReadType(b)) {
                            return Number(a.position) - Number(b.position);
                        }
                        return 0;
                    })
                    .map((doc, index) => (
                        <DnDDocumentItem
                            key={doc.id}
                            index={index}
                            doc={doc}
                            isCheckMoveFile={isCheckMoveFile}
                            handleImageMove={handleImageMove}
                            handleImageDelete={handleImageDelete}
                            uploadingFiles={uploadingFiles}
                            deletingPhotos={deletingPhotosIds}
                            movePositionDocument={movePositionDocument}
                            updatePosition={onUpdatePositionFile}
                        />
                    ))}
                <label
                    htmlFor={formKey + group.id}
                    className="relative bg-gray-100 h-32 flex flex-col justify-center items-center rounded-md cursor-pointer">
                    {uploading ? (
                        <ArrowPathIcon className="h-8 w-8 animate-spin text-gray-400" />
                    ) : (
                        <DocumentPlusIcon className="h-8 w-8 text-gray-400" />
                    )}
                    <input
                        type="file"
                        id={formKey + group.id}
                        accept={acceptFiles}
                        className="hidden"
                        multiple
                        onChange={handleImageChange}
                    />
                </label>
            </div>
            {isCheckMoveFile && (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <SelectBase
                        fieldClassName={classnames('mt-2', {
                            hidden: !group.documents.find(
                                (photo) => photo.isChecked,
                            ),
                        })}
                        size="s"
                        formKey=""
                        label="Перенести в группу"
                        options={moveGroups}
                        emptyValue={'Выберите группу'}
                        attrs={{ defaultValue: '', onChange: handleOnMoveFile }}
                    />
                </div>
            )}
        </div>
    );
};

export const DocumentsGroup = <T extends FieldValues>(
    props: DocumentsGroupProps<T>,
) => {
    const [groups, setGroups] = useState<IDocumentsGroup[]>([]);

    useEffect(() => {
        setGroups(props.groups);
    }, [props.groups]);
    const [creating, setCreating] = useState(false);

    const onAddNewGroups = useCallback(async () => {
        try {
            setCreating(true);
            const group = await props.onAddNewGroup();
            setGroups([...groups, group]);
        } catch (error: unknown) {
            alert(
                getErrorMessage(
                    error,
                    'Произошла ошибка добавлении новой группы',
                ),
            );
        } finally {
            setCreating(false);
        }
    }, [props, groups]);

    const onRenameGroup = useCallback(
        async (id: number, newName: string) => {
            await props.onRenameGroup(id, newName);
            setGroups((groups) => {
                return groups.map((g) =>
                    g.id === id ? { ...g, name: newName } : g,
                );
            });
        },
        [props],
    );

    const onDeleteGroup = useCallback(
        async (id: number) => {
            try {
                await props.onDeleteGroup(id);
                setGroups((groups) => {
                    return groups.filter((g) => g.id !== id);
                });
            } catch (error: any) {
                alert(
                    getErrorMessage(error, 'Произошла ошибка удаления группы'),
                );
            }
        },
        [props],
    );

    const renderedGroups = useMemo(() => {
        return groups.map((g, index) => (
            <GroupComponent
                key={g.id}
                group={g}
                groupIndex={index}
                groups={groups}
                setGroups={setGroups}
                formKey={htmlFor(props.formKey)}
                onRenameGroup={onRenameGroup}
                onDeleteGroup={onDeleteGroup}
                onDeleteFile={props.onDeleteFile}
                onUploadFile={props.onUploadFile}
                onMoveFileGroup={props.onMoveFileGroup}
                onUpdatePositionFile={props.onUpdatePositionFile}
            />
        ));
    }, [
        groups,
        props.formKey,
        props.onDeleteFile,
        props.onUploadFile,
        props.onMoveFileGroup,
        props.onUpdatePositionFile,
        onRenameGroup,
        onDeleteGroup,
    ]);

    return (
        <Field {...props}>
            <div className="block text-sm leading-6 text-gray-900">
                {renderedGroups}

                {/* New group */}
                <h4 className="cursor-pointer mt-2" onClick={onAddNewGroups}>
                    Добавить новую группу
                    {creating ? (
                        <ArrowPathIcon className="h-5 w-5 ml-1 mb-0.5 inline animate-spin" />
                    ) : (
                        <FolderPlusIcon className="h-5 w-5 ml-1 inline mb-0.5 text-green-600" />
                    )}
                </h4>
            </div>
        </Field>
    );
};
