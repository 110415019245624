import {FC} from "react"

interface SkeletonItemProps {}

export const SkeletonItem:FC<SkeletonItemProps> = () => {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="h-6 w-3/5 bg-gray-200 rounded"/>
      <dd className="h-6 w-3/4 col-span-2 bg-gray-200 rounded"/>
    </div>
  )
};