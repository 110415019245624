import { formatDistanceStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC, useEffect, useState, useCallback } from 'react';

import { Badge, BadgeColor } from 'shared/ui/Badge';

interface TimerProps {
    initDate: number | Date;
    onCompleted?: () => void;
    color?: BadgeColor
}

export const Timer: FC<TimerProps> = ({ initDate, onCompleted, color='yellow' }) => {
    const finishTime =
        typeof initDate === 'number' ? initDate : initDate.getTime();
    const [timeStamp, setTimeStamp] = useState(finishTime);

    const changeTimer = useCallback(() => {
        setTimeStamp((prev) => prev - 60000);
    }, []);

    useEffect(() => {
        const timerId = setInterval(changeTimer, 60000);
        return () => {
            clearInterval(timerId);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (finishTime < new Date().getTime()) {
            onCompleted && onCompleted();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeStamp]);
    return (
        <Badge color={color}>
            {`осталось ${formatDistanceStrict(new Date(), finishTime, {
                locale: ru,
                unit: 'minute',
            })}`}
        </Badge>
    );
};
