import { useEffect, DependencyList, useRef } from 'react';

export const useDebounceEffect = (
    fnc: () => void,
    deps: DependencyList,
    delay: number,
) => {
    const ref = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        clearTimeout(ref.current);
        ref.current = setTimeout(() => {
            fnc();
            clearTimeout(ref.current);
        }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fnc, ...deps, delay]);
};
