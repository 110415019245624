import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { classnames } from '../../../lib/classnames';

export type MultiComboboxProps = {
    values: string[];
    value?: string;
    onChange: (newValue: string) => void;
    onBlur: () => void;
    multiple?: boolean;
    ref?: React.LegacyRef<HTMLElement>;
    error?: FieldError;
};
export const MultiCombobox: React.FC<MultiComboboxProps> = React.forwardRef(
    ({ values, value = '', onChange, error }, ref) => {
        const [query, setQuery] = useState('');

        const onChangeCombo = (values: string[]) => {
            onChange(values.filter((v) => v).join(', '));
            setQuery('');
        };

        const selectedValues = value.split(', ').filter((v) => v);
        const availableValues = Array.from(
            new Set([...values, ...selectedValues]),
        );

        const filteredValues = useMemo(() => {
            return query === ''
                ? availableValues
                : availableValues.filter((value) =>
                      value
                          .toLowerCase()
                          .replace(/\s+/g, '')
                          .includes(query.toLowerCase().replace(/\s+/g, '')),
                  );
        }, [availableValues, query]);

        return (
            <>
                <Combobox
                    value={selectedValues}
                    onChange={onChangeCombo}
                    ref={ref}
                    multiple>
                    <div className="relative mt-2">
                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-600 sm:text-sm">
                            <Combobox.Input
                                className={classnames(
                                    'w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                                    {
                                        'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                                            !!error,
                                    },
                                )}
                                displayValue={(value: string[]) =>
                                    value.join(', ')
                                }
                                onChange={(event) =>
                                    setQuery(
                                        event.target.value
                                            .replace(value, '')
                                            .replace(', ', ''),
                                    )
                                }
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}>
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredValues.length ? (
                                    filteredValues.map((value) => (
                                        <Combobox.Option
                                            key={value}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-3 pr-9 list-none ${
                                                    active
                                                        ? 'bg-indigo-600 text-white'
                                                        : 'text-gray-900'
                                                }`
                                            }
                                            value={value}>
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${
                                                            selected
                                                                ? 'font-semibold'
                                                                : ''
                                                        }`}>
                                                        {value}
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                            className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-indigo-600'
                                                            }`}>
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                ) : query.length ? (
                                    <Combobox.Option
                                        value={query}
                                        className="relative cursor-pointer select-none py-2 pl-10 pr-4 list-none">
                                        Добавить "{query}"
                                    </Combobox.Option>
                                ) : null}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
                {error && (
                    <p className="mt-3 text-sm leading-6 text-red-600">
                        {error.type === 'required'
                            ? 'Это поле обязательное для заполнения'
                            : error.message}
                    </p>
                )}
            </>
        );
    },
);
