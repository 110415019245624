import { FC } from 'react';

interface SkeletonProps {
    height?: string | number;
    className?: string;
}

export const Skeleton: FC<SkeletonProps> = ({ height, className }) => {
    return (
        <div
            className={`${className} animate-pulse bg-gray-200`}
            style={{ height: `${height}px` }}></div>
    );
};
