export enum SummerRooms {
    UNKNOWN = -1,
    NO,
    BALCONY,
    LOGGIA,
}

export enum RenovationTypes {
    UNKNOWN = -1,
    NO,
    COSMETIC,
    EURO,
    DESIGN,
    PRECLEAN,
    ROUGH = 5,
}
export enum HouseType {
    UNKNOWN = -1,
    NEW = 1,
    RESALE,
}

export enum WindowsView {
    UNKNOWN = -1,
    YARD = 0,
    STREET = 1,
}

export enum WallTypes {
    UNKNOWN = 'unknown',
    BRICK = 'brick',
    PANEL = 'panel',
    BLOCK = 'block',
    MONOLITH = 'monolith',
    MONOLITH_BRICK = 'monolith_brick',
    WOODEN = 'wooden',
}

export type BuildingInfo = {
    id: number;
    regionId: string;
    areaId: any;
    cityId: any;
    streetId: string;
    shortnameRegion: string;
    formalnameRegion: string;
    shortnameArea: any;
    formalnameArea: any;
    shortnameCity: any;
    formalnameCity: any;
    shortnameStreet: string;
    formalnameStreet: string;
    houseNumber: string;
    building: any;
    block: any;
    letter: any;
    address: string;
    houseguid: string;
    managementOrganizationId: number;
    builtYear: number;
    exploitationStartYear: number;
    projectType: string;
    houseType: string;
    isAlarm: string;
    methodOfFormingOverhaulFund: string;
    floorCountMax: number;
    floorCountMin: number;
    entranceCount: number;
    elevatorsCount: number;
    energyEfficiency: string;
    quartersCount: number;
    livingQuartersCount: number;
    unlivingQuartersCount: number;
    areaTotal: number;
    areaResidential: number;
    areaNonResidential: number;
    areaCommonProperty: number;
    areaLand: number;
    parkingSquare: number;
    playground: number;
    sportsground: number;
    otherBeautification: string;
    foundationType: string;
    floorType: string;
    wallMaterial: string;
    basementArea: string;
    chuteType: string;
    chuteCount: number;
    electricalType: string;
    electricalEntriesCount: number;
    heatingType: string;
    hotWaterType: string;
    coldWaterType: string;
    sewerageType: string;
    sewerageCesspoolsVolume: number;
    gasType: string;
    ventilationType: string;
    firefightingType: string;
    drainageType: string;
};
