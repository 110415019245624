import {
    CloudArrowUpIcon,
    PaperClipIcon,
    PhotoIcon,
} from '@heroicons/react/24/solid';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FieldValues } from 'react-hook-form';
import { UploadFileRead } from 'app/generatedApi/files';
import { DocumentRead } from 'app/generatedApi/crm';
import { classnames as cn } from '../../lib/classnames';
import { htmlFor } from '../../lib/htmlFor';
import { Field, FieldChildrenProps } from '../Field';
import { Loader } from '../Loader/Loader';
import { Button, ButtonTheme } from '../Button/Button';
import { validationImage } from '../../lib/validation/validationImage';
import { useCropModalFile } from '../../lib/react/useCropModalFile';
import { useUploadFiles } from '../../lib/react/useUploadFiles';

export type DropFileProps<T extends FieldValues> = FieldChildrenProps<T> & {
    file?: UploadFileRead | DocumentRead;
    onUpload: (d: UploadFileRead) => void;
    onRemove: () => void;
};
function DropFileInner<T extends FieldValues>(
    {
        formKey,
        label,
        register,
        error,
        required,
        size,
        fieldClassName,
        file,
        onUpload,
        onRemove,
    }: DropFileProps<T>,
    ref?: React.Ref<HTMLInputElement> | null,
) {
    const [uploadedFile, setUploadedFile] = useState<
        UploadFileRead | DocumentRead | null
    >(null);
    useEffect(() => {
        if (file) {
            setUploadedFile(file);
        }
    }, [file]);

    const [newUpload, { isLoading }] = useUploadFiles();

    const onUploadFile = useCallback(
        async (file: File) => {
            if (uploadedFile) return;
            const result = await newUpload(file);

            onUpload(result);
            setUploadedFile(result);
        },
        [newUpload, onUpload, uploadedFile],
    );

    const remove = useCallback(() => {
        onRemove();
        setUploadedFile(null);
    }, [onRemove]);

    const { onShowCropModal, ModalCrop } = useCropModalFile(
        uploadedFile,
        onUploadFile,
    );

    const onManualUpload = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if ((e.currentTarget.files?.length ?? 0) > 0) {
                onShowCropModal(e);
            }
        },
        [onShowCropModal],
    );

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item: { files: File[] }) => {
                onUploadFile(item.files[0]);
            },
            collect: (monitor: DropTargetMonitor) => {
                return {
                    isOver: monitor.isOver(),
                };
            },
        }),
        [onUploadFile],
    );

    return (
        <>
            <Field
                formKey={formKey}
                label={label}
                fieldClassName={fieldClassName}
                size={size}
                required={required}
                error={error}>
                {uploadedFile ? (
                    <div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div className="flex w-0 flex-1 items-center">
                                    {validationImage(
                                        String(
                                            'originalName' in uploadedFile
                                                ? uploadedFile.originalName
                                                : uploadedFile.name,
                                        ),
                                    ) ? (
                                        <button
                                            onClick={onShowCropModal}
                                            type="button"
                                            className="flex-shrink-0 w-10 h-10"
                                            disabled={isLoading}>
                                            {uploadedFile.file && (
                                                <img
                                                    src={uploadedFile.file}
                                                    alt="Preview"
                                                    className="h-6 w-6 rounded-md object-contain"
                                                />
                                            )}
                                        </button>
                                    ) : (
                                        <PaperClipIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">
                                            {'originalName' in uploadedFile
                                                ? uploadedFile.originalName
                                                : uploadedFile.name}
                                        </span>
                                    </div>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                    {uploadedFile.file && (
                                        <a
                                            href={uploadedFile.file}
                                            target="_blank"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            rel="noreferrer">
                                            Просмотреть
                                        </a>
                                    )}
                                    <span
                                        className="text-gray-200"
                                        aria-hidden="true">
                                        |
                                    </span>
                                    <Button
                                        theme={ButtonTheme.OUTLINE}
                                        className="text-red-500 hover:text-red-300"
                                        onClick={remove}>
                                        Удалить
                                    </Button>
                                </div>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div
                        className={cn(
                            'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10',
                            {
                                'border-indigo-600 border-solid border-2':
                                    isOver,
                            },
                        )}
                        ref={drop}>
                        <div className="text-center">
                            {isOver ? (
                                <>
                                    <CloudArrowUpIcon
                                        className="mx-auto h-12 w-12 text-gray-300"
                                        aria-hidden="true"
                                    />
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600 mb-4">
                                        <p>Отпустите чтобы загрузить файл</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {isLoading ? (
                                        <Loader className="mx-auto !h-12 !w-12 text-gray-300" />
                                    ) : (
                                        <PhotoIcon
                                            className="mx-auto h-12 w-12 text-gray-300"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor={htmlFor(formKey)}
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                            <span>Загрузите файл</span>
                                            <input
                                                ref={ref}
                                                onChange={onManualUpload}
                                                name={htmlFor(formKey)}
                                                id={htmlFor(formKey)}
                                                type="file"
                                                className="sr-only"
                                            />
                                        </label>
                                        <p className="pl-1">
                                            или перетащите его сюда
                                        </p>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">
                                        PNG, JPG до 10 мегабайт
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Field>
            {ModalCrop}
        </>
    );
}

export const DropFile = React.forwardRef(DropFileInner);
