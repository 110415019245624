import { RetrieveOrderRead } from 'app/generatedApi/crm';

import { OrderCalculation } from '../module/types';

export function prepareCalculation(order: RetrieveOrderRead) {
    const result: OrderCalculation = {
        resultAssessment: [],
        buildFoMap: order.selection,
        analogs: order.selection?.correctedAnalogs,
    };

    const prices =
        order?.selection?.correctedAnalogs?.map(
            (a) => a.correctedPricePerMeter!
        ) ?? [];
    const pricePerMeterRange = `${Math.min(...prices)} - ${Math.max(...prices)}`;

    result.resultAssessment.push({
        name: 'Рыночная, руб.',
        value: order.selection?.priceFormatted,
    });
    result.resultAssessment.push({
        name: 'Ликвидационная, руб.',
        value: order.selection?.liquidationPriceFormatted,
    });
    result.resultAssessment.push({
        name: 'Средневзвешенная, 1 кв. м.',
        value: order.selection?.pricePerMeter,
    });
    result.resultAssessment.push({
        name: 'Диапазон аналогов, 1 кв. м.',
        value: pricePerMeterRange,
    });
    result.resultAssessment.push({
        name: 'Отношение самого дорогого аналога к самому дешевому',
        value: Math.round(Number(order.selection.ratio) * 100) / 100,
    });

    return result;
}
