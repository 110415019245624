//@ts-nocheck
export const signFile = function SignCreate(dataInBase64) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(
            function* (args) {
                const oStore = yield cadesplugin.CreateObjectAsync(
                    'CAdESCOM.Store'
                );
                yield oStore.Open(
                    cadesplugin.CADESCOM_CONTAINER_STORE,
                    cadesplugin.CAPICOM_MY_STORE,
                    cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
                );
                try {

                const oStoreCerts = yield oStore.Certificates;
                const oCertificate = yield oStoreCerts.Item(1);
                const oSigner = yield cadesplugin.CreateObjectAsync(
                    'CAdESCOM.CPSigner'
                );

                yield oSigner.propset_Certificate(oCertificate);
                yield oSigner.propset_CheckCertificate(true);

                const oSignedData = yield cadesplugin.CreateObjectAsync(
                    'CAdESCOM.CadesSignedData'
                );
                // Значение свойства ContentEncoding должно быть задано
                // до заполнения свойства Content
                yield oSignedData.propset_ContentEncoding(
                    cadesplugin.CADESCOM_BASE64_TO_BINARY
                );
                yield oSignedData.propset_Content(dataInBase64);

                var sSignedMessage = '';
                
                    sSignedMessage = yield oSignedData.SignCades(
                        oSigner,
                        cadesplugin.CADESCOM_CADES_BES,
                        true
                    );
                } catch (err) {
                    const e = cadesplugin.getLastError(err);
                    return args[1](e);
                }

                yield oStore.Close();
                return args[0](sSignedMessage);
            },
            resolve,
            reject
        );
    });
};