import React from 'react';

import { Advertisement } from 'entities/selections';
import { WallTypesLabels } from 'shared/config/WallTypesLabels';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { WindowsViewLabels } from 'shared/config/WindowsViewLabels';
import { SummerRoomsLabels } from 'shared/config/SummerRoomsLabels';

type AdvertisementTableProps = {
    advertisements: Array<Advertisement>;
};
export const AdvertisementTable: React.FC<AdvertisementTableProps> = ({
    advertisements,
}) => {
    return (
        <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
                <tbody>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Адрес
                        </th>
                        {advertisements.map((advertisement) => (
                            <td
                                key={advertisement.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {advertisement.address}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Источник
                        </th>
                        {advertisements.map((advertisement) => (
                            <td
                                key={advertisement.id}
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap  w-64">
                                <a href={advertisement.sourceUrl}>
                                    {advertisement.sourceUrl}
                                </a>
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Контакт
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.phone}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Цена
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.price} (
                                {(analog.price / analog.area).toFixed(0)})
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Площадь
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.area}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Город
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.city}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Метро
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.metro}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Расстояние до метро
                        </th>

                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.kmDoMetro}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Тип стен
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {WallTypesLabels[analog.wallType]}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Этаж
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.floor}/{analog.floorsCount}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Количество комнат
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {analog.roomsCount}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Ремонт
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {RenovationsLabels[analog.renovationType]}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Вид из окна
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {WindowsViewLabels[analog.windowsView]}
                            </td>
                        ))}
                    </tr>
                    <tr className="bg-white border-b">
                        <th className="px-2 text-xs text-gray-700 uppercase bg-gray-50 w-32 sticky left-0">
                            Летние помещения
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  w-64">
                                {SummerRoomsLabels[analog.summerRooms]}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
