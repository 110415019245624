/* eslint-disable @typescript-eslint/no-unused-vars */
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { RetrieveOrderRead } from 'app/generatedApi/crm';
import { OrderSelection } from '../module/types';

export function prepareSelection(order: RetrieveOrderRead) {
    const result: OrderSelection = { selection: [], slides: [] };

    result.selection.push({
        name: 'Дата осмотра',
        value: format(
            order.inspection.modified || order.inspection.created,
            'dd.MM.yyyy',
            { locale: ru },
        ),
    });
    result.selection.push({
        name: 'ФИО проводившего осмотр',
        value: order.inspection.customer,
    });

    const slides = order.inspection.photoGroups.map((photoGroup) => {
        return photoGroup.photos
            .filter((photo) => !!photo.image)
            .map((photo) => ({
                url: String(photo.image),
            }));
    });
    result.slides = slides.flat();

    return result;
}
