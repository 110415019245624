import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import saveAs from 'file-saver';
import { DocumentRead } from 'app/generatedApi/crm';
import { PhotoRead } from 'app/generatedApi/inspection';
import { getFileNameByPath } from '../../../lib/getFileNameByPath';
import { isDocumentReadType } from '../../../lib/helpers';
import { validationImage } from '../../../lib/validation/validationImage';
import { DialogApp, DialogSize } from '../../../ui/DialogApp/DialogApp';

interface FileItemProps {
    document: DocumentRead | PhotoRead;
}

export const FileItem: FC<FileItemProps> = ({ document }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const onClickDownload =
        (fileUrl: string, nameFile?: string | null) =>
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            saveAs(fileUrl, String(nameFile));
        };

    const onShowImage = (event: React.MouseEvent<HTMLImageElement>) => {
        setIsOpenModal(true);
    };

    return (
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
                {validationImage(
                    String(
                        isDocumentReadType(document)
                            ? document.file
                            : document.image,
                    ),
                ) ? (
                    <>
                        <img
                            src={String(
                                isDocumentReadType(document)
                                    ? document.file
                                    : document.image,
                            )}
                            alt="Preview"
                            className="w-16 h-16 object-contain rounded-md cursor-pointer"
                            onClick={onShowImage}
                        />
                        {createPortal(
                            <DialogApp
                                open={isOpenModal}
                                setOpen={setIsOpenModal}
                                size={DialogSize.LG}
                                isFooter={false}>
                                <img
                                    className="block object-contain h-full w-full"
                                    src={String(
                                        isDocumentReadType(document)
                                            ? document.file
                                            : document.image,
                                    )}
                                    alt=""
                                />
                            </DialogApp>,
                            window.document.body,
                        )}
                    </>
                ) : (
                    <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                )}
                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate font-medium">
                        {isDocumentReadType(document)
                            ? document.originalName
                            : getFileNameByPath(document.image)}
                    </span>
                    <span className="flex-shrink-0 text-gray-400">
                        {/* 2.4mb */}
                    </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                    {isDocumentReadType(document)
                        ? document.file && (
                              <a
                                  href={document.file}
                                  onClick={onClickDownload(
                                      document.file,
                                      document.originalName,
                                  )}
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                  download={document.originalName}>
                                  Скачать
                              </a>
                          )
                        : document.image && (
                              <a
                                  href={document.image}
                                  onClick={onClickDownload(
                                      document.image,
                                      document.image,
                                  )}
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                  download={document.image}>
                                  Скачать
                              </a>
                          )}
                </div>
            </div>
        </li>
    );
};
