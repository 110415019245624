import React, { TextareaHTMLAttributes } from 'react';
import { FieldValues } from 'react-hook-form';

import { htmlFor } from '../../lib/htmlFor';
import { FieldChildrenProps, Field } from '../Field';

export type TextareaProps<T extends FieldValues> = FieldChildrenProps<T> & {
    attrs?: TextareaHTMLAttributes<HTMLTextAreaElement>;
};
export const Textarea = <T extends FieldValues>({
    formKey,
    label,
    error,
    required,
    size,
    fieldClassName,
    isLoading,
    register,
    attrs,
}: TextareaProps<T>) => {
    return (
        <Field
            formKey={formKey}
            label={label}
            error={error}
            required={required}
            size={size}
            fieldClassName={fieldClassName}
            isLoading={isLoading}>
            <textarea
                {...register(formKey)}
                id={htmlFor(formKey)}
                {...attrs}
                rows={attrs?.rows || 3}
            />
        </Field>
    );
};
