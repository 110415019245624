import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppDispatch } from 'app/hooks';
import { logOut } from 'entities/auth/';

export const Logout: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        [localStorage, sessionStorage].forEach((s) =>
            s.removeItem('auth.token')
        );
        dispatch(logOut());
    }, [dispatch]);
    return <Navigate to={'/'} />;
};
