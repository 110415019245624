import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC } from 'react';

import { useCrmOrdersStatsRetrieveQuery } from 'app/generatedApi/crm';
import CardStatistic from 'shared/ui/CardStatistic/cardStatistic';
import { Loader } from 'shared/ui/Loader/Loader';

interface StatisticMonthProps {}

export const StatisticMonth: FC<StatisticMonthProps> = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const currentMount = format(firstDay, 'MMMM', {locale: ru});

    const { currentData: statistic, isLoading } = useCrmOrdersStatsRetrieveQuery({
        dateFrom: format(firstDay, 'yyyy-MM-dd'),
        dateTo: format(lastDay, 'yyyy-MM-dd'),
    });

    const avgTimeInMinutes =
        statistic?.completedAvgTime &&
        Math.round(statistic.completedAvgTime / 60);

    return (
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {/*{false ? (*/}
            {/*    <CardStatistic title="Новых в очереди">3</CardStatistic>*/}
            {/*) : (*/}
            {/*    <CardStatistic title="Новых заявок пока что нет" />*/}
            {/*)}*/}
            <CardStatistic title={`Выполнено в ${currentMount}`}>
                {isLoading ? <Loader /> : statistic?.completedCount}
            </CardStatistic>
            <CardStatistic title={`Среднее время в ${currentMount}`}>
                {isLoading ? (
                    <Loader />
                ) : (
                    avgTimeInMinutes && (
                        <>
                            {`${avgTimeInMinutes} `}
                            <span className="text-xl">мин</span>
                        </>
                    )
                )}
            </CardStatistic>
        </div>
    );
};
