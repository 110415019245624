import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

export enum ArrowTypeEnum {
    NEXT = "next",
    PREVIOUS = "prev"
}

export const ArrowType: Record<ArrowTypeEnum, ReactNode> = {
    [ArrowTypeEnum.NEXT]: <ArrowRightIcon className='h-5 w-5'/>,
    [ArrowTypeEnum.PREVIOUS]: <ArrowLeftIcon className='h-5 w-5'/>,
};
