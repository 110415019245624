import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'widgets/header';

const navigation = [
    { name: 'Заказы', href: '/applications' },
    { name: 'Осмотры', href: '/inspections?status=created' },
    { name: 'Подбор', href: '/selection' },
    { name: 'Отчеты', href: '/reports' },
    { name: 'ЛК Оценщик', href: '/appraiser' },
];
const userNavigation = [{ name: 'Выйти', href: '/logout' }];

export const BaseLayout: React.FC = () => {
    return (
        <>
            <div className="min-h-full">
                <Header menu={navigation} userMenu={userNavigation} />
                <Outlet />
            </div>
        </>
    );
};
