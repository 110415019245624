import { ArchiveBoxArrowDownIcon, PencilIcon } from '@heroicons/react/24/outline';
import React, { useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
    useCrmOrdersInspectionCreateMutation,
    useCrmOrdersPartialUpdateMutation,
    useCrmOrdersRetrieveQuery, useCrmOrdersSelectionCreateMutation,
} from 'app/generatedApi/crm';
import { ReportStatusesLabels, TableDocuments } from 'entities/reports';
import { SelectionList, SelectionStatusLabels } from 'entities/selections';
import { Badge } from 'shared/ui/Badge';
import { CustomerList, ObjectList, OrderList } from 'entities/appraisers';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import { Accordion } from 'shared/ui/Accordion';
import { OrderStatusLabels } from 'shared/config/OrderStatusLabels';
import { HeadingActions } from 'widgets/heading';
import { CameraIcon } from '@heroicons/react/24/outline';
import { CalculatorIcon } from '@heroicons/react/24/solid';

export const Application: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        data: order,
        isLoading,
        error,
    } = useCrmOrdersRetrieveQuery({ id: Number(id) });
    const [update, { isLoading: isUpdating }] =
        useCrmOrdersPartialUpdateMutation();

    const onClickArchive = async () => {
        if (id && order) {
            try {
                await update({
                    id: Number(id),
                    patchedOrder: { status: 'archived' },
                }).unwrap();

                navigate('/applications');
            } catch (e) {
            }
        }
    };

    const [createInspection] = useCrmOrdersInspectionCreateMutation();
    const [createSelection] = useCrmOrdersSelectionCreateMutation();

    const onInspectionCreate = useCallback(async () => {
        const { inspection } = await createInspection({ id: Number(id), retrieveOrder: {} }).unwrap();
        return navigate('/inspections/' + inspection.id + '/edit');
    }, [createInspection, id, navigate]);
    const onSelectionCreate = useCallback(async () => {
        const { selection } = await createSelection({ id: Number(id), retrieveOrder: {} }).unwrap();
        return navigate('/inspections/' + selection.id + '/edit');
    }, [createSelection, id, navigate]);

    if (error) {
        return <>{error}</>;
    }

    return (
        <>
            <HeadingActions
                title={id ? 'Заявка №' + id : 'Новая заявка'}
                isLoading={isLoading}
                badge={
                    order?.status && {
                        title: OrderStatusLabels[order.status],
                    }
                }>
                <>
                    {
                        order?.status === 'new' && !order?.inspection &&
                        <Button icon onClick={onInspectionCreate}>
                            <CameraIcon className="h-4 w-4" />
                            Осмотр
                        </Button>
                    }
                        {
                            ['inspection_done', 'selection_waiting'].includes(order?.status!) && !order?.selection &&
                            <Button icon onClick={onSelectionCreate}>
                                <CalculatorIcon className="h-4 w-4" />
                                Подбор
                            </Button>
                        }
                        {
                            order?.status === 'draft' ? (
                                <>
                                    <Link to={'edit'}>
                                        <Button icon>
                                            <PencilIcon className="h-5 w-5" />
                                            Редактировать
                                        </Button>
                                    </Link>
                                </>
                            ) : (
                                order?.status !== 'archived' && (
                                    <Button
                                        icon
                                        loading={isUpdating}
                                        onClick={() => onClickArchive()}
                                        theme={ButtonTheme.SECONDARY}>
                                        <ArchiveBoxArrowDownIcon className="h-5 w-5" />
                                        Архивировать
                                    </Button>
                                )
                            )}
                </>
            </HeadingActions>
            <div className="bg-white white mx-auto max-w-7xl px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                <div className="space-y-4 sm:space-y-4">
                    <Accordion title="Информация о заявке">
                        <OrderList order={order} isLoading={isLoading} />
                    </Accordion>
                    <Accordion title="Сведения о заказчике">
                        <CustomerList order={order} isLoading={isLoading} />
                    </Accordion>
                    <Accordion title="Сведения об объекте оценки">
                        <ObjectList order={order} isLoading={isLoading} />
                    </Accordion>
                    {order?.selection && (
                        <Accordion
                            title={() => (
                                <>
                                    Результат оценки
                                    <Badge className="ml-4 mr-auto">
                                        {
                                            SelectionStatusLabels[
                                                order.selection.status
                                                ]
                                        }
                                    </Badge>
                                </>
                            )}>
                            <SelectionList selection={order.selection} />
                        </Accordion>
                    )}
                    {order?.report && (
                        <Accordion
                            title={() => (
                                <>
                                    Отчеты
                                    {order.report.status && (
                                        <Badge className="ml-4 mr-auto">
                                            {order.report.status &&
                                                ReportStatusesLabels[
                                                    order.report.status
                                                    ]}
                                        </Badge>
                                    )}
                                </>
                            )}>
                            <TableDocuments report={order.report} />
                        </Accordion>
                    )}
                </div>
            </div>
        </>
    );
};
