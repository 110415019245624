import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Inspection as InspectionModel,
    useInspectionsRetrieveQuery,
    useInspectionsPartialUpdateMutation,
    useInspectionsPhotoGroupsCreateMutation,
    useInspectionsPhotoGroupsPartialUpdateMutation,
    useInspectionsPhotoGroupsDestroyMutation,
    useInspectionsPhotosDestroyMutation,
    useInspectionsPhotosPartialUpdateMutation,
    PhotoRead,
    PhotoGroupRead,
    useInspectionsPhotosCreateMutation,
} from 'app/generatedApi/inspection';
import { DocumentsGroup } from 'features/documents';
import { RenovationTypes, WindowsView } from 'shared/types/buildings';
import { Form } from 'shared/ui/Form/Form';
import { SelectForm } from 'shared/ui/Select';

import { WindowsViewLabels } from 'shared/config/WindowsViewLabels';
import { Field } from 'shared/ui/Field';
import { InputForm } from 'shared/ui/Input';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import { Combobox, MultiCombobox } from 'shared/ui/Combobox';
import { HeadingActions } from 'widgets/heading';
import { LinkApp } from 'shared/ui/LinkApp';
import { AddPropIsLoading } from 'shared/ui/AddPropIsLoading';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { getFileNameByPath } from 'shared/lib/getFileNameByPath';
import { Section } from 'shared/ui/Section';
import { useHandleErrors } from 'shared/lib/react/useHandleErrors';

type InspectionEditProps = {};

export const InspectionEdit: React.FC<InspectionEditProps> = () => {
    const { id: _id } = useParams<{ id: string }>();
    const id = useMemo(() => {
        return Number.parseInt(_id!);
    }, [_id]);

    const {
        currentData: inspection,
        isLoading,
        error,
    } = useInspectionsRetrieveQuery({
        id: id,
    });

    const {
        control,
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm<InspectionModel>({
        defaultValues: inspection,
    });

    const [
        updateInspection,
        { data: dataUpdate, error: errorUpdate, isLoading: isUpdating },
    ] = useInspectionsPartialUpdateMutation();

    useEffect(() => {
        reset(inspection);
    }, [inspection, reset]);

    useEffect(() => {
        reset(dataUpdate);
    }, [dataUpdate, reset]);

    const documentsGroup = useMemo(() => {
        const photoGroups = dataUpdate?.photoGroups || inspection?.photoGroups;
        return (
            photoGroups?.map((pg) => ({
                ...pg,
                documents: pg.photos.map((p, index) => ({
                    ...p,
                    file: p.image,
                    group: p.photoGroup,
                    originalName: getFileNameByPath(p.image),
                    position: index,
                })),
            })) ?? []
        );
    }, [dataUpdate?.photoGroups, inspection?.photoGroups]);

    const [createPhotoGroup] = useInspectionsPhotoGroupsCreateMutation();
    const [partialUpdatePhotoGroup] =
        useInspectionsPhotoGroupsPartialUpdateMutation();
    const [destroyPhotoGroup] = useInspectionsPhotoGroupsDestroyMutation();

    const onAddPhotoGroup = useCallback(async (): Promise<
        PhotoGroupRead & {
            documents: PhotoRead[];
        }
    > => {
        const group = await createPhotoGroup({
            photoGroup: { name: 'Новая группа', inspection: id },
        }).unwrap();

        return {
            ...group,
            documents: group.photos,
        };
    }, [createPhotoGroup, id]);

    const onRenamePhotoGroup = useCallback(
        async (id: number, name: string): Promise<void> => {
            await partialUpdatePhotoGroup({
                id,
                patchedPhotoGroup: { name },
            }).unwrap();
        },
        [partialUpdatePhotoGroup],
    );

    const onDeletePhotoGroup = useCallback(
        async (id: number) => {
            await destroyPhotoGroup({ id }).unwrap();
        },
        [destroyPhotoGroup],
    );

    const [destroyPhoto] = useInspectionsPhotosDestroyMutation();
    const [createPhoto] = useInspectionsPhotosCreateMutation();
    const [updatePhoto] = useInspectionsPhotosPartialUpdateMutation();

    const onUploadPhoto = useCallback(
        async (
            id: number,
            groupId: number,
            position: number,
        ): Promise<PhotoRead> => {
            return await createPhoto({
                photo: {
                    image: null,
                    photoGroup: groupId,
                    file: id,
                    position: position,
                },
            }).unwrap();
        },
        [createPhoto],
    );

    const onDeletePhoto = useCallback(
        async (id: number) => {
            await destroyPhoto({ id }).unwrap();
        },
        [destroyPhoto],
    );

    const onMovePhotoGroup = useCallback(
        async (id: number, group: number) => {
            return await updatePhoto({
                id,
                patchedPhoto: { photoGroup: group },
            }).unwrap();
        },
        [updatePhoto],
    );

    const onUpdatePositionFile = useCallback(
        async (id: number, position: number) => {
            return await updatePhoto({
                id,
                patchedPhoto: { position },
            }).unwrap();
        },
        [updatePhoto],
    );

    useHandleErrors<InspectionModel>(errorUpdate || error, setError, true);

    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (values: InspectionModel) => {
            try {
                await updateInspection({
                    id,
                    patchedInspection: {
                        ...values,
                        status: 'completed',
                    },
                }).unwrap();

                navigate(`/inspections/${id}/view`);
            } catch (error) {}
        },
        [id, navigate, updateInspection],
    );

    const onClickSave = handleSubmit(async (values: InspectionModel) => {
        try {
            await updateInspection({
                id,
                patchedInspection: values,
            }).unwrap();
        } catch (error) {}
    });

    return (
        <>
            <HeadingActions title={'Осмотр №' + id} />
            <div className="bg-white white mx-auto max-w-7xl px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                {inspection?.order && (
                    <LinkApp
                        to={`/applications/${inspection?.order}`}
                        className="font-bold">
                        {'<-'} К заявке
                    </LinkApp>
                )}
                <Form onSubmit={handleSubmit(onSubmit)} inProgress={isUpdating}>
                    <AddPropIsLoading isLoading={isLoading || isUpdating}>
                        <Section title={'Описание здания, подъезда'}>
                            <SelectForm
                                label={'Состояние подъезда'}
                                formKey={'entranceCondition'}
                                register={register}
                                options={[
                                    { name: 'Хорошее', value: 'Хорошее' },
                                    {
                                        name: 'Удовлетворительное',
                                        value: 'Удовлетворительное',
                                    },
                                    {
                                        name: 'Неудовлетворительное',
                                        value: 'Неудовлетворительное',
                                    },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.entranceCondition}
                                attrs={{
                                    ...register('entranceCondition', {}),
                                }}
                            />
                            <SelectForm
                                label={'Состояние подъезда'}
                                formKey={'roofCondition'}
                                register={register}
                                options={[
                                    { name: 'Хорошее', value: 'Хорошее' },
                                    {
                                        name: 'Удовлетворительное',
                                        value: 'Удовлетворительное',
                                    },
                                    {
                                        name: 'Неудовлетворительное',
                                        value: 'Неудовлетворительное',
                                    },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.roofCondition}
                                attrs={{
                                    ...register('roofCondition', {}),
                                }}
                            />
                        </Section>
                        <Section title={'Описание Объекта оценки'}>
                            <SelectForm
                                formKey={'visibleFinishingDefects'}
                                label={'Видимые дефекты отделки'}
                                register={register}
                                defaultValue={'Нет'}
                                options={[
                                    { name: 'Да', value: 'Да' },
                                    { name: 'Нет', value: 'Нет' },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.visibleFinishingDefects}
                                attrs={{
                                    ...register('visibleFinishingDefects', {}),
                                }}
                            />
                            <SelectForm
                                formKey={'windowView'}
                                label={'Вид из окна'}
                                register={register}
                                options={[
                                    WindowsView.YARD,
                                    WindowsView.STREET,
                                ].map((a) => ({
                                    value: a.toString(),
                                    name: WindowsViewLabels[a],
                                }))}
                                emptyValue={'Выберите значение'}
                                error={errors.windowView}
                                attrs={{
                                    ...register('windowView', {}),
                                }}
                            />
                            <SelectForm
                                formKey={'leaksOnCeiling'}
                                label={'Наличие следов протечек на потолке'}
                                register={register}
                                defaultValue={'Нет'}
                                options={[
                                    { name: 'Да', value: 'true' },
                                    { name: 'Нет', value: 'false' },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.leaksOnCeiling}
                                attrs={{
                                    ...register('leaksOnCeiling', {}),
                                }}
                            />
                        </Section>
                        <Section title={'Описание инженерного оборудования'}>
                            <SelectForm
                                formKey={'kitchenStove'}
                                label={'Кухонная плита'}
                                register={register}
                                options={[
                                    {
                                        name: 'Не установлена',
                                        value: 'Не установлена',
                                    },
                                    { name: 'Газовая', value: 'Газовая' },
                                    {
                                        name: 'Электрическая',
                                        value: 'Электрическая',
                                    },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.kitchenStove}
                                attrs={{
                                    ...register('kitchenStove', {}),
                                }}
                            />
                            <SelectForm
                                formKey={'plumbingCondition'}
                                label={'Сантехника/состояние'}
                                register={register}
                                options={[
                                    {
                                        name: 'Не установлена',
                                        value: 'Не установлена',
                                    },
                                    {
                                        name: 'Установлена/хорошее',
                                        value: 'Установлена/хорошее',
                                    },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.plumbingCondition}
                                attrs={{
                                    ...register('plumbingCondition', {}),
                                }}
                            />
                            <SelectForm
                                formKey={'kitchenArrangement'}
                                label={'Обустройство кухни'}
                                register={register}
                                options={[
                                    {
                                        name: 'Не обустроена',
                                        value: 'Не обустроена',
                                    },
                                    { name: 'Стандарт', value: 'Стандарт' },
                                    { name: 'Встроенная', value: 'Встроенная' },
                                ]}
                                emptyValue={'Выберите значение'}
                                error={errors.kitchenArrangement}
                                attrs={{
                                    ...register('kitchenArrangement', {}),
                                }}
                            />
                        </Section>
                        <Section title={'Описание внутренней отделки'}>
                            <Field formKey={'floors'} label={'Полы'}>
                                <Controller
                                    control={control}
                                    name={'floors'}
                                    render={({ field }) => (
                                        <MultiCombobox
                                            {...field}
                                            values={'Керамическая плитка/Керамогранит, Ламинат, ПВХ-плитка, Линолеум, Стяжка, Без отделки'.split(
                                                ', ',
                                            )}
                                            error={errors.floors}
                                        />
                                    )}
                                />
                            </Field>
                            <Field formKey={'ceiling'} label={'Потолки'}>
                                <Controller
                                    control={control}
                                    name={'ceiling'}
                                    render={({ field }) => (
                                        <MultiCombobox
                                            {...field}
                                            values={'Натяжной, Окрашено, Из ГКЛ, Потолочная плитка, Без отделки'.split(
                                                ', ',
                                            )}
                                            error={errors.ceiling}
                                        />
                                    )}
                                />
                            </Field>
                            <Field formKey={'walls'} label={'Стены'}>
                                <Controller
                                    control={control}
                                    name={'walls'}
                                    render={({ field }) => (
                                        <MultiCombobox
                                            {...field}
                                            values={'Керамическая плитка/Керамогранит, Окрашено, Обои, Стеновые панели, Без отделки'.split(
                                                ', ',
                                            )}
                                            error={errors.walls}
                                        />
                                    )}
                                />
                            </Field>
                            <Field formKey={'windows'} label={'Окна'}>
                                <Controller
                                    control={control}
                                    name={'windows'}
                                    render={({ field }) => (
                                        <MultiCombobox
                                            {...field}
                                            values={'Пластиковые, Деревянные'.split(
                                                ', ',
                                            )}
                                            error={errors.windows}
                                        />
                                    )}
                                />
                            </Field>
                            <Field
                                formKey={'doors'}
                                label={'Входная дверь / внутренние двери'}>
                                <Controller
                                    control={control}
                                    name={'doors'}
                                    render={({ field }) => (
                                        <MultiCombobox
                                            {...field}
                                            values={'Металлическая / филенчатые, Металлическая / деревянные, Деревянная / филенчатые, Деревянная / деревянные филенчатые, Металлическая / не установлены'.split(
                                                ', ',
                                            )}
                                            error={errors.doors}
                                        />
                                    )}
                                />
                            </Field>
                            <SelectForm
                                formKey={'renovationType'}
                                label={'Общее состояние помещений'}
                                register={register}
                                options={[
                                    RenovationTypes.UNKNOWN,
                                    RenovationTypes.NO,
                                    RenovationTypes.ROUGH,
                                    RenovationTypes.PRECLEAN,
                                    RenovationTypes.COSMETIC,
                                    RenovationTypes.EURO,
                                    RenovationTypes.DESIGN,
                                ].map((a) => ({
                                    value: a.toString(),
                                    name: RenovationsLabels[a],
                                }))}
                                emptyValue={'Выберите значение'}
                                error={errors.renovationType}
                                attrs={{
                                    ...register('renovationType', {}),
                                }}
                            />
                            <Controller
                                control={control}
                                name={'necessaryRenovations'}
                                render={({ field }) => (
                                    <Combobox
                                        {...field}
                                        label="Необходимые ремонтные работы"
                                        className="col-span-3"
                                        isLoading={isLoading || isUpdating}
                                        error={errors.necessaryRenovations}
                                        isCustomOption
                                        options={[
                                            'Полный комплекс ремонтных работ',
                                            'Проведение ремонтных работ не требуется',
                                            'Требуется проведение косметического ремонта',
                                        ]}
                                    />
                                )}
                            />
                            <SelectForm
                                formKey={'currentUsing'}
                                label={'Текущее использование Объекта оценки'}
                                options={'В качестве объекта жилого фонда, В качестве объекта нежилого фонда, Не используется'
                                    .split(', ')
                                    .map((v) => ({
                                        value: v,
                                        name: v,
                                    }))}
                                register={register}
                                emptyValue={'Выберите значение'}
                                error={errors.currentUsing}
                                attrs={{
                                    ...register('currentUsing', {}),
                                }}
                            />
                        </Section>
                        <Section title={'Описание планировки'}>
                            <SelectForm
                                formKey={'complianceLayout'}
                                label={
                                    'Соответствие планировочных решений Объекта оценки указанным решениям в технической документации'
                                }
                                options={'Соответствует, Не соответствует'
                                    .split(', ')
                                    .map((v) => ({
                                        value: v,
                                        name: v,
                                    }))}
                                register={register}
                                emptyValue={'Выберите значение'}
                                error={errors.complianceLayout}
                                attrs={{
                                    ...register('complianceLayout', {}),
                                }}
                            />
                            <SelectForm
                                formKey={'unapprovedLayout'}
                                label={'Не разрешенная перепланировка'}
                                defaultValue={'Нет'}
                                options={'Да, Нет'.split(', ').map((v) => ({
                                    value: v,
                                    name: v,
                                }))}
                                register={register}
                                emptyValue={'Выберите значение'}
                                error={errors.unapprovedLayout}
                                attrs={{
                                    ...register('unapprovedLayout', {}),
                                }}
                            />
                            <InputForm
                                formKey={'layoutChanges'}
                                label={'Описание перепланировок'}
                                register={register}
                                error={errors.layoutChanges}
                                attrs={{
                                    ...register('layoutChanges', {}),
                                }}
                            />
                        </Section>
                        <Section title={'Фотографии объекта оценки'}>
                            <div className="col-span-full">
                                <DocumentsGroup
                                    formKey={'photoGroups'}
                                    label={'Фотографии'}
                                    onDeleteGroup={onDeletePhotoGroup}
                                    onRenameGroup={onRenamePhotoGroup}
                                    onDeleteFile={onDeletePhoto}
                                    onUploadFile={onUploadPhoto}
                                    onMoveFileGroup={onMovePhotoGroup}
                                    groups={documentsGroup}
                                    onAddNewGroup={onAddPhotoGroup}
                                    onUpdatePositionFile={onUpdatePositionFile}
                                />
                            </div>
                        </Section>

                        <div className="mt-6 flex items-center justify-start gap-x-6">
                            <Button
                                type={'submit'}
                                disabled={isLoading || isUpdating}>
                                Завершить осмотр
                            </Button>
                            <Button
                                theme={ButtonTheme.SECONDARY}
                                disabled={isLoading || isUpdating}
                                onClick={onClickSave}>
                                Сохранить
                            </Button>
                        </div>
                    </AddPropIsLoading>
                </Form>
            </div>
        </>
    );
};
