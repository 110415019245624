import React, {
    ChangeEvent,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';

import {
    OrderRead,
    StatusD4EEnum,
    useCrmOrdersListQuery,
} from 'app/generatedApi/crm';
import { Pagination } from 'features/pagination';
import { Table } from 'shared/ui/Table';
import { OrderStatusLabels } from 'shared/config/OrderStatusLabels';
import { LabelOrderStatus, OrderStatus } from 'entities/appraisers';
import { HeadingTabs } from 'widgets/heading';
import { useSaveUrlParams } from 'shared/lib/react/useSaveUrlParams';
import { Button } from 'shared/ui/Button/Button';
import { SearchInput } from 'features/search';
import { useDebounce } from 'shared/lib/react/useDebounce';
import { ErrorMessage } from 'shared/ui/ErrorMessage/ErrorMessage';
import { handleError } from 'shared/lib/handleError';

const pageSize = 10;

export const ApplicationsTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState<OrderStatus>('new');
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 250);
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [
            { name: 'status', value: filterStatus, setValue: setFilterStatus },
            { name: 'page', value: page, setValue: setPage },
        ],
        [filterStatus, page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });

    const {
        currentData: orders,
        isLoading,
        isFetching,
        error,
    } = useCrmOrdersListQuery({
        page,
        pageSize,
        status: filterStatus as StatusD4EEnum,
        query: debouncedQuery,
    });

    const totalsOrders = useRef<number>();
    const filterTabs = Object.entries(LabelOrderStatus).map(
        ([status, name]) => ({
            name,
            value: status,
        }),
    );

    const applicationsWithCustomerName = useMemo(
        () =>
            orders?.results?.map((a: OrderRead) => ({
                ...a,
                customerName: a.customerFio,
                statusLabel: OrderStatusLabels[a.status!],
                address: a.address,
            })) ?? [],
        [orders?.results],
    );

    const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        setFilterStatus('');
    }, []);

    if (orders?.count && totalsOrders.current !== orders?.count) {
        totalsOrders.current = orders.count;
    }

    if (error) {
        return (
            <ErrorMessage
                message={handleError(error)}
                className="py-8 bg-white"
            />
        );
    }

    return (
        <>
            <div className="bg-white pt-10 pb-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <HeadingTabs
                        namePage="Заказы"
                        actions={[
                            <Link key={'new'} to={'new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новая заявка
                                </Button>
                            </Link>,
                        ]}
                        tabs={filterTabs}
                        currentTab={filterStatus}
                        onChangeTab={(status) => {
                            setPage(1);
                            setFilterStatus(status);
                        }}
                    />
                </div>
                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    <SearchInput onChange={onSearch} />
                </div>
            </div>
            {orders?.count === 0 ? (
                <div className="bg-white px-4 py-12">
                    <div className="text-center">
                        <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Заказов нет
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Начните с создания нового заказа.
                        </p>
                        <div className="mt-6">
                            <Link to={'/applications/new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новая заявка
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <Table
                            isLoading={isFetching}
                            rows={applicationsWithCustomerName}
                            columns={[
                                { key: 'id', className: 'w-[1%]' },
                                { key: 'address' },
                                {
                                    key: 'customerName',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                                {
                                    key: 'statusLabel',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                            ]}
                            columnsName={['#', 'Адрес', 'Заказчик', 'Статус']}
                            onClick={(el) => navigate(`/applications/${el.id}`)}
                        />
                    </div>
                    <Pagination
                        isLoading={isLoading}
                        onPageChange={setPage}
                        totalItems={totalsOrders.current || orders?.count}
                        currentPage={page}
                        itemsPerPage={pageSize}
                    />
                </>
            )}
        </>
    );
};
