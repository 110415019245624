import { Circle, Map, Placemark, Polygon, YMaps } from '@pbe/react-yandex-maps';
import { FC, useEffect, useState } from 'react';

import { CorrectedAnalog } from 'entities/selections';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { AnalogsTable } from 'widgets/selection';
import { Polygon as GeoPolygon } from 'shared/lib/geo';
import { DataList } from 'shared/ui/DataList';
import { pointToCoords } from 'shared/lib/maps/pointToCoords';

import { OrderCalculation } from '../module/types';

interface Display4Props {
    value: OrderCalculation;
}

export const Display4: FC<Display4Props> = ({ value }) => {
    const [center, setCenter] = useState<[number, number]>([0, 0]);

    useEffect(() => {
        if (value.buildFoMap) {
            setCenter(pointToCoords(value.buildFoMap.location));
        }
    }, [value.buildFoMap]);
    return (
        <div>
            <div className="">
                <h3 className="block mb-3 text-2xl font-semibold">
                    Результат оценки стоимостей
                </h3>
                <DataList items={value.resultAssessment} />
            </div>
            <div className="mt-4">
                <h3 className="block mb-3 text-2xl font-semibold">
                    Карта аналогов и объекта оценки
                </h3>
                <YMaps>
                    <Map
                        height={500}
                        width="100%"
                        state={{ center: center, zoom: 15 }}>
                        <>
                            {value.buildFoMap.analogs.map((analog, index) => {
                                const isSelected =
                                    value.buildFoMap.correctedAnalogs.find(
                                        (a) => a.advertisement.id === analog.id
                                    );
                                return (
                                    <Placemark
                                        key={index}
                                        geometry={pointToCoords(
                                            analog.location!
                                        ).reverse()}
                                        options={{
                                            iconColor: isSelected
                                                ? 'green'
                                                : 'red',
                                            zIndex: isSelected ? 2 : 1,
                                        }}
                                    />
                                );
                            })}
                        </>
                        {value.buildFoMap.type === 'default' ? (
                            <>
                                <Placemark geometry={center} />
                                <Circle
                                    geometry={[
                                        center,
                                        value.buildFoMap.distance,
                                    ]}
                                    options={{ fill: false }}
                                />
                            </>
                        ) : value.buildFoMap.type === 'residential_complex' ? (
                            <Polygon
                                geometry={[
                                    GeoPolygon.parse(
                                        value.buildFoMap?.residentialComplex
                                            ?.polygon ?? ''
                                    ).coords.map((c) => [
                                        c.lat.toFixed(6),
                                        c.long.toFixed(6),
                                    ]),
                                ]}
                                options={{
                                    strokeColor: 'blue',
                                    opacity: 0.2,
                                    strokeWidth: 1,
                                }}
                            />
                        ) : null}
                    </Map>
                </YMaps>
            </div>
            <div className="mt-4">
                <h3 className="block mb-3 text-2xl font-semibold">
                    Таблица аналоги и корректировки
                </h3>
                <AnalogsTable
                    analogs={value.analogs as unknown as CorrectedAnalog[]}
                />
            </div>
        </div>
    );
};
