import { FC } from 'react';

import { TableTheme } from '../Table/Table';
import cls from '../../styles/Table.module.css';

interface SkeletonTableProps {
    theme?: TableTheme;
    countColumn?: number;
    countRow?: number;
}

const SkeletonTable: FC<SkeletonTableProps> = ({
    theme = TableTheme.DEFAULT,
    countColumn = 5,
    countRow = 10,
}) => {
    return (
        <div>
            <table className={`min-w-full animate-pulse ${cls[theme]}`}>
                <thead>
                    <tr>
                        {Array.from({ length: countColumn }, (_,i) => (
                            <th
                                key={i}
                                scope="col"
                                className="text-left text-sm font-semibold text-gray-900">
                                <div className="h-5 bg-gray-200 rounded w-2/3"></div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: countRow }, (_,i) => (
                        <tr key={i}>
                            {Array.from({ length: countColumn }, (_,j) => (
                                <td key={`${i}_${j}`} className="whitespace-nowrap !py-3.5 text-sm text-gray-900">
                                    <div className="h-6 bg-gray-200 rounded w-2/3 py"></div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SkeletonTable;
