import { FC } from 'react';

interface SkeletonOrderDetailProps {}

export const SkeletonOrderDetail: FC<SkeletonOrderDetailProps> = () => {
    return (
        <div className="animate-pulse">
            <div className="mb-8">
                <div className="flex gap-4 items-center mb-2">
                    <div className="w-1/6 h-9 bg-gray-200 rounded" />
                    <div className="w-2/12 h-6 bg-gray-200 rounded" />
                    <div className="w-1/12 h-6 bg-gray-200 rounded" />
                </div>
                <div>
                    <div className="w-1/6 h-4 mb-1 bg-gray-200 rounded" />
                    <div className="w-1/3 h-4 mb-1 bg-gray-200 rounded" />
                    <div className="w-1/4 h-4 bg-gray-200 rounded" />
                </div>
            </div>
            <div className="w-full">
                <div className="w-full h-20 bg-gray-200 rounded" />
            </div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-16">
                    <div>
                        <div className="h-8 w-1/3 mb-4 bg-gray-200 rounded" />
                        <div className="divide-y divide-gray-100">
                            {[...Array.from({ length: 6 })].map((_, i) => (
                                <div key={i} className="py-6">
                                    <div className="h-6 w-full bg-gray-200 rounded" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="h-96 w-fill bg-gray-200 rounded" />
                    </div>
                </div>
            </div>
        </div>
    );
};
