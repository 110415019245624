import { FC, useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { classnames } from '../../../../lib/classnames';
import { DialogApp, DialogSize } from '../../../../ui/DialogApp/DialogApp';

import { Slide } from './model/types';
import { defaultSettings, modalSettings, navSettings } from './settings';
import cls from './SliderApp.module.css';

interface SliderAppProps {
    slides?: Slide[];
    settings?: Settings;
    imageNav?: boolean;
    modal?: boolean;
}

export const SliderApp: FC<SliderAppProps> = ({
    slides = [],
    settings,
    imageNav = false,
    modal = false,
}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [navMain, setNavMain] = useState<Slider>();
    const [navSecondary, setNavSecondary] = useState<Slider>();
    const mainSliderRef = useRef<Slider>(null);
    const secondarySliderRef = useRef<Slider>(null);

    useEffect(() => {
        mainSliderRef.current && setNavMain(mainSliderRef.current);
        secondarySliderRef.current &&
            setNavSecondary(secondarySliderRef.current);
    }, []);

    if (!slides.length) {
        return null;
    }

    return (
        <>
            {slides.length <= 1 ? (
                <div className={cls.slide}>
                    <img
                        className={classnames(
                            'object-cover object-center w-full',
                            {
                                'cursor-pointer': modal,
                            }
                        )}
                        src={slides[0].url}
                        onClick={() => setIsOpenModal(true)}
                        alt="slide"
                    />
                </div>
            ) : (
                <>
                    <Slider
                        {...defaultSettings}
                        {...settings}
                        asNavFor={navSecondary}
                        ref={mainSliderRef}>
                        {slides.map((slide, index) => (
                            <div key={index} className={cls.slide}>
                                <img
                                    className={classnames(
                                        'object-cover object-center w-full',
                                        { 'cursor-pointer': modal }
                                    )}
                                    src={slide.url}
                                    onClick={() => setIsOpenModal(true)}
                                    alt="slide"
                                />
                            </div>
                        ))}
                    </Slider>
                    {imageNav && (
                        <Slider
                            className={cls.navSlider}
                            asNavFor={navMain}
                            ref={secondarySliderRef}
                            {...navSettings}>
                            {slides.map((slide, index) => (
                                <div key={index}>
                                    <img
                                        className="block object-contain h-full"
                                        src={slide.url}
                                        alt="nav_slide"
                                    />
                                </div>
                            ))}
                        </Slider>
                    )}
                </>
            )}

            {modal && (
                <DialogApp
                    open={isOpenModal}
                    setOpen={setIsOpenModal}
                    isFooter={false}
                    size={DialogSize.LG}>
                    {slides.length <= 1 ? (
                        <img
                            className="block object-contain h-full w-full"
                            src={slides[0].url}
                            alt="modal-slide"
                        />
                    ) : (
                        <Slider asNavFor={navMain} {...modalSettings}>
                            {slides.map((slide, index) => (
                                <div key={index} className="mt-10">
                                    <img
                                        className="block object-contain h-full w-full"
                                        src={slide.url}
                                        alt="modal-slide"
                                    />
                                </div>
                            ))}
                        </Slider>
                    )}
                </DialogApp>
            )}
        </>
    );
};
