import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ReportRead } from 'app/generatedApi/reports';
import { FC } from 'react';
import { LinkApp } from 'shared/ui/LinkApp';
import { Table } from 'shared/ui/Table';
import { ReportStatusesLabels } from '../model/consts';

interface TableDocumentsProps {
    report: ReportRead;
}

export const TableDocuments: FC<TableDocumentsProps> = ({ report }) => {
    return (
        <Table
            rows={[report].map((r) => ({
                ...r,
                status: r.status && ReportStatusesLabels[r.status],
                appraiserName: r.appraiser,
            }))}
            columns={[
                { key: 'id' },
                { key: 'appraiserName' },
                { key: 'status' },
            ]}
            columnsName={['#', 'Оценщик', 'Статус']}>
            {(report) => (
                <>
                    {report.document ? (
                        <LinkApp
                            to={report.document}
                            target="_blank"
                            className="block">
                            <ArrowDownTrayIcon className="h-5 w-5 inline mr-1.5" />
                            Скачать
                        </LinkApp>
                    ) : // <a
                    null}
                    <LinkApp to={'/reports/' + report.id}>Редактировать</LinkApp>
                    <div className="text-red-600 hover:text-red-900 cursor-pointer">
                        Удалить
                    </div>
                </>
            )}
        </Table>
    );
};
