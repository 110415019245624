import { FC, useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { signFile } from 'features/signFile';
import { Timer } from 'features/timer';
import { useCrmOrdersRetrieveQuery } from 'app/generatedApi/crm';

import { handleError } from 'shared/lib/handleError';
import { Badge } from 'shared/ui/Badge';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import { DialogApp, DialogSize } from 'shared/ui/DialogApp/DialogApp';
import { ErrorMessage } from 'shared/ui/ErrorMessage/ErrorMessage';
import { Step, StepperApp } from 'shared/ui/StepperApp/StepperApp';
import { Textarea } from 'shared/ui/Textarea/Textarea';
import { OrderStatusLabels } from 'shared/config/OrderStatusLabels';
import { readToBase64 } from 'shared/lib/readToBase64';

import { prepareCalculation } from './lib/prepareCalculation';
import { prepareCustomer } from './lib/prepareCustomer';
import { prepareDataSign } from './lib/prepareDataSign';
import { prepareObjectBuild } from './lib/prepareObjectBuild';
import { prepareSelection } from './lib/prepareSelection';
import { Display1 } from './ui/Display1';
import { Display2 } from './ui/Display2';
import { Display3 } from './ui/Display3';
import { Display4 } from './ui/Display4';
import { Display5 } from './ui/Display5';
import { SkeletonOrderDetail } from './ui/SkeletonOrderDetail';
import { useReportFileSignMutation } from 'app/generatedApi/patch';

interface OrderDetailProps {}

export const OrderDetail: FC<OrderDetailProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { id } = useParams();
    const {
        data: order,
        isLoading,
        refetch: refetchOrder,
        error,
    } = useCrmOrdersRetrieveQuery({ id: Number(id) }, {});

    const [sign] = useReportFileSignMutation();

    const steps = useRef<Step[]>();
    const { register, handleSubmit, reset } = useForm();

    const handleRefetchOrder = useCallback(async () => {
        await refetchOrder();
    }, [refetchOrder]);

    const submitErrorMessage = async () => {
        reset();
        setIsOpen(false);
    };

    const onSignReport = useCallback(async () => {
        if (!order?.report?.document) return;

        const blobFile = await fetch(order.report.document).then((response) =>
            response.blob(),
        );
        const fileBase64Data = await readToBase64(blobFile);
        const signedFile = await signFile(fileBase64Data);
        const signedFileBlob = new Blob([signedFile as BlobPart]);
        const formData = new FormData();
        formData.set('id', order?.report?.id.toString());
        formData.set('file', signedFileBlob, `report_${order?.report?.id}.pdf`);
        await sign(formData).unwrap();
    }, [order?.report?.document, order?.report?.id, sign]);

    if (order) {
        steps.current = [
            {
                name: 'Заказчик',
                content: <Display1 values={prepareCustomer(order)} />,
            },
            {
                name: 'Объект',
                content: <Display2 values={prepareObjectBuild(order)} />,
            },
            {
                name: 'Фото',
                content: <Display3 values={prepareSelection(order)} />,
            },
            {
                name: 'Расчет',
                content: <Display4 value={prepareCalculation(order)} />,
            },
            {
                name: 'Подписание',
                content: (
                    <Display5
                        id={String(order.id)}
                        value={prepareDataSign(order)}
                        refetchOrder={handleRefetchOrder}
                    />
                ),
            },
        ];
    }
    if (error) {
        return <ErrorMessage message={handleError(error)} />;
    }

    return (
        <>
            <ErrorBoundary fallback={<ErrorMessage message="Error" />}>
                {isLoading ? (
                    <SkeletonOrderDetail />
                ) : (
                    <>
                        <div className="mb-8">
                            <div className="flex gap-4 items-center mb-2">
                                <h1 className="text-3xl font-semibold leading-6 text-gray-900 text-nowrap">
                                    {`Заявка № ${order?.id} `}
                                    {order?.orderStartDate &&
                                        format(
                                            order.orderStartDate,
                                            'dd.MM.yyyy',
                                            {
                                                locale: ru,
                                            },
                                        )}
                                </h1>
                                {order?.orderToStartWorkDate && (
                                    <Timer
                                        color="green"
                                        initDate={
                                            new Date(
                                                order?.orderToStartWorkDate,
                                            )
                                        }
                                    />
                                )}
                                <Badge color="gray">
                                    {order?.status &&
                                        OrderStatusLabels[order.status]}
                                </Badge>
                            </div>
                            <div>
                                <span className="text-base block">
                                    {order?.floorNumber &&
                                        `${order?.floorNumber}-комнатная квартира`}
                                </span>
                                <span className="text-base block">
                                    {order?.address}
                                </span>
                                <span className="text-base block">
                                    {order?.selection?.priceFormatted} руб.
                                </span>
                            </div>
                        </div>
                        <StepperApp
                            steps={steps.current}
                            textButtonNext="Проверено"
                            textButtonFinish="Подписать"
                            actionInFinish={onSignReport}
                            finishButton={<Button>Завершить заявку</Button>}
                            footerButton={
                                <Button
                                    theme={ButtonTheme.DANGER}
                                    onClick={() => setIsOpen(true)}>
                                    Сообщить об ошибке
                                </Button>
                            }
                        />
                        <DialogApp
                            open={isOpen}
                            setOpen={setIsOpen}
                            title="Сообщить об ошибке"
                            size={DialogSize.MD}
                            actionSuccess={
                                <Button
                                    type="submit"
                                    onClick={handleSubmit(submitErrorMessage)}>
                                    Отправить
                                </Button>
                            }>
                            <form onSubmit={handleSubmit(submitErrorMessage)}>
                                <Textarea
                                    register={register}
                                    label=""
                                    formKey="errorMessage"
                                />
                            </form>
                        </DialogApp>
                    </>
                )}
            </ErrorBoundary>
        </>
    );
};
