import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, Fragment, useRef, ReactNode } from 'react';

import { classnames } from '../../lib/classnames';
import { Button, ButtonTheme } from '../../ui/Button/Button';

import cls from './DialogApp.module.css';

export enum DialogSize {
    SM = 'size-sm',
    MD = 'size-md',
    LG = 'size-lg',
    FULL = 'size-full',
}

interface DialogAppProps {
    open?: boolean;
    setOpen?: (arg: boolean) => void;
    title?: string;
    size?: DialogSize;
    isFooter?: boolean;
    actionSuccess?: ReactNode;
    children?: ReactNode;
}

export const DialogApp: FC<DialogAppProps> = ({
    open = false,
    setOpen = () => {},
    title,
    size = DialogSize.SM,
    isFooter = true,
    actionSuccess,
    children,
}) => {
    const closeBtnRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-20"
                initialFocus={closeBtnRef}
                onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className={classnames(
                                    cls.dialogContent,
                                    cls[size]
                                )}>
                                <div className="absolute right-0 top-0 hidden pr-5 pt-6 z-10 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setOpen(false)}>
                                        <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                {title && (
                                    <h3 className={cls.title}>{title}</h3>
                                )}
                                <div>{children}</div>
                                {isFooter && (
                                    <div className="mt-5 flex justify-end gap-3 sm:mt-6">
                                        <Button
                                            theme={ButtonTheme.SECONDARY}
                                            onClick={() => setOpen(false)}
                                            ref={closeBtnRef}>
                                            Закрыть
                                        </Button>
                                        {actionSuccess}
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
