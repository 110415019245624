import { RetrieveOrderRead } from 'app/generatedApi/crm';
import { FC } from 'react';
import { getLocaleDate } from 'shared/lib/getLocaleDate';
import { DataList, DataItem } from 'shared/ui/DataList';

interface CustomerListProps {
    order?: RetrieveOrderRead;
    isLoading?: boolean;
}

export const CustomerList: FC<CustomerListProps> = ({ order, isLoading }) => {
    const orderInfo: DataItem[] = [
        { name: 'ФИО', value: order?.customerFio },

        { name: 'Номер телефона', value: order?.phone },
        { name: 'Почта', value: order?.email },
        {
            name: 'Серия и номер паспорта',
            value: `${order?.passportSeries} ${order?.passportNumber}`,
        },
        { name: 'Кем выдан', value: order?.passportIssuer },
        {
            name: 'Дата выдачи',
            value: getLocaleDate(order?.passportIssueDate),
        },
        { name: 'Адрес регистрации', value: order?.registrationAddress },
    ];
    return <DataList items={orderInfo} isLoading={isLoading} />;
};
