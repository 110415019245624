import React, { useCallback } from 'react';
import { useFormContext, Path } from 'react-hook-form';

import { Order, OwnerRead } from 'app/generatedApi/crm';
import { Button, ButtonSize, ButtonTheme } from 'shared/ui/Button/Button';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export type StepProps = React.PropsWithChildren & {
    onNext?: () => void;
    onPrev?: () => void;
    onSave?(): void;
    submit?: boolean;
    saveText?: string;
    saveDraftText?: string;
    validate?: Path<Order & { owners: OwnerRead[] }>[];
    validateOnNext?: boolean;
    isLoading?: boolean;
};
export const Step: React.FC<StepProps> = ({
    validate,
    validateOnNext,
    children,
    onPrev,
    onSave,
    onNext,
    submit,
    saveDraftText = 'Сохранить черновик',
    saveText = 'Сохранить',
}) => {
    const { trigger } = useFormContext<Order & { owners: OwnerRead[] }>();

    const onSaveWithTrigger = useCallback(async () => {
        if (validate) {
            return trigger(validate).then((v) => v && onSave && onSave());
        }
        return onSave && onSave();
    }, [onSave, trigger, validate]);

    const onNextWithValidate = useCallback(async () => {
        if (validate && validateOnNext) {
            return trigger(validate).then(
                (valid) => valid && onNext && onNext(),
            );
        }
        return onNext && onNext();
    }, [onNext, trigger, validate, validateOnNext]);
    return (
        <>
            <div className="space-y-6 sm:space-y-8">{children}</div>
            <div className="mt-6 flex items-center justify-start gap-x-6">
                {onPrev ? (
                    <Button
                        icon
                        theme={ButtonTheme.SECONDARY}
                        onClick={onPrev}
                        size={ButtonSize.MD}>
                        <ChevronLeftIcon className="h-5 w-5" />
                        Назад
                    </Button>
                ) : null}
                {onSave && (
                    <Button onClick={onSaveWithTrigger}>{saveDraftText}</Button>
                )}
                {submit && <Button type="submit">{saveText}</Button>}
                {onNext ? (
                    <Button
                        icon
                        theme={ButtonTheme.SECONDARY}
                        onClick={onNextWithValidate}>
                        Далее
                        <ChevronRightIcon className="h-5 w-5" />
                    </Button>
                ) : null}
            </div>
        </>
    );
};
