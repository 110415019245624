export function saveInStorage(
    name: string,
    value: string,
    isLocalStorage = false
) {
    if (isLocalStorage) {
        localStorage.setItem(name, value);
    } else {
        sessionStorage.setItem(name, value);
    }
}
