import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { authApi, authReducer } from 'entities/auth';
// eslint-disable-next-line import/order
import { adsApi } from 'entities/ads';
import { buildingsApi } from 'entities/buildings';
import { reportsApi } from 'entities/reports';
import { selectionsApi } from 'entities/selections';

import { generatedApi } from './emptyApi';

export const store = configureStore({
    reducer: {
        [generatedApi.reducerPath]: generatedApi.reducer,
        auth: authReducer,
        [authApi.reducerPath]: authApi.reducer,
        [buildingsApi.reducerPath]: buildingsApi.reducer,
        [adsApi.reducerPath]: adsApi.reducer,
        [selectionsApi.reducerPath]: selectionsApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        generatedApi.middleware,
        authApi.middleware,
        buildingsApi.middleware,
        adsApi.middleware,
        selectionsApi.middleware,
        reportsApi.middleware,
    ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
