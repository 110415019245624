import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';

import { setToken, logOut } from 'entities/auth';
import { saveInStorage } from 'shared/lib/saveInStorage';

import { RootState } from './store';

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT
    ? process.env.REACT_APP_SERVER_ENDPOINT
    : '/';
const baseUrlWithProtocol = baseUrl.startsWith('http')
    ? baseUrl
    : 'http://' + baseUrl;
const statusCodsRefresh = [401, 403];

type IBaseQuery<T extends BaseQueryFn<any, unknown, unknown, {}, {}> = any> =
    BaseQueryFn<
        string | FetchArgs,
        BaseQueryResult<T>,
        | FetchBaseQueryError
        | { status: number; data: { errorMessage: string } },
        {},
        FetchBaseQueryMeta
    >;

export const baseQueryToken: IBaseQuery = fetchBaseQuery({
    baseUrl: baseUrlWithProtocol,
    prepareHeaders: (headers, { getState, endpoint, extra }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set('x-authorization', `JWT ${token}`);
        }
        return headers;
    },
});

export const baseQuery: IBaseQuery = async (args, api, extraOptions) => {
    let result = await baseQueryToken(args, api, extraOptions);

    if (statusCodsRefresh.includes(Number(result.error?.status))) {
        const refreshResult = await baseQueryToken(
            {
                url: 'auth/token/refresh/',
                method: 'POST',
                body: {
                    token: result.meta?.request.headers
                        .get('x-authorization')
                        ?.split(' ')[1],
                },
            },
            api,
            extraOptions,
        );

        if (refreshResult.data) {
            const keepLoggedIn = Boolean(
                localStorage.getItem('auth.keepLoggedIn'),
            );
            api.dispatch(setToken(refreshResult.data.token));
            saveInStorage('auth.token', refreshResult.data.token, keepLoggedIn);

            result = await baseQueryToken(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
        }
    }

    return result;
};
