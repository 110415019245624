import { RenovationTypes } from '../types/buildings';

export const RenovationsLabels: Record<RenovationTypes, string> = {
    [RenovationTypes.UNKNOWN]: 'Неизвестно',
    [RenovationTypes.NO]: 'Без ремонта',
    [RenovationTypes.COSMETIC]: 'Косметический',
    [RenovationTypes.EURO]: 'Евро',
    [RenovationTypes.DESIGN]: 'Дизайнерский',
    [RenovationTypes.PRECLEAN]: 'Предчистовой',
    [RenovationTypes.ROUGH]: 'Черновой',
};
