import { generatedApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        reportsList: build.query<ReportsListApiResponse, ReportsListApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/reports/`,
                params: {
                    ordering: queryArg.ordering,
                    page: queryArg.page,
                    page_size: queryArg.pageSize,
                    status: queryArg.status,
                },
            }),
        }),
        reportsRetrieve: build.query<
            ReportsRetrieveApiResponse,
            ReportsRetrieveApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/reports/${queryArg.id}/` }),
        }),
        reportsGenerateCreate: build.mutation<
            ReportsGenerateCreateApiResponse,
            ReportsGenerateCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/reports/${queryArg.id}/generate/`,
                method: 'POST',
            }),
        }),
        reportsRefillCreate: build.mutation<
            ReportsRefillCreateApiResponse,
            ReportsRefillCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/reports/${queryArg.id}/refill/`,
                method: 'POST',
            }),
        }),
        reportsSignCreate: build.mutation<
            ReportsSignCreateApiResponse,
            ReportsSignCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/reports/${queryArg.id}/sign/`,
                method: 'POST',
                body: queryArg.report,
            }),
        }),
        reportsFilesCreate: build.mutation<
            ReportsFilesCreateApiResponse,
            ReportsFilesCreateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/reports/files/`,
                method: 'POST',
                body: queryArg.reportFile,
            }),
        }),
        reportsFilesRetrieve: build.query<
            ReportsFilesRetrieveApiResponse,
            ReportsFilesRetrieveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/reports/files/${queryArg.id}/`,
            }),
        }),
        reportsTemplatesList: build.query<
            ReportsTemplatesListApiResponse,
            ReportsTemplatesListApiArg
        >({
            query: () => ({ url: `/api/v1/reports/templates/` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type ReportsListApiResponse =
    /** status 200  */ PaginatedListReportListRead;
export type ReportsListApiArg = {
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A page number within the paginated result set. */
    page?: number;
    /** Number of results to return per page. */
    pageSize?: number;
    /** * `created` - Создан
     * `generating` - Генерируется
     * `generated` - Сгенерирован
     * `signed` - Подписан
     * `error` - Ошибка */
    status?: 'created' | 'error' | 'generated' | 'generating' | 'signed';
};
export type ReportsRetrieveApiResponse = /** status 200  */ ReportRead;
export type ReportsRetrieveApiArg = {
    /** A unique integer value identifying this report2. */
    id: number;
};
export type ReportsGenerateCreateApiResponse = /** status 200  */ ReportRead;
export type ReportsGenerateCreateApiArg = {
    /** A unique integer value identifying this report2. */
    id: number;
};
export type ReportsRefillCreateApiResponse = /** status 200  */ ReportRead;
export type ReportsRefillCreateApiArg = {
    /** A unique integer value identifying this report2. */
    id: number;
};
export type ReportsSignCreateApiResponse = /** status 200  */ ReportRead;
export type ReportsSignCreateApiArg = {
    /** A unique integer value identifying this report2. */
    id: number;
    report: Report;
};
export type ReportsFilesCreateApiResponse = /** status 201  */ ReportFileRead;
export type ReportsFilesCreateApiArg = {
    reportFile: ReportFile;
};
export type ReportsFilesRetrieveApiResponse = /** status 200  */ ReportFileRead;
export type ReportsFilesRetrieveApiArg = {
    /** A unique integer value identifying this report file. */
    id: number;
};
export type ReportsTemplatesListApiResponse = /** status 200  */ TemplateRead[];
export type ReportsTemplatesListApiArg = void;
export type Status456Enum =
    | 'created'
    | 'generating'
    | 'generated'
    | 'signed'
    | 'error';
export type ListReport = {
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    document?: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    selection: number;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type ListReportRead = {
    id: number;
    created: string;
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    document?: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    selection: number;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type PaginatedListReportList = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ListReport[];
};
export type PaginatedListReportListRead = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ListReportRead[];
};
export type Report = {
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type TypeCadEnum = 'default' | 'residential_complex';
export type RenovationTypeEnum = -1 | 0 | 1 | 2 | 3 | 4 | 5;
export type NewRenovationTypeEnum =
    | 'unknown'
    | 'none'
    | 'preclean'
    | 'average'
    | 'good'
    | 'euro'
    | 'premium';
export type SummerRoomsEnum = -1 | 0 | 1 | 2;
export type HouseTypeEnum = -1 | 1 | 2;
export type WallTypeEnum =
    | 'unknown'
    | 'brick'
    | 'panel'
    | 'block'
    | 'monolith'
    | 'wooden'
    | 'monolith_brick';
export type SelectionList = {
    modified?: string | null;
    type?: TypeCadEnum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type StatusA47Enum = 0 | 1 | 2 | 3;
export type SelectionListRead = {
    id: number;
    created: string;
    modified?: string | null;
    type?: TypeCadEnum;
    status: StatusA47Enum;
    error?: string;
    address: string;
    floor: number;
    roomsCount: number;
    area: number;
    renovationType?: RenovationTypeEnum;
    newRenovationType?: NewRenovationTypeEnum;
    summerRooms?: SummerRoomsEnum;
    maxFloor: number;
    houseType?: HouseTypeEnum;
    wallType?: WallTypeEnum;
    builtYear: number;
    location: string;
    step?: number;
    distance?: number;
    areaThreshold?: number;
    filters?: any | null;
    homogeneityRatio?: number;
    pricePerMeter?: number;
    price?: number;
    riskFreeRate?: number;
    discountRate?: number;
    lowLiquidityRiskCompensation?: number;
    moneyPriceCoefficient?: number;
    liquidationPrice?: number;
    order?: number | null;
    city?: number | null;
    assessmentObject?: number | null;
    residentialComplex?: number | null;
    residentialComplexHouse?: number | null;
    residentialComplexApartment?: number | null;
    building?: number | null;
    adsParsingRequest?: number | null;
    excludedAnalogs: number[];
};
export type ReportRead = {
    id: number;
    selection: SelectionListRead;
    created: string;
    modified?: string | null;
    status?: Status456Enum;
    error?: string | null;
    rawDocument?: string | null;
    document: string | null;
    signature?: string | null;
    mainMap?: string | null;
    analogsMap?: string | null;
    order: number;
    template?: number | null;
    city?: number | null;
    bank?: number | null;
    newCity?: number | null;
    newBank?: number | null;
    customer?: number | null;
    assessmentObject?: number | null;
    inspection?: number | null;
    evaluatingCompany?: number | null;
    signer?: number | null;
    appraiser?: number | null;
    form?: number | null;
};
export type ReportFile = {
    file: string;
};
export type ReportFileRead = {
    id: number;
    created: string;
    modified: string | null;
    file: string;
    fileName: string;
    uploadedBy: number;
};
export type Template = {
    modified?: string | null;
    name?: string;
    file: string;
    active?: boolean;
    bank: number;
};
export type TemplateRead = {
    id: number;
    created: string;
    modified?: string | null;
    name?: string;
    file: string;
    active?: boolean;
    bank: number;
};
export const {
    useReportsListQuery,
    useLazyReportsListQuery,
    useReportsRetrieveQuery,
    useLazyReportsRetrieveQuery,
    useReportsGenerateCreateMutation,
    useReportsRefillCreateMutation,
    useReportsSignCreateMutation,
    useReportsFilesCreateMutation,
    useReportsFilesRetrieveQuery,
    useLazyReportsFilesRetrieveQuery,
    useReportsTemplatesListQuery,
    useLazyReportsTemplatesListQuery,
} = injectedRtkApi;
