import { Disclosure } from '@headlessui/react';
import { YMaps, Map, Placemark, Circle, Polygon } from '@pbe/react-yandex-maps';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateNewReportMutation } from 'entities/reports';
import {
    SelectionStatus,
    useCheckAdvertisementMutation,
    useRestartSelectionMutation,
    useSelectionQuery,
} from 'entities/selections';
import { Polygon as GeoPolygon } from 'shared/lib/geo';
import { Button } from 'shared/ui/Button/Button';

import { AdvertisementTable } from '../AdvertisementTable/AdvertisementTable';

import { AnalogsTable } from 'widgets/selection';
import { HouseTypeLabels } from 'shared/config/HouseTypeLabels';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { SummerRoomsLabels } from 'shared/config/SummerRoomsLabels';
import { WallTypesLabels } from 'shared/config/WallTypesLabels';
import { pointToCoords } from 'shared/lib/maps/pointToCoords';
import { Loader } from 'shared/ui/Loader/Loader';

const getRatioOfMostExpensiveToCheapest = (values: Array<number>) => {
    if (!Array.isArray(values) || values.length === 0) {
        return 0;
    }

    const maxVal = Math.max(...values);
    const minVal = Math.min(...values);

    return maxVal / minVal;
};
export const Selection: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    let status = useRef(SelectionStatus.CREATED);
    const {
        data: selection,
        isLoading,
        error,
        refetch,
    } = useSelectionQuery(Number.parseInt(id ?? ''), {
        pollingInterval:
            status.current === SelectionStatus.IN_PROGRESS ? 3000 : 0 ?? 3000,
    });

    useEffect(() => {
        status.current = selection?.status ?? SelectionStatus.CREATED;
    }, [selection?.status]);

    const [center, setCenter] = useState<[number, number]>([0, 0]);

    useEffect(() => {
        if (selection) {
            setCenter(pointToCoords(selection.location));
        }
    }, [selection]);

    const ration = useMemo(() => {
        return getRatioOfMostExpensiveToCheapest(
            selection?.correctedAnalogs?.map((a) => a.correctedPricePerMeter) ??
                []
        ).toFixed(2);
    }, [selection]);

    const pricePerMeterRange = useMemo(() => {
        const prices =
            selection?.correctedAnalogs?.map((a) => a.correctedPricePerMeter) ??
            [];
        return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }, [selection]);

    const [
        createNewReportFromSelection,
        { isLoading: isLoadingNewReport, data, isSuccess },
    ] = useCreateNewReportMutation();

    const [url, setUrl] = useState<string>('');
    const [
        check,
        { data: checkData, isSuccess: checkSuccess, isLoading: checkLoading },
    ] = useCheckAdvertisementMutation();

    const [restartSelection, { isLoading: restartLoading }] =
        useRestartSelectionMutation();

    const restart = useCallback(async () => {
        if (selection?.id) {
            await restartSelection(selection?.id);
            await refetch();
        }
    }, [refetch, restartSelection, selection?.id]);

    const onCreateNewReport = useCallback(() => {
        if (selection?.id) {
            createNewReportFromSelection({ selection: selection.id });
        }
    }, [createNewReportFromSelection, selection]);

    const goToReport = useCallback(() => {
        if (selection?.report?.id) {
            navigate('/reports/' + selection?.report.id);
        }
    }, [navigate, selection?.report]);

    useEffect(() => {
        if (isSuccess) {
            navigate('/reports/' + data?.id);
        }
    }, [data, isSuccess, navigate]);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <>{error.toString()}</>;
    }

    if (!selection) {
        return <></>;
    }
    return (
        <>
            <div className="grid gap-4 bg-white white mx-auto max-w-7xl px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                <div className="grid grid-cols-2 gap-2">
                    <div className="relative">
                        <h3 className="text-xl font-bold mb-1">
                            Сведения об объекте оценки
                        </h3>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Вид объекта:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {HouseTypeLabels[selection.houseType]}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Адрес:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.address}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Этаж:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.floor} / {selection.maxFloor}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Кол-во комнат:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.roomsCount}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Площадь:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.area}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Тип ремонта:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {RenovationsLabels[selection.renovationType]}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Наличие летних помещений:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {SummerRoomsLabels[selection.summerRooms]}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Материал стен:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {WallTypesLabels[selection.wallType]}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Год постройки:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.builtYear}
                            </span>
                        </div>
                    </div>
                    <div className="relative">
                        <h3 className="text-xl font-bold mb-1">
                            Результат оценки
                        </h3>
                        {selection.status !== SelectionStatus.ERROR ? (
                            <>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Рыночная стоимость объекта:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {selection.price} ₽
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Ликвидационная стоимость объекта:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {selection.liquidationPrice} ₽
                                    </span>
                                </div>
                                <div className={'mb-4'}>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Средневзвешенная стоимость 1 кв. м.:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {selection.pricePerMeter} ₽
                                    </span>
                                </div>
                            </>
                        ) : (
                            <div className={'text-sm font-medium text-red-500'}>
                                Ошибка: не удалось найти достаточно аналогов,
                                либо что-то пошло не так
                            </div>
                        )}

                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Всего найдено аналогов:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.analogs.length}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Радиус поиска аналогов, метров:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.distance}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Дозволенное отклонение, кв. м:
                            </span>
                            {selection.areaThreshold && (
                                <span
                                    className={
                                        'ml-1 text-sm font-bold text-black'
                                    }>
                                    +-
                                    {Math.abs(
                                        selection.area * selection.areaThreshold
                                    ).toFixed(1)}
                                </span>
                            )}
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Аналогов использовано в расчете:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {selection.correctedAnalogs.length}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Отношение самого дорогого к самому дешевому
                                аналогу (цена за кв. м.):
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {ration}
                            </span>
                        </div>
                        <div>
                            <span
                                className={'text-sm font-medium text-gray-800'}>
                                Диапазон стоимости кв. метра аналогов, руб:
                            </span>
                            <span
                                className={'ml-1 text-sm font-bold text-black'}>
                                {pricePerMeterRange}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <Button
                        onClick={() => restart()}
                        disabled={restartLoading || isLoading}>
                        Перезапустить
                    </Button>
                </div>
                <div className="w-full">
                    <h3 className="text-xl font-bold mb-2">
                        Расположение объектов оценки и аналогов на карте
                    </h3>
                    <YMaps>
                        <Map
                            height={500}
                            width="100%"
                            state={{ center: center, zoom: 15 }}>
                            <>
                                {selection.analogs.map((analog, index) => {
                                    const isSelected =
                                        selection.correctedAnalogs.find(
                                            (a) =>
                                                a.advertisement.id === analog.id
                                        );
                                    return (
                                        <Placemark
                                            key={index}
                                            geometry={pointToCoords(
                                                analog.location
                                            ).reverse()}
                                            options={{
                                                iconColor: isSelected
                                                    ? 'green'
                                                    : 'red',
                                                zIndex: isSelected ? 2 : 1,
                                            }}
                                        />
                                    );
                                })}
                            </>
                            {selection.type === 'default' ? (
                                <>
                                    <Placemark geometry={center} />
                                    <Circle
                                        geometry={[center, selection.distance]}
                                        options={{ fill: false }}
                                    />
                                </>
                            ) : selection.type === 'residential_complex' ? (
                                <Polygon
                                    geometry={[
                                        GeoPolygon.parse(
                                            selection?.residentialComplex
                                                ?.polygon ?? ''
                                        ).coords.map((c) => [
                                            c.lat.toFixed(6),
                                            c.long.toFixed(6),
                                        ]),
                                    ]}
                                    options={{
                                        strokeColor: 'blue',
                                        opacity: 0.2,
                                        strokeWidth: 1,
                                    }}
                                />
                            ) : null}
                        </Map>
                    </YMaps>
                </div>
                {selection.correctedAnalogs.length ? (
                    <>
                        <div className="w-full grid">
                            <h3 className="text-xl font-bold mb-2">
                                Выбранные аналоги и корректировки:
                            </h3>
                            <AnalogsTable
                                analogs={selection.correctedAnalogs}
                            />
                        </div>
                        {selection?.report ? (
                            <Button onClick={goToReport}>
                                Перейти к отчету
                            </Button>
                        ) : (
                            <Button
                                onClick={onCreateNewReport}
                                loading={isLoadingNewReport}>
                                Создать отчет
                            </Button>
                        )}
                    </>
                ) : null}
                {selection.analogs.length ? (
                    <Disclosure>
                        <Disclosure.Button className="py-2">
                            Показать все аналоги
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <div className="w-full grid">
                                <AdvertisementTable
                                    advertisements={selection.analogs}
                                />
                            </div>
                        </Disclosure.Panel>
                    </Disclosure>
                ) : null}
                {selection.excludedAnalogs.length ? (
                    <Disclosure>
                        <Disclosure.Button className="py-2">
                            Исключенные аналоги
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <div className="w-full grid">
                                <AdvertisementTable
                                    advertisements={selection.excludedAnalogs}
                                />
                            </div>
                        </Disclosure.Panel>
                    </Disclosure>
                ) : null}
                {selection.status >= SelectionStatus.FINISHED ? (
                    <Disclosure>
                        <Disclosure.Button className="py-2">
                            Проверить объявление
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <div className="w-full grid">
                                <div>
                                    <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-sm font-medium text-gray-900">
                                        URL объявления
                                    </label>
                                    <input
                                        disabled={checkLoading}
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
                                    />
                                </div>
                                <div className="mt-2">
                                    <Button
                                        onClick={() => {
                                            check({ id: id!, url });
                                        }}
                                        loading={checkLoading}>
                                        Проверить
                                    </Button>
                                </div>
                                {checkSuccess && JSON.stringify(checkData)}
                            </div>
                        </Disclosure.Panel>
                    </Disclosure>
                ) : null}
            </div>
        </>
    );
};
