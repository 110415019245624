import { Map, Placemark, Polygon, YMaps } from '@pbe/react-yandex-maps';
import { FC } from 'react';
import ymaps, {
    IGeometry,
    IDataManager,
    IPlacemarkOptions,
    IPolygonOptions,
} from 'yandex-maps';

import { WithYMapsProps } from '@pbe/react-yandex-maps/typings/hocs/withYMaps';
import {
    AnyObject,
    WithInstanceRef,
} from '@pbe/react-yandex-maps/typings/util/typing';
import { ErrorBoundaryProps } from '@sentry/react';
import { Skeleton } from './Skeleton';

type IPlacemark = {
    geometry?: IGeometry[][][][] | number[][] | object;
    options?: IPlacemarkOptions;
    onClick?: Function;
    properties?: AnyObject | IDataManager;
};
type IPolygon = {
    geometry?: IGeometry[][][][] | number[][] | object;
    options?: IPolygonOptions;
};

interface MapsComponentProps
    extends React.PropsWithChildren<
        WithYMapsProps & WithInstanceRef & AnyObject & ErrorBoundaryProps
    > {
    state: ymaps.IMapState;
    placemarks?: IPlacemark[];
    polygon?: IPolygon;
    className?: string;
    isLoading?: boolean;
}

export const Maps: FC<MapsComponentProps> = ({
    state,
    placemarks = [],
    polygon,
    height = 500,
    className = 'w-full col-span-full',
    isLoading = false,
}) => {
    return (
        <div className={`rounded-md overflow-hidden ${className}`}>
            {isLoading ? (
                <Skeleton height={height} />
            ) : (
                <YMaps>
                    <Map
                        height={height}
                        state={state}
                        className={className}
                        style={{ height: `${height}px` }}>
                        <>
                            {placemarks.map((placemark, index) => {
                                return <Placemark key={index} {...placemark} />;
                            })}
                        </>

                        {polygon && <Polygon {...polygon} />}
                    </Map>
                </YMaps>
            )}
        </div>
    );
};
