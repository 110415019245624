import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
    Order,
    ResidentialComplexApartmentRead,
    ResidentialComplexHouseRead,
    ResidentialComplexRead,
} from 'app/generatedApi/crm';
import {
    useLazySelectionsApartmentsListQuery,
    useLazySelectionsResidentialComplexesListQuery,
    useLazySelectionsResidentialComplexHousesListQuery,
} from 'app/generatedApi/selections';
import { Polygon as GeoPolygon } from 'shared/lib/geo';
import { pointToCoords } from 'shared/lib/maps/pointToCoords';
import { Combobox, Option } from 'shared/ui/Combobox';
import { Maps } from 'shared/ui/Maps';

interface ResidentialComplexProps {
    orderDraft: Order;
    isLoading?: boolean;
}

export const ResidentialComplex: FC<ResidentialComplexProps> = ({
    orderDraft,
    isLoading,
}) => {
    const { setValue } = useFormContext<Order>();
    const [center, setCenter] = useState<[number, number]>([0, 0]);
    const [selectComplex, setSelectComplex] =
        useState<ResidentialComplexRead>();
    const [selectHouse, setSelectHouse] =
        useState<ResidentialComplexHouseRead>();
    const [selectApartment, setSelectApartment] =
        useState<ResidentialComplexApartmentRead>();

    const [fetchComplexes, { currentData: complexes, isLoading: isLoadingComplex }] =
        useLazySelectionsResidentialComplexesListQuery();

    const [fetchHouses, { currentData: houses, isLoading: isLoadingHouses }] =
        useLazySelectionsResidentialComplexHousesListQuery();

    const [fetchApartments, { currentData: apartments, isLoading: isLoadingApartments }] =
        useLazySelectionsApartmentsListQuery();

    const onSelectResidentialComplex = useCallback(
        (select: Option | string | number) => {
            let selectValue = 0;
            if (typeof select === 'string' || typeof select === 'number') {
                selectValue = Number(select)
            } else {
                selectValue = Number(select.value)
            }
            
            const complex = complexes?.find((rc) => rc.id === selectValue)!;
            setValue('residentialComplex', selectValue);
            setValue('residentialComplexName', complex?.name!);
            setValue('subjectRf', complex?.subject);
            setValue('locality', complex?.locality);
            setValue('residentialComplexHouse', null);
            setValue('address', null);
            setValue('kadastrNumber', null);
            setValue('residentialComplexApartment', null);
            setValue('apartment', null);
        },
        [complexes, setValue],
    );

    const onSelectHouse = useCallback(
        (select: Option | string | number) => {
            let selectValue = 0;
            if (typeof select === 'string' || typeof select === 'number') {
                selectValue = Number(select)
            } else {
                selectValue = Number(select.value)
            }
            setSelectHouse(houses?.find((h) => h.id === selectValue));
            setValue('residentialComplexHouse', selectValue);
            setValue('address', selectHouse?.address);
            setValue('kadastrNumber', selectHouse?.kadastrNumber);
            setValue('houseFloors', selectHouse?.floorsCount);

            setValue('residentialComplexApartment', null);
            setValue('apartment', null);
        },
        [houses, selectHouse, setValue],
    );

    const onSelectApartment = useCallback(
        (select: Option | string | number) => {
            let selectValue = 0;
            if (typeof select === 'string' || typeof select === 'number') {
                selectValue = Number(select)
            } else {
                selectValue = Number(select.value)
            }
            setSelectApartment(apartments?.find((a) => a.id === selectValue));
            setValue('residentialComplexApartment', selectValue);
            setValue('apartment', selectApartment?.number);
        },
        [apartments, selectApartment?.number, setValue],
    );

    const housesPlacemarks = useMemo(
        () =>
            houses?.map((house) => ({
                geometry: pointToCoords(house.location || ''),
                options: {
                    iconColor: selectHouse?.id === house.id ? 'green' : 'red',
                    zIndex: 1,
                },
                onClick: () => {
                    if (selectHouse?.id === house.id) return;

                    onSelectHouse({
                        name: house.address || '',
                        value: house.id,
                    });
                },
            })),
        [houses, onSelectHouse, selectHouse?.id],
    );

    useEffect(() => {
        if (orderDraft.city) {
            fetchComplexes({ city: orderDraft.city });
        }
    }, [fetchComplexes, orderDraft.city]);

    useEffect(() => {
        if (orderDraft.residentialComplex || selectComplex?.id) {
            fetchHouses({
                residentialComplex:
                    orderDraft.residentialComplex || selectComplex?.id,
            });
        }
    }, [fetchHouses, orderDraft.residentialComplex, selectComplex?.id]);

    useEffect(() => {
        if (orderDraft.residentialComplexHouse || selectHouse?.id) {
            fetchApartments({
                house: orderDraft.residentialComplexHouse || selectHouse?.id,
            }).unwrap();
        }
    }, [fetchApartments, orderDraft.residentialComplexHouse, selectHouse?.id]);

    useEffect(() => {
        if (complexes?.length) {
            if (orderDraft.residentialComplex) {
                setSelectComplex(
                    complexes?.find(
                        (c) => c.id === orderDraft.residentialComplex,
                    ),
                );
            }
        }
    }, [complexes, orderDraft.residentialComplex]);

    useEffect(() => {
        if (houses?.length) {
            if (orderDraft.residentialComplexHouse) {
                setSelectHouse(
                    houses?.find(
                        (h) => h.id === orderDraft.residentialComplexHouse,
                    ),
                );
                setCenter(pointToCoords(selectHouse?.location || ''));
            }
        }
    }, [houses, orderDraft.residentialComplexHouse, selectHouse?.location]);

    useEffect(() => {
        onSelectResidentialComplex({
            name: selectComplex?.name || '',
            value: String(selectComplex?.id),
        });
    }, [onSelectResidentialComplex, selectComplex?.id, selectComplex?.name]);

    useEffect(() => {
        onSelectHouse({
            name: selectHouse?.address || '',
            value: String(selectHouse?.id),
        });
    }, [onSelectHouse, selectHouse?.address, selectHouse?.id]);    

    return (
        <>
            <Maps
                state={{ center: center, zoom: 15 }}
                isLoading={isLoading}
                placemarks={housesPlacemarks}
                polygon={{
                    geometry: [
                        GeoPolygon.parse(
                            String(selectComplex?.polygon) ?? '',
                        ).coords.map((c) => [
                            c.lat.toFixed(6),
                            c.long.toFixed(6),
                        ]),
                    ],
                    options: {
                        strokeColor: 'blue',
                        opacity: 0.2,
                        strokeWidth: 1,
                    },
                }}
            />

            <Combobox
                label={'Жилой комплекс'}
                className="col-span-full"
                isLoading={isLoading || isLoadingComplex}
                value={
                    selectComplex
                        ? {
                              name: selectComplex.name,
                              value: selectComplex.id,
                          }
                        : undefined
                }
                options={
                    complexes?.map((complex) => ({
                        name: complex.name,
                        value: String(complex.id),
                    })) || []
                }
                onChange={onSelectResidentialComplex}
            />
            <Combobox
                label={'Дом'}
                className="col-span-3"
                isLoading={isLoading || isLoadingHouses}
                value={
                    selectHouse
                        ? {
                              name: selectHouse.address || '',
                              value: selectHouse.id,
                          }
                        : undefined
                }
                onChange={onSelectHouse}
                options={
                    houses?.map((h) => ({
                        name: h.address!,
                        value: String(h.id),
                    })) || []
                }
            />
            <Combobox
                label={'Квартира'}
                className="col-span-3"
                isLoading={isLoading || isLoadingApartments}
                hint="Только для супер-сервиса"
                value={
                    selectApartment
                        ? {
                              name: selectApartment.number.toString(),
                              value: selectApartment.id,
                          }
                        : undefined
                }
                onChange={onSelectApartment}
                options={
                    apartments?.map((a) => ({
                        name: String(a.number),
                        value: String(a.id),
                    })) || []
                }
            />
        </>
    );
};
