import { FC } from 'react';

import { OrderDetail } from 'widgets/appraiser';

interface AppraiserOrderProps {}

export const AppraiserOrder: FC<AppraiserOrderProps> = () => {
    return (
        <main className="bg-white mx-auto max-w-7xl px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
            <OrderDetail />
        </main>
    );
};
