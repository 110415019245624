/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';

import { PdfView } from 'shared/ui/PdfView/PdfView';

import { OrderDataSign } from '../module/types';

interface Display5Props {
    id: string;
    value: OrderDataSign;
    refetchOrder: () => Promise<void>;
}

export const Display5: FC<Display5Props> = ({ value }) => {
    return (
        <div className="w-3/4 mx-auto mt-20">
            <PdfView className="mb-4" fileUrl={value.pdfFile} />
        </div>
    );
};
