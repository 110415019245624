import { BuildingOffice2Icon, UserIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
    DocumentRead,
    DocumentsGroupRead,
    Order,
    useCrmNewDocumentsDestroyMutation,
    useCrmNewDocumentsPartialUpdateMutation,
    useCrmNewDocumentsGroupsCreateMutation,
    useCrmNewDocumentsGroupsDestroyMutation,
    useCrmNewDocumentsGroupsPartialUpdateMutation,
    useCrmOrdersRetrieveQuery,
    useCrmNewDocumentsCreateMutation,
} from 'app/generatedApi/crm';
import { AddressAutocomplete } from 'features/autoComplete';
import { DocumentsGroup } from 'features/documents';
import { Suggest } from 'entities/buildings';
import { DropFile } from 'shared/ui/Dropfile';
import { InputForm } from 'shared/ui/Input';
import { Section } from 'shared/ui/Section';
import { SelectForm } from 'shared/ui/Select';
import { Tabs } from 'shared/ui/Tabs/Tabs';

import { Step, StepProps } from './Step';
import {
    LabelSummerRoomsType,
    LabelLayoutTypeLabel,
} from 'entities/appraisers';
import { ResidentialComplex } from './ResidentialComplex';
import { UploadFileRead } from 'app/generatedApi/files';

export const ObjectStep: React.FC<StepProps & {}> = (props) => {
    const { id } = useParams();

    const {
        register,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useFormContext<Order>();
    const orderDraft = watch();
    const { data: fetchedOrder } = useCrmOrdersRetrieveQuery({
        id: +id!,
    });

    const onCompleteAddress = useCallback(
        (data: Suggest) => {
            setValue('address', data.value);
            setValue('subjectRf', data.data.region);
            setValue('locality', data.data.city);
            setValue('dadata', data);
            setValue('houseFiasId', data.data.houseFiasId);
        },
        [setValue],
    );

    const [createDocumentGroup] = useCrmNewDocumentsGroupsCreateMutation();
    const [partialUpdateDocumentsGroup] =
        useCrmNewDocumentsGroupsPartialUpdateMutation();
    const [destroyDocumentsGroup] = useCrmNewDocumentsGroupsDestroyMutation();

    const onAddTechDocumentGroup =
        useCallback(async (): Promise<DocumentsGroupRead> => {
            const group = await createDocumentGroup({
                documentsGroup: { name: 'Новая группа' },
            }).unwrap();
            setValue('techDocuments', [
                ...(orderDraft?.techDocuments ?? []),
                group.id,
            ]);
            return group;
        }, [createDocumentGroup, orderDraft?.techDocuments, setValue]);

    const onAddLegalDocumentGroup =
        useCallback(async (): Promise<DocumentsGroupRead> => {
            const group = await createDocumentGroup({
                documentsGroup: { name: 'Новая группа' },
            }).unwrap();
            setValue('legalDocuments', [
                ...(orderDraft?.legalDocuments ?? []),
                group.id,
            ]);
            return group;
        }, [createDocumentGroup, orderDraft?.legalDocuments, setValue]);

    const onRenameDocumentGroup = useCallback(
        async (id: number, name: string): Promise<void> => {
            await partialUpdateDocumentsGroup({
                id,
                patchedDocumentsGroup: { name },
            }).unwrap();
        },
        [partialUpdateDocumentsGroup],
    );

    const onDeleteTechDocumentGroup = useCallback(
        async (id: number) => {
            await destroyDocumentsGroup({ id }).unwrap();

            setValue(
                'techDocuments',
                orderDraft?.techDocuments?.filter((gid) => gid !== id) ?? [],
            );
        },
        [destroyDocumentsGroup, setValue, orderDraft?.techDocuments],
    );

    const onDeleteLegalDocumentGroup = useCallback(
        async (id: number) => {
            await destroyDocumentsGroup({ id }).unwrap();

            setValue(
                'legalDocuments',
                orderDraft?.techDocuments?.filter((gid) => gid !== id) ?? [],
            );
        },
        [destroyDocumentsGroup, setValue, orderDraft?.techDocuments],
    );

    const [createNewDocument] = useCrmNewDocumentsCreateMutation();
    const [destroyNewDocument] = useCrmNewDocumentsDestroyMutation();
    const [updateNewDocument] = useCrmNewDocumentsPartialUpdateMutation();

    const onUploadDocument = useCallback(
        async (
            id: number,
            groupId: number,
            position: number,
        ): Promise<DocumentRead> => {
            return await createNewDocument({
                document: {
                    newFile: id,
                    group: groupId,
                    position: position,
                },
            }).unwrap();
        },
        [createNewDocument],
    );

    const onDeleteDocument = useCallback(
        async (id: number) => {
            await destroyNewDocument({ id }).unwrap();
        },
        [destroyNewDocument],
    );

    const onMoveFileGroup = useCallback(
        async (id: number, group: number, position: number) => {
            return await updateNewDocument({
                id,
                patchedDocument: { group, position },
            }).unwrap();
        },
        [updateNewDocument],
    );

    const onUpdatePositionFile = useCallback(
        async (id: number, position: number) => {
            return await updateNewDocument({
                id,
                patchedDocument: { position },
            }).unwrap();
        },
        [updateNewDocument],
    );

    const onUploadedFloorPlan = useCallback(
        (d: UploadFileRead) => {
            setValue('floorPlanBti', d.id);
        },
        [setValue],
    );

    const onRemovedFloorPlan = useCallback(() => {
        setValue('floorPlanBti', null);
    }, [setValue]);

    useEffect(() => {
        if (orderDraft.area || orderDraft.summerRoomArea) {
            setValue(
                'areaWithSummerRooms',
                Number(orderDraft.area) + Number(orderDraft.summerRoomArea),
            );
        } else {
            setValue('areaWithSummerRooms', null);
        }
    }, [orderDraft.area, orderDraft.summerRoomArea, setValue]);

    useEffect(() => {
        if (orderDraft.area || orderDraft.livingArea) {
            setValue(
                'utilityRoomsArea',
                Number(orderDraft.area) - Number(orderDraft.livingArea),
            );
        } else {
            setValue('utilityRoomsArea', null);
        }
    }, [
        orderDraft.area,
        orderDraft.livingArea,
        orderDraft.summerRoomArea,
        setValue,
    ]);

    return (
        <Step
            onPrev={props.onPrev}
            submit
            validateOnNext
            saveText="Сохранить в работу"
            validate={[
                'ooType',
                'dadata',
                'residentialComplexName',
                'address',
                'houseFiasId',
                'district',
                'nearestMetroStation',
                'nearestMetroStationDistance',
                'groundTransportStop',
                'groundTransportStopDistance',
                'locality',
                'subjectRf',
                'kadastrNumber',
                'countOfApartmentsOnFloor',
                'apartment',
                'roomsCount',
                'floorNumber',
                'area',
                'summerRoomArea',
                'areaWithSummerRooms',
                'livingArea',
                'kitchenArea',
                'separateKitchen',
                'utilityRoomsArea',
                'ceilingHeights',
                'countAndTypeOfRestrooms',
                'separateRestroom',
                'summerRoomsType',
                'layoutType',
                'buildingWeakness',
                'location',
                'builtYear',
                'orderToStartWorkDate',
                'orderStartDate',
                'orderCompleteDate',
                'city',
                'bank',
                'residentialComplex',
                'residentialComplexHouse',
                'residentialComplexApartment',
                'floorPlanBti',
                'legalDocuments',
                'techDocuments',
            ]}>
            <Section
                title={'Сведения об объекте оценки'}
                isLoading={props.isLoading}>
                <Tabs
                    className="col-span-full !py-0"
                    tabs={['Жилой компелкс', 'Ручной ввод']}
                    icons={[BuildingOffice2Icon, UserIcon]}
                    content={[]}
                    current={
                        orderDraft.ooType === 'residential_complex' ? 0 : 1
                    }
                    onChange={(index) =>
                        setValue(
                            'ooType',
                            index === 0 ? 'residential_complex' : 'manual',
                        )
                    }
                />
                {orderDraft.ooType === 'residential_complex' && (
                    <ResidentialComplex
                        orderDraft={orderDraft}
                        isLoading={props.isLoading}
                    />
                )}
                {orderDraft.ooType === 'manual' && (
                    <AddressAutocomplete
                        value={
                            orderDraft?.dadata?.data
                                ? (orderDraft?.dadata as Suggest)
                                : undefined
                        }
                        onChangeFiasData={onCompleteAddress}
                        minFiasLevel={9}
                        message={'Введите данные до номера квартиры'}
                    />
                )}
                <InputForm
                    formKey={'address'}
                    label={'Адрес'}
                    register={register}
                    error={errors.address}
                    required={true}
                    attrs={{ ...register('address', { required: true }) }}
                    size={'xl'}
                />
                <InputForm
                    formKey={'residentialComplexName'}
                    label={'Название ЖК'}
                    register={register}
                    error={errors.residentialComplexName}
                    required={true}
                    attrs={{
                        ...register('residentialComplexName', {
                            required: true,
                        }),
                    }}
                    size={'xl'}
                />

                <InputForm
                    formKey={'subjectRf'}
                    label={'Субъект РФ'}
                    register={register}
                    error={errors.subjectRf}
                    required={true}
                    attrs={{ ...register('subjectRf', { required: true }) }}
                />
                <InputForm
                    formKey={'locality'}
                    label={'Населенный пункт'}
                    register={register}
                    error={errors.locality}
                    required={true}
                    attrs={{ ...register('locality', { required: true }) }}
                />
                <InputForm
                    formKey={'street'}
                    label={'Улица'}
                    register={register}
                    error={errors.street}
                    required={true}
                    attrs={{ ...register('street', { required: true }) }}
                    size={'l'}
                />
                <InputForm
                    formKey={'houseNumber'}
                    label={'Номер дома'}
                    register={register}
                    error={errors.houseNumber}
                    required={true}
                    attrs={{ ...register('houseNumber', { required: true }) }}
                    size={'s'}
                />
                <InputForm
                    formKey={'kadastrNumber'}
                    label={'Кадастровый номер'}
                    register={register}
                    error={errors.kadastrNumber}
                    size={'m'}
                    mask="99:99:999999:99[99]"
                    attrs={{
                        ...register('kadastrNumber'),
                    }}
                />
                <InputForm
                    formKey={'apartment'}
                    label={'Номер квартиры'}
                    register={register}
                    required={true}
                    attrs={{
                        ...register('apartment', { required: true }),
                        type: 'number',
                    }}
                    error={errors.apartment}
                    size={'m'}
                    fieldClassName={'col-start-1'}
                />
                <InputForm
                    formKey={'countOfApartmentsOnFloor'}
                    label={'Кол-во квартир на этаже'}
                    register={register}
                    attrs={{
                        type: 'number',
                        ...register('countOfApartmentsOnFloor'),
                    }}
                    error={errors.countOfApartmentsOnFloor}
                    size={'s'}
                />
                <InputForm
                    formKey={'houseFloors'}
                    label={'Кол-во этажей'}
                    register={register}
                    required={true}
                    attrs={{
                        type: 'number',
                        ...register('houseFloors', { required: true }),
                    }}
                    error={errors.houseFloors}
                    size={'s'}
                />
                <InputForm
                    formKey={'floorNumber'}
                    label={'Этаж'}
                    register={register}
                    required={true}
                    attrs={{
                        type: 'number',
                        ...register('floorNumber', { required: true }),
                    }}
                    error={errors.floorNumber}
                    size={'s'}
                    fieldClassName={'col-start-1'}
                />
                <InputForm
                    formKey={'roomsCount'}
                    label={'Кол-во комнат'}
                    register={register}
                    required={true}
                    attrs={{
                        type: 'number',
                        min: 1,
                        max: 99,
                        ...register('roomsCount', { required: true }),
                    }}
                    error={errors.roomsCount}
                    size={'s'}
                />
                <InputForm
                    formKey={'area'}
                    label={'Общая площадь, кв. м.'}
                    register={register}
                    required={true}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        ...register('area', { required: true }),
                    }}
                    size={'s'}
                    error={errors.area}
                    fieldClassName={'!col-start-1 mt-6'}
                />
                <InputForm
                    formKey={'summerRoomArea'}
                    label={'Площадь летних помещений, кв. м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        ...register('summerRoomArea'),
                    }}
                    size={'s'}
                    error={errors.summerRoomArea}
                />
                <InputForm
                    formKey={'areaWithSummerRooms'}
                    label={'Общая площадь с лет. помещениями, кв. м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        readOnly: true,
                        ...register('areaWithSummerRooms'),
                    }}
                    size={'s'}
                    error={errors.areaWithSummerRooms}
                />
                <InputForm
                    formKey={'livingArea'}
                    label={'Жилая площадь, кв. м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        ...register('livingArea'),
                    }}
                    size={'s'}
                    fieldClassName={'mt-12'}
                    error={errors.livingArea}
                />
                <InputForm
                    formKey={'kitchenArea'}
                    label={'Площадь кухни, кв. м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        ...register('kitchenArea'),
                    }}
                    size={'s'}
                    fieldClassName={'mt-12'}
                    error={errors.kitchenArea}
                />
                <SelectForm
                    formKey={'separateKitchen'}
                    label={
                        'Имеет отдельную от других объектов недвижимости кухню'
                    }
                    register={register}
                    options={[
                        { value: 'true', name: 'Да' },
                        { value: 'false', name: 'Нет' },
                    ]}
                    emptyValue={'Выберите ответ'}
                    size={'s'}
                    error={errors.separateKitchen}
                    attrs={{ ...register('separateKitchen') }}
                />
                <InputForm
                    formKey={'utilityRoomsArea'}
                    label={'Вспомогательные и подсобные помещения, кв. м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        readOnly: true,
                        ...register('utilityRoomsArea'),
                    }}
                    size={'s'}
                    error={errors.utilityRoomsArea}
                />
                <InputForm
                    formKey={'ceilingHeights'}
                    label={'Высота потолков, кв. м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        ...register('ceilingHeights'),
                    }}
                    size={'s'}
                    fieldClassName={'col-start-1 mt-6'}
                    error={errors.ceilingHeights}
                />
                <SelectForm
                    formKey={'countAndTypeOfRestrooms'}
                    label={'Кол-во и тип санузлов'}
                    register={register}
                    options={[
                        { value: 'Совмещенный', name: 'Совмещенный' },
                        { value: 'Раздельный', name: 'Раздельный' },
                        { value: 'Два сан.узла', name: 'Два сан.узла' },
                        {
                            value: 'Несколько сан.узлов',
                            name: 'Несколько сан.узлов',
                        },
                        { value: 'Другое', name: 'Другое' },
                    ]}
                    emptyValue={'Выберите ответ'}
                    size={'m'}
                    fieldClassName={'col-start-1 sm:mt-12'}
                    error={errors.countAndTypeOfRestrooms}
                    required={true}
                    attrs={{
                        ...register('countAndTypeOfRestrooms', {
                            required: true,
                        }),
                    }}
                />
                <SelectForm
                    formKey={'separateRestroom'}
                    label={
                        'Имеет отдельный от других объектов недвижимости санузел (ванная комната и туалет)'
                    }
                    register={register}
                    options={[
                        { value: 'true', name: 'Да' },
                        { value: 'false', name: 'Нет' },
                    ]}
                    emptyValue={'Выберите ответ'}
                    size={'m'}
                    error={errors.separateRestroom}
                    attrs={{ ...register('separateRestroom') }}
                />
                <SelectForm
                    formKey={'summerRoomsType'}
                    label={'Наличие балкона/лоджии'}
                    register={register}
                    options={Object.entries(LabelSummerRoomsType).map(
                        ([value, name]) => ({ name, value }),
                    )}
                    error={errors.summerRoomsType}
                    emptyValue={'Выберите ответ'}
                    size={'m'}
                    fieldClassName={'col-start-1'}
                    required={true}
                    attrs={{
                        ...register('summerRoomsType', { required: true }),
                    }}
                />
                <SelectForm
                    formKey={'layoutType'}
                    label={'Тип планировки'}
                    register={register}
                    options={Object.entries(LabelLayoutTypeLabel).map(
                        ([value, name]) => ({ name, value }),
                    )}
                    emptyValue={'Выберите ответ'}
                    size={'m'}
                    fieldClassName={'col-start-1'}
                    error={errors.layoutType}
                    required={true}
                    attrs={{ ...register('layoutType', { required: true }) }}
                />
                <InputForm
                    formKey={'buildingWeakness'}
                    label={'Процент износа здания (согласно данным БТИ), %'}
                    register={register}
                    required={true}
                    attrs={{
                        type: 'number',
                        step: 0.01,
                        defaultValue: 0,
                        ...register('buildingWeakness', { required: true }),
                    }}
                    size={'m'}
                    fieldClassName={'col-start-1'}
                    error={errors.buildingWeakness}
                />

                <Controller
                    control={control}
                    name="floorPlanBti"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <DropFile
                            formKey={field.name}
                            ref={field.ref}
                            onUpload={onUploadedFloorPlan}
                            onRemove={onRemovedFloorPlan}
                            label={'Поэтажный план (по документам БТИ)'}
                            register={register}
                            file={fetchedOrder?.floorPlanBti}
                            size={'xl'}
                            required={true}
                            error={errors.floorPlanBti}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="legalDocuments"
                    rules={{ required: true }}
                    render={({ field: { name } }) => (
                        <DocumentsGroup
                            formKey={name}
                            label={'Правоподтверждающие документы'}
                            groups={fetchedOrder?.legalDocuments || []}
                            isLoading={props.isLoading}
                            onAddNewGroup={onAddLegalDocumentGroup}
                            onRenameGroup={onRenameDocumentGroup}
                            onDeleteGroup={onDeleteLegalDocumentGroup}
                            onUploadFile={onUploadDocument}
                            onDeleteFile={onDeleteDocument}
                            onMoveFileGroup={onMoveFileGroup}
                            onUpdatePositionFile={onUpdatePositionFile}
                            size={'xl'}
                            required={true}
                            // @ts-ignore
                            error={errors.legalDocuments}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="techDocuments"
                    rules={{ required: true }}
                    render={({ field: { ref, name } }) => (
                        <DocumentsGroup
                            formKey={name}
                            label={'Технические документы'}
                            groups={fetchedOrder?.techDocuments ?? []}
                            isLoading={props.isLoading}
                            onAddNewGroup={onAddTechDocumentGroup}
                            onRenameGroup={onRenameDocumentGroup}
                            onDeleteGroup={onDeleteTechDocumentGroup}
                            onUploadFile={onUploadDocument}
                            onDeleteFile={onDeleteDocument}
                            onMoveFileGroup={onMoveFileGroup}
                            onUpdatePositionFile={onUpdatePositionFile}
                            size={'xl'}
                            required={true}
                            // @ts-ignore
                            error={errors.techDocuments}
                        />
                    )}
                />

                <InputForm
                    formKey={'district'}
                    label={'Район города'}
                    register={register}
                    size={'m'}
                    fieldClassName={'col-start-1'}
                    error={errors.district}
                    required={true}
                    attrs={{ ...register('district') }}
                />

                <InputForm
                    formKey={'nearestMetroStation'}
                    label={'Ближайшая станция метро'}
                    register={register}
                    fieldClassName={'!col-start-1'}
                    error={errors.nearestMetroStation}
                    attrs={{ ...register('nearestMetroStation') }}
                />
                <InputForm
                    formKey={'nearestMetroStationDistance'}
                    label={'Расстояние до ближайшей станции метро, м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        ...register('nearestMetroStationDistance'),
                    }}
                    size={'m'}
                    error={errors.nearestMetroStationDistance}
                />
                <InputForm
                    formKey={'groundTransportStop'}
                    label={'Остановка наземного транспорта'}
                    register={register}
                    fieldClassName={'col-start-1'}
                    attrs={{ ...register('groundTransportStop') }}
                    error={errors.groundTransportStop}
                />
                <InputForm
                    formKey={'groundTransportStopDistance'}
                    label={'Расстояние до остановки, м'}
                    register={register}
                    attrs={{
                        type: 'number',
                        ...register('groundTransportStopDistance'),
                    }}
                    size={'m'}
                    error={errors.groundTransportStopDistance}
                />
            </Section>
        </Step>
    );
};
