import { FC } from 'react';

import { DataItem } from '../module/types';
import { FileItem } from './FileItem';
import { SkeletonItem } from './Skeleton/SkeletonItem';
import { SkeletonList } from './Skeleton/SkeletonList';

interface DataListProps {
    title?: string;
    items?: DataItem[];
    isLoading?: boolean
}

export const DataList: FC<DataListProps> = ({ title, items, isLoading }) => {
    if (isLoading) {
        return (
            <SkeletonList title={title}>
                {items?.map((item, index) => (
                    <SkeletonItem key={index} />
                ))}
            </SkeletonList>
        );
    }
    return (
        <div>
            {title && (
                <h4 className="block mb-1.5 text-xl font-semibold">{title}</h4>
            )}
            <dl className="divide-y divide-gray-100">
                {items?.map((item, index) => (
                    <div
                        key={index}
                        className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            {item.name}
                        </dt>

                        {item.type === 'file' ? (
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {item.documentsGroup?.map((group) => {
                                    if ('documents' in group) {
                                        return (
                                            <div
                                                key={group.id}
                                                className="grid gap-2">
                                                <h4 className="text-base font-semibold">
                                                    {group.name}
                                                </h4>
                                                <ul className="divide-y !divide-gray-100 rounded-md border border-gray-200">
                                                    {group.documents.map(
                                                        (document) => (
                                                            <FileItem
                                                                key={
                                                                    document.id
                                                                }
                                                                document={
                                                                    document
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        );
                                    }

                                    if (group.photos) {
                                        return (
                                            <div
                                                key={group.id}
                                                className="grid gap-2">
                                                <h4 className="text-base font-semibold">
                                                    {group.name}
                                                </h4>
                                                <ul className="divide-y !divide-gray-100 rounded-md border border-gray-200">
                                                    {group.photos.map(
                                                        (photo) => (
                                                            <FileItem
                                                                key={photo.id}
                                                                document={photo}
                                                            />
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        );
                                    }

                                    return null;
                                })}
                                {item.file && (
                                    <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                        <FileItem document={item.file} />
                                    </ul>
                                )}
                            </dd>
                        ) : (
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {item.value}
                            </dd>
                        )}
                    </div>
                ))}
            </dl>
        </div>
    );
};
