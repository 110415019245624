import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { CreateSelection } from 'app/generatedApi/selections';
import { AddressAutocomplete } from 'features/autoComplete';
import { Suggest, useHouseQuery } from 'entities/buildings';
import {
    HouseType,
    WallTypes,
    RenovationTypes,
    SummerRooms,
} from 'shared/types/buildings';
import { Button } from 'shared/ui/Button/Button';
import { HouseTypeLabels } from 'shared/config/HouseTypeLabels';
import { RenovationsLabels } from 'shared/config/RenovationsLabels';
import { SummerRoomsLabels } from 'shared/config/SummerRoomsLabels';
import { WallTypesLabels } from 'shared/config/WallTypesLabels';

type SelectionFormProps = {
    isLoading?: boolean;
    onSubmit: (params: CreateSelection) => void;
};

export const SelectionForm: React.FC<SelectionFormProps> = ({
    onSubmit,
    isLoading,
}) => {
    const { handleSubmit, register, setValue } = useForm<CreateSelection>();
    const [suggestData, setSuggestData] = useState<Suggest | undefined>(
        undefined,
    );

    useEffect(() => {
        if (
            suggestData?.data?.fiasLevel &&
            suggestData?.data?.fiasLevel >= '8' &&
            suggestData?.value !== null
        ) {
            setValue('address', suggestData.value);
            setValue(
                'location',
                `POINT(${suggestData.data.geoLat} ${suggestData.data.geoLon})`,
            );

            // setValue('dadataData', fiasData);//todo: fix change generated Api
        }
    }, [
        setValue,
        suggestData?.data?.fiasLevel,
        suggestData?.data.geoLat,
        suggestData?.data.geoLon,
        suggestData?.value,
    ]);

    const { currentData: houseData } = useHouseQuery(
        suggestData?.data?.houseFiasId ?? '',
        { skip: !suggestData?.data?.houseFiasId.length ?? true },
    );

    useEffect(() => {
        if (!houseData) {
            return;
        }

        setValue('building', houseData.id);

        if (houseData?.floorCountMax) {
            setValue('maxFloor', houseData.floorCountMax);
        }

        if (houseData?.builtYear) {
            setValue('builtYear', houseData.builtYear);
        }

        if (houseData?.builtYear < new Date().getFullYear()) {
            setValue('houseType', HouseType.RESALE);
        }

        switch (houseData?.wallMaterial?.toLowerCase()) {
            case 'кирпич': {
                setValue('wallType', WallTypes.BRICK);
                break;
            }
            case 'монолит':
                setValue('wallType', WallTypes.MONOLITH);
                break;
            case 'панель':
                setValue('wallType', WallTypes.PANEL);
                break;
            case 'дерев':
                setValue('wallType', WallTypes.WOODEN);
                break;
        }
    }, [houseData, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-3">
                <AddressAutocomplete onChangeFiasData={setSuggestData} />
                <div className="grid grid-cols-3 gap-2">
                    <div className="grid grid-cols-2 gap-1">
                        <div>
                            <label
                                htmlFor="first_name"
                                className="block mb-2 text-sm font-medium text-gray-900">
                                Этаж
                            </label>
                            <input
                                type="number"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
                                {...register('floor', {
                                    required: true,
                                    min: 1,
                                    max: 99,
                                })}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="first_name"
                                className="block mb-2 text-sm font-medium text-gray-900">
                                Макс. этаж
                            </label>
                            <input
                                type="number"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
                                {...register('maxFloor', {
                                    required: true,
                                    min: 1,
                                    max: 99,
                                })}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-1">
                        <div>
                            <label
                                htmlFor="first_name"
                                className="block mb-2 text-sm font-medium text-gray-900">
                                Кол-во комнат
                            </label>
                            <input
                                type="number"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
                                {...register('roomsCount', {
                                    required: true,
                                    min: 1,
                                    max: 99,
                                })}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="first_name"
                                className="block mb-2 text-sm font-medium text-gray-900">
                                Площадь
                            </label>
                            <input
                                type="number"
                                step="0.1"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
                                {...register('area', {
                                    required: true,
                                    min: 1,
                                })}
                            />
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="buildYear"
                            className="block mb-2 text-sm font-medium text-gray-900">
                            Год постройки
                        </label>
                        <input
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
                            {...register('builtYear', {
                                required: true,
                                min: 1800,
                                max: new Date().getFullYear(),
                            })}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-2">
                    <div>
                        <label
                            htmlFor="tabs"
                            className="block mb-2 text-sm font-medium text-gray-900">
                            Вид объекта
                        </label>
                        <select
                            {...register('houseType', { required: true })}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5">
                            <option disabled defaultChecked>
                                Выберите тип объекта
                            </option>
                            <option value={HouseType.NEW}>
                                {HouseTypeLabels[HouseType.NEW]}
                            </option>
                            <option value={HouseType.RESALE}>
                                {HouseTypeLabels[HouseType.RESALE]}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="tabs"
                            className="block mb-2 text-sm font-medium text-gray-900">
                            Тип ремонта
                        </label>
                        <select
                            {...register('renovationType', { required: true })}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5">
                            <option disabled defaultChecked>
                                Выберите тип ремонта
                            </option>
                            <option value={RenovationTypes.NO}>
                                {RenovationsLabels[RenovationTypes.NO]}
                            </option>
                            <option value={RenovationTypes.COSMETIC}>
                                {RenovationsLabels[RenovationTypes.COSMETIC]}
                            </option>
                            <option value={RenovationTypes.EURO}>
                                {RenovationsLabels[RenovationTypes.EURO]}
                            </option>
                            <option value={RenovationTypes.DESIGN}>
                                {RenovationsLabels[RenovationTypes.DESIGN]}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="tabs"
                            className="block mb-2 text-sm font-medium text-gray-900">
                            Летние помещения
                        </label>
                        <select
                            {...register('summerRooms', { required: true })}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5">
                            <option disabled defaultChecked>
                                Выберите тип летних помещений
                            </option>
                            <option value={SummerRooms.NO}>
                                {SummerRoomsLabels[SummerRooms.NO]}
                            </option>
                            <option value={SummerRooms.BALCONY}>
                                {SummerRoomsLabels[SummerRooms.BALCONY]}
                            </option>
                            <option value={SummerRooms.LOGGIA}>
                                {SummerRoomsLabels[SummerRooms.LOGGIA]}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="tabs"
                            className="block mb-2 text-sm font-medium text-gray-900">
                            Материал стен
                        </label>
                        <select
                            {...register('wallType', { required: true })}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5">
                            <option disabled defaultChecked>
                                Выберите тип летних помещений
                            </option>
                            <option value={WallTypes.MONOLITH}>
                                {WallTypesLabels[WallTypes.MONOLITH]}
                            </option>
                            <option value={WallTypes.PANEL}>
                                {WallTypesLabels[WallTypes.PANEL]}
                            </option>
                            <option value={WallTypes.BLOCK}>
                                {WallTypesLabels[WallTypes.BLOCK]}
                            </option>
                            <option value={WallTypes.BRICK}>
                                {WallTypesLabels[WallTypes.BRICK]}
                            </option>
                            <option value={WallTypes.WOODEN}>
                                {WallTypesLabels[WallTypes.WOODEN]}
                            </option>
                        </select>
                    </div>
                </div>
                <Button
                    loading={isLoading}
                    type={'submit'}
                    disabled={isLoading}>
                    Начать подбор
                </Button>
            </div>
        </form>
    );
};
