import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useGetToken } from 'entities/auth';

export const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
    const token = useGetToken();

    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <>{children}</>;
};
