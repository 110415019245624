import { Settings } from 'react-slick';

import { ArrowTypeEnum } from '../SliderArrow/ArrowType';
import { SliderArrow } from '../SliderArrow/SliderArrow';

export const defaultSettings: Settings = {
    centerPadding: '0px',
    adaptiveHeight: true,
    lazyLoad: 'progressive',
    nextArrow: <SliderArrow type={ArrowTypeEnum.NEXT} />,
    prevArrow: <SliderArrow type={ArrowTypeEnum.PREVIOUS} />,
};

export const navSettings = {
    slidesToShow: 4,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
};

export const modalSettings = {
    nextArrow: <SliderArrow type={ArrowTypeEnum.NEXT} />,
    prevArrow: <SliderArrow type={ArrowTypeEnum.PREVIOUS} />,
};
