import React, { FormEventHandler, PropsWithChildren } from 'react';
import { Loader } from '../Loader/Loader';

export type FormProps = {
    inProgress?: boolean;
    onSubmit: FormEventHandler<HTMLFormElement>;
    buttons?: React.ReactNode[];
};

export const Form: React.FC<PropsWithChildren<FormProps>> = ({
    inProgress,
    onSubmit,
    children,
}) => {
    return (
        <form onSubmit={onSubmit} className='relative'>
            {inProgress && (
                <div className="absolute inset-0 flex items-center justify-center z-10">
                    <div className="absolute bg-white inset-0 opacity-40"></div>
                    <Loader className="text-grey-900 z-20" />
                </div>
            )}
            {children}
        </form>
    );
};
