import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { FieldValues } from 'react-hook-form';
import { Field, FieldProps } from '../../../ui/Field';
import { htmlFor } from '../../../lib/htmlFor';

export type InputBaseProps<T extends FieldValues> = FieldProps<T> & {
    type?: HTMLInputTypeAttribute;
    attrs?: InputHTMLAttributes<HTMLInputElement>;
    mask?: string;
};
export const InputBase = <T extends FieldValues>({
    formKey,
    label,
    type,
    attrs,
    error,
    required,
    size,
    mask,
    fieldClassName,
    isLoading
}: InputBaseProps<T>) => {
    return (
        <Field
            formKey={formKey}
            label={label}
            error={error}
            required={required}
            size={size}
            fieldClassName={fieldClassName}
            isLoading={isLoading}
        >
            {mask
                ? <input
                    id={htmlFor(formKey)}
                    type={type}
                    {...attrs}
                />
                : <input
                    id={htmlFor(formKey)}
                    type={type}
                    {...attrs}
                />
            }
        </Field>
    );
};
