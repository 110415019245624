import { FC } from 'react';

interface ErrorMessageProps {
    message?: string;
    className?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({
    message,
    className = '',
}) => {
    return (
        <div className={className}>
            <h2 className="text-center text-2xl font-semibold">
                Произошла ошибка
            </h2>
            {message && <h3 className="mt-5 text-center text-xl whitespace-pre">{message}</h3>}
        </div>
    );
};
