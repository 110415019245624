import {
    WallTypes,
    RenovationTypes,
    WindowsView,
    SummerRooms,
    HouseType,
    BuildingInfo,
} from 'shared/types/buildings';

export enum SelectionStatus {
    CREATED,
    IN_PROGRESS,
    FINISHED,
    ERROR,
}

export type Advertisement = {
    id: number;
    created: string;
    modified: string;
    address: string;
    sourceUrl: string;
    sourceId: number;
    title: string;
    description: string;
    price: number;
    time: string;
    phone: string;
    city: string;
    metro: string;
    kmDoMetro: number;
    houseType: number;
    wallType: WallTypes;
    roomsCount: number;
    floor: number;
    floorsCount: number;
    area: number;
    renovationType: RenovationTypes;
    windowsView: WindowsView;
    summerRooms: SummerRooms;
    heightOfCeiling?: number;
    buildingYear: number;
    location: string;
    originalData: string;
    unpublished: boolean;
};

export type CorrectedAnalog = {
    id: number;
    advertisement: Advertisement;
    pricePerMeter: number;
    correctedPricePerMeter: number;
    bargainingCorrection: number;
    salesTypeCorrection: number;
    wallTypeCorrection: number;
    floorCorrection: number;
    areaCorrection: number;
    summerRoomsCorrection: number;
    renovationTypeCorrection: number;
};

export type Selection = {
    excludedAnalogs: Advertisement[];
    distance: number;
    areaThreshold: number;
    id: number;
    created: string;
    modified: string | null;
    floor: number;
    maxFloor: number;
    roomsCount: number;
    area: number;
    renovationType: RenovationTypes;
    type: 'default' | 'residential_complex';
    address: string;
    summerRooms: SummerRooms;
    windowsView: WindowsView;
    houseType: HouseType;
    wallType: WallTypes;
    location: string;
    builtYear: number;
    status: SelectionStatus;
    analogs: Advertisement[];

    correctedAnalogs: CorrectedAnalog[];

    price: number;
    liquidationPrice: number;

    pricePerMeter: number;
    report?: { id: number };
    building: BuildingInfo;
    dadataData: any;

    ratio: number;

    residentialComplex?: {
        polygon: string;
    };
};

export type CreateSelection = Pick<
    Selection,
    Exclude<
        keyof Selection,
        | 'id'
        | 'analogs'
        | 'created'
        | 'modified'
        | 'status'
        | 'correctedAnalogs'
        | 'building'
    >
> & {
    building: number;
};
