import React, { HTMLAttributes, memo } from 'react';

import { classnames } from '../../lib/classnames';
import { Loader } from '../Loader/Loader';

import cls from './Button.module.css';

export enum ButtonTheme {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    OUTLINE = 'outline',
    GREY = 'grey',
    GREYOPACITY = 'greyOpacity',
}

export enum ButtonSize {
    SM = 'size-sm',
    MD = 'size-md',
    LG = 'size-lg',
    XL = 'size-xl',
}

export type ButtonProps = {
    loading?: boolean;
    disabled?: boolean;
    round?: boolean;
    ref?: React.LegacyRef<HTMLButtonElement> | null;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
    theme?: ButtonTheme;
    size?: ButtonSize;
    soft?: boolean;
    icon?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const ButtonComp: React.FC<React.PropsWithChildren<ButtonProps>> =
    React.forwardRef(
        (
            {
                loading = false,
                children,
                disabled,
                round = false,
                onClick,
                type = 'button',
                className = '',
                theme = ButtonTheme.PRIMARY,
                size = ButtonSize.MD,
                soft = false,
                icon = false,
                ...otherProps
            },
            ref,
        ) => {
            return (
                <button
                    {...otherProps}
                    ref={ref}
                    disabled={disabled || loading}
                    type={type}
                    onClick={onClick}
                    className={classnames(
                        cls.button,
                        cls[theme],
                        cls[size],
                        {
                            [cls.round]: round,
                            [cls.soft]: soft,
                            [cls.icon]: icon,
                            [cls.loading]: loading,
                        },
                        className,
                    )}>
                    {loading ? (
                        <Loader
                            className={classnames(
                                'inline !w-5 !h-5 animate-spin',
                                { 'mr-2': !icon },
                            )}
                        />
                    ) : null}
                    {children}
                </button>
            );
        },
    );
export const Button = memo(ButtonComp);
