import React from 'react';
import { FieldValues } from 'react-hook-form';

import { htmlFor } from '../../../lib/htmlFor';
import { Field, FieldProps } from '../../Field';

export type SelectBaseProps<T extends FieldValues> = FieldProps<T> & {
    options: { value: string; name: string }[];
    emptyValue?: string;
    attrs?: React.SelectHTMLAttributes<HTMLSelectElement>;
};
export const SelectBase = <T extends FieldValues>(
    {
        options,
        emptyValue,
        formKey,
        label,
        hint,
        error,
        required,
        size,
        fieldClassName,
        attrs,
        isLoading
    }: SelectBaseProps<T>
) => {
    return (
        <Field formKey={formKey} label={label} hint={hint} error={error}
               required={required} size={size} fieldClassName={fieldClassName} isLoading={isLoading}>
            <select
                id={htmlFor(formKey)}
                {...attrs}>
                {emptyValue && (
                    <option value="" disabled>
                        {emptyValue}
                    </option>
                )}
                {options?.map((value) => (
                    <option key={value.value} value={value.value}>
                        {value.name}
                    </option>
                ))}
            </select>
        </Field>
    );
};
